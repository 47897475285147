import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TagManager from "react-gtm-module";
import * as color from "../../css/ColorVariable";

import MyLink from "../../asset/img/mylink.png";
import MyLinkActive from "../../asset/img/mylinkactive.png";
import HiddenLink from "../../asset/img/hiddenlink.png";
import HiddenLinkActive from "../../asset/img/hiddenlinkactive.png";
import FavoriteLink from "../../asset/img/favoritelink.png";
import FavoriteLinkActive from "../../asset/img/favoritelinkactive.png";
import MySorti from "../../asset/img/mysorti.png";
import MySortiActive from "../../asset/img/mysortiActive.png";

import SideBarList from "./SideBarList";

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 10px;
  height: 100%;
  width: ${(props) => (props.toggle ? "95px" : "250px")};
  padding: 50px 20px 20px 20px;
  border-right: 1px solid ${color.brightgrayColor};
  background-color: white;
  transition: 0.5s;
  z-index: 9999;
`;

function SideBar({ toggle }) {
  const navigate = useNavigate();
  const goToMylink = () => {
    navigate("/dashboard/mylink");
  };

  const goToHiddenLink = () => {
    navigate("/dashboard/hiddenlink");
  };

  const goToFavorLink = () => {
    navigate("/dashboard/favor");
  };

  const goToMysorti = () => {
    navigate("/dashboard/mysorti");
  };

  const tagManagerArgs = {
    dataLayer: {
      event: "Menu_click",
    },
  };

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <SideBarWrapper toggle={toggle}>
      <SideBarList
        img={MyLink}
        activeImg={MyLinkActive}
        text="나의 링크"
        onClick={goToMylink}
        toggle={toggle}
      />
      <SideBarList
        img={MySorti}
        activeImg={MySortiActive}
        text="MY SORTI 링크"
        onClick={goToMysorti}
        toggle={toggle}
      />
      <SideBarList
        img={HiddenLink}
        activeImg={HiddenLinkActive}
        text="숨겨진 링크"
        onClick={goToHiddenLink}
        toggle={toggle}
      />
      <SideBarList
        img={FavoriteLink}
        activeImg={FavoriteLinkActive}
        text="즐겨찾는 링크"
        onClick={goToFavorLink}
        toggle={toggle}
      />
    </SideBarWrapper>
  );
}

export default SideBar;
