import React from 'react'
import styled from "styled-components";
import Cookie from 'universal-cookie';

import SortiLogo from '../../asset/img/sortiLogo.png';
import HamburgerMenu from '../../asset/img/hamburgermenu.png';

import Button from '../Button/Button';
import Input from "../Input/Input";
import ProfileBox from '../Box/ProfileBox'
import {useNavigate} from "react-router-dom";

const cookies = new Cookie();

const MenuBarWrapper = styled.div`
  display : flex;
  justify-content : space-between;
  align-items : center;
  position : fixed;
  width : calc(100% - 80px);
  min-width: 1200px;
  height : 50px;
  padding : 17px 40px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
  background-color : white;
  z-index : 10000;
  & > div:first-child{
    display : flex;
    align-items : center;
    column-gap: 70px;
    & > div:first-child{
      display : flex;
      align-items : center;
      column-gap: 30px;
      & > img:first-child{
        width :21px;
        cursor: pointer;
      }
      & > img:last-child{
        width :130px;
        cursor: pointer;
      }
    }
    & > div:last-child{
      display : flex;
      align-items : center;
      column-gap: 20px;
    }
  }
`

function MenuBar({handleToggleMenu , handleSearchDataTitle}) {

    const navigate = useNavigate();

  return (
    <>
      <MenuBarWrapper>
          <div>
              <div>
                  <img src={HamburgerMenu} alt='HamburgerMenu' onClick={handleToggleMenu}/>
                  <img src={SortiLogo} alt='SortiLogo' onClick={() => navigate('/')}/>
              </div>
              <div>
                  <Input placeholder='나의 링크 검색' onChange={handleSearchDataTitle}/>
                  {/*<Button text={'링크 만들기'}/>*/}
              </div>
          </div>
          <ProfileBox name={cookies.get('name')} email={cookies.get('email')}/>
      </MenuBarWrapper>
    </>
  )
}

export default MenuBar