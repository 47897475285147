import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import * as color from '../css/ColorVariable';
import { DATA_API_URL } from '../Url';

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import LinkInfoBox from '../components/Box/LinkInfoBox';
import TagContainer from '../components/Box/TagContainer';

import { Bar } from 'react-chartjs-2';
import Link from '../asset/img/linkIcon.png';
import LoadingMotion from '../asset/img/Loading-Motion.gif';
import Mouse from '../asset/img/mouse.png';
import Click from '../asset/img/mouseIcon.png';
import Month from '../asset/img/thisMonthIcon.png';
import EmptyGraphBox from '../components/Box/EmptyGraphBox';
import EmptyLinkBox from '../components/Box/EmptyLinkBox';
import LinkCreateContainer from '../components/Box/LinkCreateContainer';
import LinkDetailBox from '../components/Box/LinkDetailBox';
import SearchControlBox from '../components/Box/SearchControlBox';
import TokenExpiredModal from '../components/Modal/TokenExpiredModal';
import ToggleButton from '../components/Button/ToggleButton';
import SearchPage from './SearchPage';
import ArrowUpWhite from '../asset/img/ArrowUpWhite.png';
import ArrowDownGreen from '../asset/img/ArrowDownGreen.png';
import SearchTagContainer from '../components/Box/SearchTagContainer';
import useTitle from '../utils/useTitle';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const cookies = new Cookie();

const SelectOptionWrapper = styled.div`
  width: fit-content;
  display: flex;
  white-space: nowrap;
  background-color: ${(props) => (props.toggle ? color.mainColor : color.backgroundColor)};
  align-items: center;
  box-sizing: border-box;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  & > span {
    font-size: 16px;
    line-height: 24px;
    font-family: suitMedium;
    color: ${(props) => (props.toggle ? 'white' : '#252525')};
  }
  & > img {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
  &:hover {
    background-color: ${(props) => (props.toggle ? color.hoverSelectedButton : '#ccfde6')};
  }
`;
const ContainerWrapper = styled.div`
  margin-top: ${(props) => (!!props.mt ? props.mt : 70)}px;
`;

const SearchWithLinksWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
  width: 100%;
  box-sizing: border-box;
  & > div:first-child {
    position: sticky;
    top: 14px;
    margin-top: 14px;
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
    & > div:first-child {
      position: sticky;
      top: 0px;
      background-color: ${color.backgroundColor};
      z-index: 4;
      height: 56px;
      align-items: center;
      display: flex;
      width: 100%;
      & > p {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
`;

const LoadingOverlay = styled.div`
  display: ${(props) => (props.state ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: #ffffffb3;
  top: 0;
  left: 0;
  z-index: 999999999;
  & > img {
    width: 216px;
    height: 216px;
  }
`;

const DashboardMyLinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 40px 30px;
  & > h1 {
    font-family: suitExBold;
    font-weight: 500;
    font-size: 24px;
  }
`;

const LinkInfoBoxContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const GraphBoxWrapper = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  & > div:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & > div:first-child {
      display: flex;
      align-items: center;
      column-gap: 8px;
      & > p {
        font-family: suitMedium;
        color: ${color.mainColor};
      }
      & > span {
        font-family: suitMedium;
      }
      & > img {
        width: 10px;
      }
    }
  }
  & > div:last-child {
    height: 100%;
    display: ${(props) => (props.toggle ? 'flex' : 'none')};
    & > canvas {
      min-width: calc(100% - 32px) !important;
      height: 300px !important;
    }
  }
`;

function DashboardMyLink({ searchDataTitle, handleSearchDataTitle, setBackColor }) {
  // let today = new Date();
  // let dayList = [];
  // const beforeDate = new Date(today.setDate(today.getDate() - 30));
  // const lastYear = beforeDate.getFullYear().toString();
  // const lastMonth = (beforeDate.getMonth() + 1).toString().padStart(2, '0');
  // const lastDay = beforeDate.getDate().toString().padStart(2, '0');
  // const lastDate = lastYear + '-' + lastMonth + '-' + lastDay;

  useTitle('SORTI | 나의 링크');

  const [myLinkData, setMyLinkData] = React.useState({});
  const [myLinks, setMyLinks] = React.useState('');
  const [searchStart, setSearchStart] = React.useState('');
  const [searchEnd, setSearchEnd] = React.useState('');
  const [sortType, setSortType] = React.useState('최근 날짜 순');

  const [tagArray, setTagArray] = React.useState([]);
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const [refreshTag, setRefreshTag] = React.useState(false);

  const [loadingToggle, setLoadingToggle] = React.useState(false);
  const [graphToggle, setGraphToggle] = React.useState(true);
  const [searchData, setSearchData] = React.useState('');

  const handleTokenExpiredModal = () => {
    setLoadingToggle(false);
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };

  const handleSortType = (name) => {
    setSortType(name);
  };

  const handleStart = (e) => {
    setSearchStart(e.target.value);
  };

  const handleEnd = (e) => {
    setSearchEnd(e.target.value);
  };

  useEffect(() => {
    setLoadingToggle(true);

    let pObj = { token: cookies.get('token') };
    if (!!searchDataTitle && searchDataTitle !== '') {
      pObj.tagNameList = [];
      pObj.searchData = searchDataTitle;
      pObj.startDate = '';
      pObj.endDate = '';
      pObj.sortType = sortType;
    } else {
      pObj.tagNameList = tagArray;
      pObj.searchData = searchData;
      pObj.startDate = searchStart;
      pObj.endDate = searchEnd;
      pObj.sortType = sortType;
    }

    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/links/my',
      params: pObj,
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setMyLinks(response.data.data.linkData);
          setMyLinkData(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchDataTitle, searchStart, searchEnd, sortType, tagArray, refreshTag, searchData]);

  useEffect(() => {
    if (!myLinks || myLinks === '') return;
    setLoadingToggle(false);
  }, [myLinks]);

  useEffect(() => {
    setBackColor(color.backgroundColor);
  }, []);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const data = {
    labels: myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dateTime),
    datasets: [
      {
        label: '',
        data: myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount),
        backgroundColor: [color.mainColor],
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'bar',
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgb(255 ,255 ,255)',
        titleFont: {
          family: 'suitMedium',
          weight: 500,
        },
        bodyFont: {
          family: 'suitMedium',
        },
        titleColor: 'rgb(0 ,0 ,0)',
        bodyColor: 'rgb(0 ,0 ,0)',
        borderColor: color.mainColor,
        borderWidth: 1,
        displayColors: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          offset: false,
        },
        ticks: {
          callback: function (val, index) {
            if (index === 29) {
              return this.getLabelForValue('오늘');
            } else {
              return index % 5 === 0 ? this.getLabelForValue(val).substr(5, 10).replace('-', '.') : '';
            }
          },
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor, color.darkgrayColor, color.darkgrayColor, color.darkgrayColor, color.mainColor],
        },
      },
      y: {
        min: 0,
        max: Math.max(...(myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount))),
        ticks: {
          padding: 10,
          stepSize: Math.round(Math.max(...(myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount))) / 2),
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor],
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  if (!!searchDataTitle && searchDataTitle !== '') {
    return <SearchPage searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />;
  }
  return (
    <DashboardMyLinkWrapper>
      <>
        <h1>나의 링크</h1>
        <LinkInfoBoxContainer>
          <LinkInfoBox img={Link} text={'나의 링크'} content={`${myLinkData.linkCount || ''}`} color={'00CE7C'} />
          <LinkInfoBox img={Month} text={'이번 달 생성 링크'} content={`${myLinkData.monthLinkCreateCount || ''}`} color={'B287F8'} />
          <LinkInfoBox img={Click} text={'전체 링크 클릭 수'} content={`${myLinkData.totalLinkClickCount || ''}`} color={'756BF1'} />
        </LinkInfoBoxContainer>
        <ContainerWrapper>
          <LinkCreateContainer refresh={refreshTag} setRefresh={setRefreshTag} />
        </ContainerWrapper>
        <ContainerWrapper>
          {myLinkData.totalLinkClickCount === 0 ? (
            <EmptyGraphBox graphSortType={'월간'} />
          ) : (
            <GraphBoxWrapper toggle={graphToggle}>
              <div>
                <div>
                  <p>30일간의 링크 클릭</p>
                  <span>{myLinkData.monthLinkClickCount === undefined ? '' : numberWithCommas(myLinkData.monthLinkClickCount)}</span>
                  <img src={Mouse} alt='pointer' />
                </div>
                <SelectOptionWrapper toggle={graphToggle} onClick={() => setGraphToggle(!graphToggle)}>
                  <span>그래프</span>
                  <img alt='graph toggle' src={graphToggle ? ArrowUpWhite : ArrowDownGreen} />
                </SelectOptionWrapper>
              </div>
              <div>
                <Bar data={data} options={options} />
              </div>
            </GraphBoxWrapper>
          )}
        </ContainerWrapper>
      </>
      <ContainerWrapper mt={56}>
        <SearchWithLinksWrapper>
          <div>
            <SearchTagContainer
              searchStart={searchStart}
              searchEnd={searchEnd}
              handleStart={handleStart}
              handleEnd={handleEnd}
              tagArray={tagArray}
              setTagArray={setTagArray}
              searchData={searchData}
              setSearchData={setSearchData}
            />
          </div>
          <div>
            <div>
              <SearchControlBox linkCount={myLinkData.linkCount} sortType={sortType} handleSortType={handleSortType} />
            </div>
            <div>
              {myLinks.length === 0 ? (
                <EmptyLinkBox />
              ) : myLinks === '' ? (
                ''
              ) : (
                myLinks.map((item, key) => {
                  return (
                    <LinkDetailBox
                      setRefreshTag={setRefreshTag}
                      refreshTag={refreshTag}
                      key={key}
                      urlId={item.urlId}
                      originUrl={item.originUrl}
                      customUrl={item.customUrl}
                      favoriteStatus={item.favoriteStatus}
                      insertDate={item.insertDate}
                      clickCount={item.clickCount}
                      tag={item.tag}
                      urlTitle={item.urlTitle}
                      usId={item.usId}
                      tagArray={tagArray}
                    />
                  );
                })
              )}
            </div>
          </div>
        </SearchWithLinksWrapper>
      </ContainerWrapper>
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <LoadingOverlay state={loadingToggle}>
        <img src={LoadingMotion} alt='loading motion gif' />
      </LoadingOverlay>
    </DashboardMyLinkWrapper>
  );
}

export default DashboardMyLink;
