import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Cancel from '../asset/img/cancel.png';
import * as color from '../css/ColorVariables';

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  padding: 20px 20px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(30px);
  z-index: 105;
  transform: ${(props) => (props.toggle ? `translate(0)` : `translate(100%)`)};
  transition: transform 0.5s;
  & > div:first-child {
    display: flex;
    justify-content: flex-end;
    padding-top: 40%;
    & > img {
      width: 20px;
      padding: 5% 5% 5% 0;
    }
  }
  & > div:last-child {
    padding: 10% 5%;
    & > p {
      font-family: suitSemiBold;
      font-size: 18px;
      line-height: 24px;
      color: black;
      padding: 15px 50px;
    }
  }
`;

function SideDrawer({ toggle, toggleFunction }) {
  const cookies = new Cookies();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const goToSignUp = () => {
    window.location.href = '/signup';
  };

  const goToLogin = () => {
    window.location.href = '/login';
  };
  const goToDashboard = () => {
    window.location.href = '/dashboard/mylink';
  };
  useEffect(() => {
    const name = cookies.get('name');
    const loginType = cookies.get('loginType');
    const token = cookies.get('token');
    if (!!name && name !== '' && !!loginType && loginType !== '' && !!token && token !== '') {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      <Wrapper toggle={toggle}>
        <div>
          <img src={Cancel} onClick={toggleFunction} />
        </div>
        {isLoggedIn ? (
          <div>
            <p onClick={goToDashboard}>대시보드</p>
          </div>
        ) : (
          <div>
            <p onClick={goToSignUp}>회원가입</p>
            <p onClick={goToLogin}>로그인</p>
          </div>
        )}
      </Wrapper>
    </>
  );
}

export default SideDrawer;
