import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as color from '../css/ColorVariable';

import MenuBar from '../components/Menu/MenuBar';
import SideBar from '../components/Menu/SideBar';
import DashboardMyLink from './DashboardMyLink';
import DashboardHiddenLink from './DashboardHiddenLink';
import DashboardFavor from './DashboardFavor';
import DashboardMySorti from './DashboardMySorti';
import MyPage from './MyPage';
import DashBoardLinkDetail from './DashBoardLinkDetail';

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  background-color: ${(props) => (props.backColor ? props.backColor : color.backgroundColor)};
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-top: 80px;
  min-width: 1450px;
  & > div {
    overflow-y: scroll;
  }
  & > div:last-child {
    display: flex;
    width: 100%;
    justify-content: center;
    & > div {
      width: 100%;
      min-width: 1200px;
      max-width: 1500px;
    }
  }
`;

function Dashboard() {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [searchDataTitle, setSearchDataTitle] = React.useState('');
  const [backColor, setBackColor] = React.useState('');

  const handleSearchDataTitle = (e) => {
    setSearchDataTitle(e.target.value);
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <DashboardWrapper>
      <MenuBar handleToggleMenu={handleToggleMenu} handleSearchDataTitle={handleSearchDataTitle} />
      <ContentContainer backColor={backColor}>
        <SideBar toggle={toggleMenu} />
        <div>
          <div>
            <Routes>
              <Route
                path='/mylink'
                exact
                element={<DashboardMyLink setBackColor={setBackColor} searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />}
              />
              <Route
                path='/mysorti'
                exact
                element={<DashboardMySorti setBackColor={setBackColor} searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />}
              />
              <Route
                path='/hiddenlink'
                exact
                element={<DashboardHiddenLink setBackColor={setBackColor} searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />}
              />
              <Route path='/favor' exact element={<DashboardFavor setBackColor={setBackColor} searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />} />
              <Route path='/linkdetail/:id' exact element={<DashBoardLinkDetail />} />
              <Route path='/mypage' exact element={<MyPage />} />
            </Routes>
          </div>
        </div>
      </ContentContainer>
    </DashboardWrapper>
  );
}

export default Dashboard;
