import React from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

import Extension from '../asset/img/extensionMain.png';
import ExtensionSubBottom from '../asset/img/extensionSubBottom.png';
import ExtensionSubTop from '../asset/img/extensionSubTop.png';
import SortiButton from './SortiButton';
import { isMobileFunc } from '../utils/utils';

const isMobile = isMobileFunc();

const ExtensionContainerWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.whiteColor};
  padding-top: 0;
  overflow: hidden;
  box-sizing: border-box;
  & > div {
    flex-direction: ${isMobile ? 'column-reverse' : 'row'};

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 60px;
    row-gap: 120px;
    position: relative;
    & > img.main {
      width: ${isMobile ? '270px' : '540px'};
      margin-top: ${isMobile ? '-100px' : '40px'};
      z-index: 2;
    }
    & > img.sub {
      position: absolute;
      z-index: 1;
    }
    & > img.top {
      top: ${isMobile ? '220px' : '-130px'};
      right: ${isMobile ? '160px' : '-160px'};
      width: ${isMobile ? '204px' : '500px'};
    }
    & > img.bottom {
      bottom: ${isMobile ? '-80px' : '-160px'};
      left: ${isMobile ? '180px' : '180px'};
      width: ${isMobile ? '240px' : '500px'};
    }
    & > div {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: ${isMobile ? 'center' : 'flex-start'};
      row-gap: ${isMobile ? '30px' : '20px'};
      padding-top: 1%;
      margin-top: ${isMobile ? '55%' : ''};
      & > p {
        font-family: suitExBold;
        font-size: ${isMobile ? '24px' : '40px'};
        line-height: ${isMobile ? '40px' : '50px'};
        text-align: ${isMobile ? 'center' : ''};
        & > strong {
          font-weight: normal;
          color: ${color.mainColor};
        }
      }
      & > span {
        font-family: suitMedium;
        font-size: ${isMobile ? '14px' : '20px'};
        line-height: ${isMobile ? '18px' : '25px'};
        text-align: ${isMobile ? 'center' : ''};
        color: ${color.darkgrayColor};
      }
      & > button {
        height: 60px;
        margin-top: 40px;
        font-size: 18px;
      }
    }
  }
`;

const handleClick = () => {
  window.open('https://chrome.google.com/webstore/detail/sorti-extension/nhfmhpidhnabgpdblooddkcncgbobnpo?hl=ko');
};

function ExtensionContainer() {
  return (
    <ExtensionContainerWrapper>
      <div>
        <img className='main' src={Extension} alt={'extension image1'} />
        <div>
          <p>
            {isMobile && <>PC의 </>}
            <strong>브라우저 익스텐션</strong>으로
            <br />
            바로 SORTI URL을{isMobile && <br />} 생성해보세요
          </p>
          {!isMobile && <span>링크를 짧게 만들고 바로 사람들에게 내 링크를 알려봐요!</span>}
          {!isMobile && <SortiButton text={'브라우저 익스텐션 설치하기 >'} onClick={handleClick} />}
        </div>
        <img className='sub top' alt='extension Image2' src={ExtensionSubTop} />
        <img className='sub bottom' alt='extension Image3' src={ExtensionSubBottom} />
      </div>
    </ExtensionContainerWrapper>
  );
}

export default ExtensionContainer;
