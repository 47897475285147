import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

import CustomUrlColor from '../asset/img/customurlColor.png';

import { isMobileFunc } from '../utils/utils';
import ToggleButton from './ToggleButton';

const isMobile = isMobileFunc();

const urlFontSize = 24;

const MySortiContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: ${isMobile ? '0 5%' : '0 10%'};
  background-color: ${color.whiteColor};
  & > div > p > strong {
    font-weight: normal;
    color: ${color.mainColor};
  }
  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-bottom: ${isMobile ? '20px' : '40px'};
    margin-top: ${isMobile ? '140px' : '0px'};
    & > p {
      text-align: center;
      font-family: suitExBold;
      font-size: ${isMobile ? '24px' : '40px'};
      line-height: ${isMobile ? '40px' : '50px'};
      white-space: nowrap;
    }
    & > span {
      font-family: suitMedium;
      font-size: ${isMobile ? '14px' : '20px'};
      line-height: ${isMobile ? '18px' : '25px'};
      color: ${color.darkgrayColor};
      text-align: center;
      line-height: 24px;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    width: ${isMobile ? '100%' : '50%'};
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    flex-direction: ${isMobile ? 'column' : 'row'};
    row-gap: 20px;
    & > div:nth-child(1) {
      display: flex;
      background-color: white;
      justify-content: space-between;
      align-items: center;
      width: ${isMobile ? '192px' : 'fit-content'};
      height: 60px;
      box-sizing: border-box;
      font-family: suitRegular;
      font-size: ${isMobile ? urlFontSize - 8 : urlFontSize}px;
      color: ${color.mainColor};
      border: 1.5px solid ${(props) => (props.toggle ? color.mainColor : color.brightgrayColor)};
      border-radius: 15px;
      padding: ${isMobile ? '12px 15px' : '17px 23px 15px 23px'};
      box-shadow: 0px 5px 15px #d0e3dc;
      column-gap: 10%;
      transition: 0.5s;
      & > p {
        width: ${isMobile ? '280px' : '150px'};
        white-space: nowrap;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      background-color: white;
      align-items: center;
      font-family: suitRegular;
      font-size: ${isMobile ? urlFontSize - 8 : urlFontSize}px;
      border: 1.5px solid ${color.brightgrayColor};
      border-radius: 15px;
      padding: ${isMobile ? '12px 15px' : '22px 23px 20px 23px'};
      box-shadow: 0px 5px 15px #d0e3dc;
      transition: width 0.5s;
      height: 60px;
      box-sizing: border-box;
      /* max-width: 270px; */
      & > img {
        height: ${isMobile ? urlFontSize : urlFontSize + 2}px;
      }
      & > p {
        white-space: nowrap;
      }
      & > p > strong {
        font-weight: normal;
        color: ${color.mainColor};
      }
    }
  }
`;

function MySortiContainer() {
  const [toggleOnOff, setToggleOnOff] = React.useState(false);

  const handleToggleOnOff = () => {
    setToggleOnOff(!toggleOnOff);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleToggleOnOff();
    }, 1500);

    return () => clearInterval(timer);
  });

  return (
    <MySortiContainerWrapper toggle={toggleOnOff}>
      <div>
        <p>
          <strong>MY SORTI</strong>를 통해
          <br /> 남들과는 다른 <strong>나만의 URL</strong>로
        </p>
        <span>링크에 나만의 키워드를 등록하여 {isMobile && <br />} 링크로 나를 표현해보세요!</span>
      </div>
      <div>
        <div>
          <p>MY SORTI</p>
          <ToggleButton toggle={toggleOnOff} />
        </div>
        <div>
          <p>
            https://sorti.me/
            {toggleOnOff ? (
              <>
                <strong>MY SORTI</strong>
                <span>/</span>
              </>
            ) : (
              ''
            )}
          </p>
          <img src={CustomUrlColor} alt={''} />
        </div>
      </div>
    </MySortiContainerWrapper>
  );
}

export default MySortiContainer;
