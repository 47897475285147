import React from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

import SocialLoginButton from '../components/SocialLoginButton';

import Google from '../asset/img/google.png';
import Kakao from '../asset/img/kakao.png';
import { isMobileFunc } from '../utils/utils';
import useTitle from '../utils/useTitle';

const isMobile = isMobileFunc();

const Wrapper = styled.div`
  background-color: ${color.whiteColor};
  height: 100vh;
  min-width: ${isMobile ? '300px' : '1100px'};
  & > div {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -40%);
    width: ${isMobile ? '10%' : '55%'};
    min-width: ${isMobile ? '350px' : '600px'};
    height: fit-content;
    padding: 7.5% 0;
    border-radius: 10px;
    text-align: center;
    background-color: white;
    & > p:first-child {
      font-family: suitExBold;
      font-size: ${isMobile ? '20px' : '40px'};
    }
    & > p:nth-child(2) {
      font-family: suitMedium;
      margin: auto;
      font-size: ${isMobile ? '14px' : '20px'};
      line-height: ${isMobile ? '18px' : '25px'};
      width: 70%;
      padding: 5% 0;
    }
    & > p:last-child {
      font-family: suitMedium;
      margin-top: 30px;
      line-height: 160%;
      font-size: ${isMobile ? '12px' : ''};
      & > span {
        color: ${color.darkgrayColor};
        text-decoration: underline;
      }
    }
    & > p > strong {
      color: ${color.mainColor};
      font-weight: normal;
    }
    & > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
    }
  }
`;

function Signup() {
  useTitle('SORTI');

  const onClickGoogle = () => {
    window.location.href = 'https://sorti.me/sorti/api/user/oauth2/authorization/google';
  };

  const onClickKakao = () => {
    window.location.href = 'https://sorti.me/sorti/api/user/oauth2/authorization/kakao';
  };

  const handleOpen = (title) => {
    window.open(title);
  };

  return (
    <Wrapper>
      <div>
        <p>
          아직 <strong>SORTI</strong>의 회원이 아니신가요?
        </p>
        <p>SORTI의 회원이 되어 손쉬운 단축 URL을 경험해보세요!</p>
        <div>
          <SocialLoginButton border={`1px solid ${color.brightgrayColor}`} backgroundColor={'white'} text={'Google로 시작하기'} onClick={onClickGoogle} img={Google} />
          <SocialLoginButton border={'none'} backgroundColor={'#FEE500'} text={'카카오로 시작하기'} onClick={onClickKakao} img={Kakao} />
        </div>
        <p>
          SORTI에 가입하시면 <span onClick={handleOpen.bind(this, 'privacyPolicy')}>개인정보처리방침</span>과{' '}
          <span onClick={handleOpen.bind(this, 'termsOfService')}>이용약관</span>에<br />
          동의하는 것으로 간주합니다.
        </p>
      </div>
    </Wrapper>
  );
}

export default Signup;
