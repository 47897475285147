import React from 'react';
import styled from "styled-components";

import DownArrow from '../../asset/img/greendownarrow.png';
import Profile from '../../asset/img/profile.png';
import ProfilePopover from "./ProfilePopover";

const ProfileBoxWrapper = styled.div`
    display : flex;
    align-items : center;
    column-gap : 12px;
    font-family : suitMedium;
    cursor : pointer;
    & > img:first-child{
        width : 38px;
    }
    & > img:nth-child(3){
        width : 14px;
        margin-left : 14px;
    }
`

const defaultProps = {
    /**
     * 사용자의 이름
     */
    name : "이름",
    /**
     * 사용자의 이메일
     */
    email : "email@email.com",
  };

function ProfileBox({name , email}) {

    const [togglePopover , setTogglePopover] = React.useState(false);

    const handlePopover = () => {
        setTogglePopover(!togglePopover);
    }

  return (
    <ProfileBoxWrapper onClick={handlePopover}>
        <img src={Profile} alt="Profile" />
        <div>
            <p>{name}</p>
            <p>{email}</p>
        </div>
        <img src={DownArrow} alt="DownArrow" />
        <ProfilePopover toggle={togglePopover} toggleFunc={handlePopover}/>
    </ProfileBoxWrapper>
  )
}

ProfileBox.defaultProps = defaultProps;

export default ProfileBox