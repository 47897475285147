import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  border: 0;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.1s linear;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) => (props.show ? 'scale(1)' : 'scale(0.9)')};
  min-width: 420px;
  color: #252525;
  & > h1 {
    font-family: suitSemiBold;
    font-weight: 500;
    margin-bottom: 20px;
  }
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
    & > p {
      font-family: suitRegular;
    }
  }
  & > div:last-of-type {
    display: flex;
    width: 100%;
    column-gap: 12px;
    margin-top: 40px;
    & > button {
      width: 100%;
      font-family: suitBold;
      border-radius: 10px;
      padding: 12px 0;
      cursor: pointer;
    }

    & > button:first-child {
      color: ${(props) => props.button2color};
      border: 1px solid ${(props) => props.button2color};
      background-color: white;
    }
    & > button:nth-last-child(1) {
      background-color: ${(props) => props.button1color};
      color: white;
      border: none;
    }
  }
`;

const BackWrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight};
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(34, 30, 31, 0.5);
`;

const ConfirmModal = ({ title, desc, desc2, desc3, toggle, toggleFunc, button1, button2, button1color, button2color, confirmFunc, children }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(toggle);
  }, [toggle]);

  return (
    <BackWrapper toggle={toggle} onClick={toggleFunc}>
      <ModalWrapper onClick={(e) => e.stopPropagation()} show={show} button1color={button1color} button2color={button2color}>
        <h1>{title}</h1>
        <div>
          <p>{desc}</p>
          <p>{desc2}</p>
          <p>{desc3}</p>
        </div>
        {children}
        <div>
          <button onClick={toggleFunc}>{button2}</button>
          <button onClick={confirmFunc}>{button1}</button>
        </div>
      </ModalWrapper>
    </BackWrapper>
  );
};

export default ConfirmModal;
