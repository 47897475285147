import React from 'react';
import styled from 'styled-components';
import { isMobileFunc } from '../utils/utils';

const isMobile = isMobileFunc();

const ButtonWrapper = styled.button`
  width: ${isMobile ? '80%' : '63%'};
  border: ${(props) => props.border};
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  font-family: suitBold;
  font-size: 16px;
  padding: 17px;
  color: black;
  background-image: url(${(props) => props.img});
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: 15px center;
  cursor: pointer;
`;

function SocialLoginButton(props) {
  return (
    <ButtonWrapper img={props.img} onClick={props.onClick} border={props.border} backgroundColor={props.backgroundColor}>
      {props.text}
    </ButtonWrapper>
  );
}

export default SocialLoginButton;
