import React from 'react';
import styled from 'styled-components'
import GreenArrow from "../../asset/img/greenarrow.png";
import InformationList from "./InformationList";
import * as color from "../../css/ColorVariable";

const Wrapper = styled.div`
  transition: all 1s;
`

const ToggleMenu = styled.div`
  cursor: pointer;
  padding-bottom : ${props => props.toggle ? '15px' : '0px'};
  transition : padding-bottom 1s;
  & > img {
    transform: ${props => props.toggle ? 'rotate(270deg)' : 'rotate(90deg)'} !important;
    transition: all .5s;
  }
`

const SlideBox = styled.div`
  display : flex;
  flex-direction: column;
  row-gap : 24px;
  padding-top : ${props => props.toggle ? '15px' : '0px'};
  column-gap: 24px;
  visibility: ${props => props.toggle ? 'visible' : 'hidden'};
  overflow : hidden;
  width: 100%;
  max-height: ${props => props.toggle ? '30vh' : '0'};
  margin-top: 10px;
  transition: visibility 1s , max-height 1s , padding-top .5s;
`

const InformGraphBox = styled.div`
  display: flex;
  width: 100%;
  transition: padding-top .5s , min-height .5s;
  border-radius: 10px;
  overflow: hidden;
  min-height: ${props => props.toggle ? '30px' : '0px'};
`

const GraphBox = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props => props.color};
`

function InformationBox({arr , title}) {

    const [toggleInformationBox , setToggleInformationBox] = React.useState(false);
    const colors = [color.mainColor , '#F4E661' , '#8AFFA4' , '#65F2D9' , '#65C8F2' , '#756BF1' , '#B287F8'];

    const handelToggleInformationBox = () => {
        setToggleInformationBox(!toggleInformationBox);
    }

    return (
        <Wrapper toggle={toggleInformationBox}>
            <ToggleMenu toggle={toggleInformationBox} onClick={handelToggleInformationBox}>
                <p>{title}</p>
                <img src={GreenArrow} alt=""/>
            </ToggleMenu>
            <InformGraphBox toggle={toggleInformationBox}>
                {
                    arr === undefined ? '' : arr.map((item , index) => (
                        <GraphBox width={item.dataPercent} color={colors[index]}/>
                    ))
                }
            </InformGraphBox>
            <SlideBox toggle={toggleInformationBox}>
                {arr === undefined ? '' : arr.map((item , index) => (
                    <InformationList
                        index={index}
                        dataName={item.dataName}
                        dataCount={item.dataCount}
                        dataPercent={item.dataPercent}
                    />
                ))}
            </SlideBox>
        </Wrapper>
    );
}

export default InformationBox;