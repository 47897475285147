import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';
import Tag from './TagWithNoImg';
import Magnifier from '../../asset/img/magnifier.png';
import Plus from '../../asset/img/plus.png';
import { DATA_API_URL, LINK_API_URL } from '../../Url';
import TokenExpiredModal from '../Modal/TokenExpiredModal';
import CompleteModal from '../Modal/CompleteModal';

const cookies = new Cookie();

const LinTagPopoverWrapper = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 15px;
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid ${color.mainColor};
  border-radius: 10px;
  padding: 15px;
  background-color: white;
  z-index: 20;
  & > input {
    background-image: url(${Magnifier});
    background-color: #f1f7f4;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    &::placeholder {
      font-family: suitRegular;
      font-size: 16px;
      color: #a0b1ab;
    }
  }
  & > div {
    display: flex;
    column-gap: 10px;
    align-items: center;
    row-gap: 15px;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    & > img {
      width: 20px;
      cursor: pointer;
    }
  }
`;

function LinkTagPopover({ refreshTag, setRefreshTag, toggle, toggleFunc, urlId, linkTags, handleLinkTags, handleToggleNewLink }) {
  const [myTags, setMyTags] = React.useState([]);
  const [searchData, setSearchData] = React.useState('');
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);
  const [tagMaxErrortoggle, setTagMaxErrorToggle] = React.useState(false);
  const [maxErrorText, setMaxErrorText] = React.useState('');

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  useEffect(() => {
    setSearchData('');
  }, [refreshTag]);

  useEffect(() => {
    if (!toggle) return;
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/tags',
      params: {
        token: cookies.get('token'),
        searchData: searchData,
        tagNameList: [],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setMyTags(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchData, toggle]);

  const tagRegister = (tagName) => {
    axios({
      method: 'post',
      url: LINK_API_URL + '/sorti/api/link/tag/regist',
      params: {
        token: cookies.get('token'),
        tagName: tagName,
        urlId: urlId,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          if (!!setRefreshTag) {
            setRefreshTag(!refreshTag);
          } else {
            window.location.reload();
          }
          //   handleAddLink(tagName)
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else if (response.data.result['code'] === '105') {
          setMaxErrorText(response.data.result['message']);
        }
      })
      .catch(function (error) {});
  };

  const closeMaxErrorModal = () => {
    setTagMaxErrorToggle(false);
    setRefreshTag(!refreshTag);
    setMaxErrorText('');
    toggleFunc();
  };

  useEffect(() => {
    if (!maxErrorText || maxErrorText === '') return;
    setTagMaxErrorToggle(true);
  }, [maxErrorText]);

  return (
    <>
      <LinTagPopoverWrapper toggle={toggle}>
        <input value={searchData} type='text' placeholder={'태그 검색'} onChange={handleSearchData} />
        <div>
          <img src={Plus} alt='' onClick={handleToggleNewLink} />
          {myTags === [] ? '' : myTags.map((item, key) => <Tag key={key} text={item} tagRegister={() => tagRegister(item)} />)}
        </div>
      </LinTagPopoverWrapper>
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <CompleteModal toggle={tagMaxErrortoggle} toggleFunc={closeMaxErrorModal} confirmText={maxErrorText} confirmFunc={closeMaxErrorModal} />
    </>
  );
}

export default LinkTagPopover;
