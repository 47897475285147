import React from 'react'
import styled from "styled-components";

import Cancel from '../../asset/img/tagdelete.png';

const TagWrapper = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    column-gap: 10px; 
    box-sizing : border-box;
    background-color : ${props => props.toggle ? 'D0E3DC' : 'white'} ;
    padding : 5px 10px;
    border-radius : 10px;
    & > p {
        font-family : suitRegular;
    }
    & > img {
        width : 8px;
        cursor: pointer;
    }
`

function Tag({text , onDelete , tagArray}) {
  return (
    <TagWrapper>
        <p>{text}</p>
        <img src={Cancel} alt={'Cancel'} onClick={() => onDelete(text)}/>
    </TagWrapper>
  )
}

export default Tag