import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';

import Hide from '../../asset/img/hiddenlink.png';
import Pencil from '../../asset/img/pencil.png';
import Delete from '../../asset/img/delete.png';
import MyLink from '../../asset/img/tomylink.png';
import {LINK_API_URL} from "../../Url";

const cookies = new Cookie();

const PopoverWrapper = styled.div`
  display: ${props => props.toggle ? 'flex' : 'none'};
  width: 110px;
  flex-direction: column;
  row-gap: 15px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: white;
  border : 1px solid ${color.mainColor};
  border-radius: 10px;
  z-index: 100;
  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    & > p {
      font-family: suitMedium;
    }
  }
`

const HorizontalLine = styled.div`
  height: 1px;
  border-radius: 100px;
  background-color: ${color.brightgrayColor};
`

const Overlay = styled.div`
  position: fixed;
  display: ${props => props.toggle ? 'block' : 'none'};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
`

function FilterPopover({toggle , toggleFunc , handleSortType}) {

    return (
        <>
            <PopoverWrapper toggle={toggle}>
                <div onClick={() => handleSortType('최근 날짜 순')}>
                    <p>최근 날짜 순</p>
                </div>
                <HorizontalLine/>
                <div onClick={() => handleSortType('오래된 날짜 순')}>
                    <p>오래된 날짜 순</p>
                </div>
                <HorizontalLine/>
                <div onClick={() => handleSortType('클릭 많은 순')}>
                    <p>클릭 많은 순</p>
                </div>
                <HorizontalLine/>
                <div onClick={() => handleSortType('클릭 적은 순')}>
                    <p>클릭 적은 순</p>
                </div>
                <HorizontalLine/>
                <div onClick={() => handleSortType('이름 순')}>
                    <p>이름 순</p>
                </div>
            </PopoverWrapper>
            <Overlay toggle={toggle} onClick={toggleFunc}/>
        </>
    );
}

export default FilterPopover;