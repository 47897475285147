import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

const LinkHistoryBoxListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding : 5px 15px;
    background-color: ${color.whiteColor};
    border-radius: 10px;
    & > p {
      font-family: suitMedium;
      font-feature-settings: 'tnum';
    }
    & > p:first-child{
      color : ${color.mainColor};  
      width : 30px;
    }
  & > p:nth-child(2){
    width: 60px;
  }
  & > p:nth-child(3){
    width: 30px;
  }
  & > p:nth-child(4){
    width: 150px;
  }
  & > p:nth-child(5){
    width: 80px;
  }
  & > p:nth-child(6){
    width: 80px;
  }
  & > p:nth-child(7){
    width: 50px;
  }
  & > p:nth-child(8){
    width: 160px;
  }
`

function LinkHistoryBoxList({no , clientIp , device , browser , country , date , osInfo , hour}) {
    return (
        <LinkHistoryBoxListWrapper>
            <p>{no}</p>
            <p>{date}</p>
            <p>{hour}</p>
            <p>{clientIp}</p>
            <p>{device}</p>
            <p>{browser}</p>
            <p>{osInfo}</p>
            <p>{country}</p>
        </LinkHistoryBoxListWrapper>
    );
}

export default LinkHistoryBoxList;