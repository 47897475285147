import React from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';
import SortiButton from './SortiButton';

import { useNavigate } from 'react-router-dom';
import Background from '../asset/img/mainBack.png';
import BackgroundMobile from '../asset/img/mainBackMobile.png';
import { isMobileFunc } from '../utils/utils';
import Cookies from 'universal-cookie';

const isMobile = isMobileFunc();

const BannerContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${isMobile ? '90vh' : '90vh'};
  padding-top: ${isMobile ? '10vh' : '10vh'};
  background-color: ${color.mainColor};
  display: flex;
  justify-content: center;
  align-items: center;
  & > div.mainContent {
    z-index: 8;
    max-width: ${isMobile ? '768px' : '1600px'};
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > p {
      position: absolute;
      top: ${isMobile ? '30%' : '30%'};
      left: 50%;
      transform: translate(-50%, 0);
      font-family: suitExBold;
      color: white;
      font-size: ${isMobile ? '24px' : '40px'};
      text-align: center;
      line-height: ${isMobile ? '40px' : '120%'};
      z-index: 2;
      white-space: nowrap;
    }
    & > video {
      width: ${isMobile ? '160%' : '100%'};
      max-width: ${isMobile ? '640px' : ''};
    }
  }

  & > div:not(.mainContent) {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const ButtonWrapper = styled.div`
  width: 100% !important;
  max-width: 393px;
  height: 60px !important;
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: 50%;
  right: 50%;
  /* bottom: 190px; */
  ${isMobile ? 'bottom: 15%;' : 'bottom: 190px;'}
  transform: translate(-50%, 0);
  padding: 0 28px;
  box-sizing: border-box;
  & > button {
    background-color: ${color.subViolet};
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 12px;
    font-size: 18px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 6 !important;
  width: 90%;
  bottom: 0 !important;
  height: ${isMobile ? '40% !important' : '33% !important'};
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

const BackgroundImg = styled.img`
  position: absolute;
  z-index: 5 !important;
  width: ${isMobile ? '80%' : '100%'};
  top: 100;
`;

function BannerContainer() {
  const navigate = useNavigate();

  const cookies = new Cookies();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const goToSignUp = () => {
    const flag = !!cookies.get('token') && !!cookies.get('name') && !!cookies.get('email') && !!cookies.get('loginType');

    if (!!flag) {
      navigate('/dashboard/mylink');
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <BannerContainerWrapper>
        <div className='mainContent'>
          <p>
            하나뿐인 나만의 URL,
            <br />
            SORTI와 함께 시작해보세요
          </p>
          <video width='100%' height='auto' autoPlay muted loop playsInline>
            {window.navigator.userAgent.includes('iPhone') || isSafari ? (
              <source src='./Videos/mainBanner.mp4' type='video/mp4' />
            ) : (
              <source src='./Videos/mainBanner.webm' type='video/webm' />
            )}
          </video>
          <ButtonWrapper>
            <SortiButton width onClick={goToSignUp} text='SORTI 시작하기' />
          </ButtonWrapper>
        </div>
        <BackgroundImg src={isMobile ? BackgroundMobile : Background} alt='background' />
        <Overlay />
      </BannerContainerWrapper>
    </>
  );
}

export default BannerContainer;
