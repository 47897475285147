import React from "react";
import styled from "styled-components";
import * as color from "../../css/ColorVariable";

const SideBarListWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: fit-content;
  position: relative;
  padding: 15px 17px;
  border-radius: 10px;
  color: ${(props) => (props.hover ? "#ffffff" : "")};
  background-color: ${(props) => (props.hover ? color.mainColor : "")};
  transition: 0.2s;
  cursor: pointer;
  & > img {
    width: 20px;
  }
  & > p {
    font-family: suitSemiBold;
    max-width: ${(props) => (props.toggle ? "0px" : "120px")};
    overflow: hidden;
    visibility: ${(props) => (props.toggle ? "hidden" : "visible")};
    white-space: nowrap;
    transition: 0.5s;
  }
`;

function SideBarList({ img, activeImg, text, onClick, toggle }) {
  const [hover, setHover] = React.useState(false);

  const handleHover = () => {
    setHover(!hover);
  };

  return (
    <SideBarListWrapper
      className={"sidebar_list"}
      toggle={toggle}
      hover={hover}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={onClick}
    >
      <img src={hover ? activeImg : img} alt="img" />
      <p>{text}</p>
    </SideBarListWrapper>
  );
}

export default SideBarList;
