import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

import SortiLogo from '../asset/img/sortiLogo.png';

import SortiButton from './SortiButton';

import Cookie from 'universal-cookie';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 1000;
  font-family: suitBold;
  align-items: center;
  justify-content: space-between;
  padding: 80px 100px 23px 100px;
  background: ${(props) => (props.top ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)')};
  backdrop-filter: blur(30px);
  transform: ${(props) => (props.top ? 'translateY(-35%)' : 'translateY(-35%)')};
  transition: 0.5s;
  box-shadow: ${(props) => (props.top ? '' : '0px 1px 20px #D0E3DC')};
  & > img {
    height: 30px;
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
    column-gap: 60px;
    & > p {
      color: ${color.mainColor};
      cursor: pointer;
    }
  }
`;

function MenuBar(props) {
  const cookies = new Cookie();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const goToLogin = () => {
    window.location.href = '/login';
  };

  const goToMain = () => {
    window.location.href = '/';
  };

  const goToDashboard = () => {
    window.location.href = '/dashboard/mylink';
  };

  useEffect(() => {
    const name = cookies.get('name');
    const loginType = cookies.get('loginType');
    const token = cookies.get('token');
    if (!!name && name !== '' && !!loginType && loginType !== '' && !!token && token !== '') {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Wrapper top={props.top} style={{ width: `calc(100% - 200px` }}>
      <img src={SortiLogo} alt={''} onClick={goToMain} />
      <div>
        {/*<p onClick={goToSignUp}>회원가입</p>*/}
        <SortiButton onClick={isLoggedIn ? goToDashboard : goToLogin} text={isLoggedIn ? '대시보드' : '로그인'} />
      </div>
    </Wrapper>
  );
}

export default MenuBar;
