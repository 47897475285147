import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import * as color from '../css/ColorVariable';
import { DATA_API_URL } from '../Url';

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import LinkInfoBox from '../components/Box/LinkInfoBox';
import TagContainer from '../components/Box/TagContainer';

import { Bar } from 'react-chartjs-2';
import Link from '../asset/img/linkIcon.png';
import LoadingMotion from '../asset/img/Loading-Motion.gif';
import Mouse from '../asset/img/mouse.png';
import Click from '../asset/img/mouseIcon.png';
import Month from '../asset/img/thisMonthIcon.png';
import EmptyGraphBox from '../components/Box/EmptyGraphBox';
import EmptyLinkBox from '../components/Box/EmptyLinkBox';
import LinkCreateContainer from '../components/Box/LinkCreateContainer';
import LinkDetailBox from '../components/Box/LinkDetailBox';
import SearchControlBox from '../components/Box/SearchControlBox';
import TokenExpiredModal from '../components/Modal/TokenExpiredModal';
import ToggleButton from '../components/Button/ToggleButton';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const cookies = new Cookie();

const SearchWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  & > div:first-of-type {
    margin-bottom: 80px;
    & > p {
      font-size: 32px;
      line-height: 48px;
      font-family: 'suitExBold';
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-family: 'suitMedium';
    }
    & > div:first-of-type {
      & > span {
        color: ${color.darkgrayColor};
        & > strong {
          color: #252525;
        }
      }
    }
    & > div:last-of-type {
      & > span {
        margin-right: 20px;
      }
      & > div {
        & > p {
          padding-bottom: 4px;
        }
      }
    }
  }
`;

const LoadingOverlay = styled.div`
  display: ${(props) => (props.state ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: #ffffffb3;
  top: 0;
  left: 0;
  z-index: 999999999;
  & > img {
    width: 216px;
    height: 216px;
  }
`;

const DashboardMyLinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 40px 30px;
  overflow-y: scroll;
  & > h1 {
    font-family: suitExBold;
    font-weight: 500;
    font-size: 24px;
  }
`;

const LinkInfoBoxContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const GraphBoxWrapper = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  & > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 8px;
    & > p {
      font-family: suitMedium;
      color: ${color.mainColor};
    }
    & > span {
      font-family: suitMedium;
    }
    & > img {
      width: 10px;
    }
  }
  & > div:last-child {
    height: 100%;
    & > canvas {
      min-width: calc(100% - 32px) !important;
      height: 300px !important;
    }
  }
`;

function SearchPage({ searchDataTitle, handleSearchDataTitle }) {
  const [myLinkData, setMyLinkData] = React.useState({});
  const [myLinks, setMyLinks] = React.useState('');
  const [searchStart, setSearchStart] = React.useState('');
  const [searchEnd, setSearchEnd] = React.useState('');
  const [sortType, setSortType] = React.useState('최근 날짜 순');

  const [tagArray, setTagArray] = React.useState([]);
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const [refreshTag, setRefreshTag] = React.useState(false);

  const [loadingToggle, setLoadingToggle] = React.useState(false);

  const [hiddenList, setHiddenList] = React.useState([]);
  const [hiddenToggle, setHiddenToggle] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setLoadingToggle(false);
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const handleTagArray = (tagName) => {
    if (tagArray.includes(tagName)) {
      return 0;
    } else {
      setTagArray([...tagArray, tagName]);
    }
  };

  const handleDeleteTagArray = (tagName) => {
    setTagArray(tagArray.filter((tagArray) => tagArray !== tagName));
  };

  const handleSortType = (name) => {
    setSortType(name);
  };

  const handleStart = (e) => {
    setSearchStart(e.target.value);
  };

  const handleEnd = (e) => {
    setSearchEnd(e.target.value);
  };

  useEffect(() => {
    setLoadingToggle(true);

    let pObj = { token: cookies.get('token') };
    if (!!searchDataTitle && searchDataTitle !== '') {
      pObj.tagNameList = [];
      pObj.searchData = searchDataTitle;
      pObj.startDate = '';
      pObj.endDate = '';
      pObj.sortType = sortType;
    } else {
      pObj.tagNameList = tagArray;
      pObj.searchData = '';
      pObj.startDate = searchStart;
      pObj.endDate = searchEnd;
      pObj.sortType = sortType;
    }

    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/links/my',
      params: pObj,
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setMyLinks(response.data.data.linkData);
          setMyLinkData(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchDataTitle, searchStart, searchEnd, sortType, tagArray, refreshTag]);

  useEffect(() => {
    if (!myLinks || myLinks === '') return;
    setLoadingToggle(false);
  }, [myLinks]);

  useEffect(() => {
    if (!hiddenToggle || !searchDataTitle || searchDataTitle === '') {
      setHiddenList([]);
      return;
    }
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/links/hidden',
      params: {
        token: cookies.get('token'),
        tagNameList: [],
        searchData: searchDataTitle,
        startDate: '',
        endDate: '',
        sortType: sortType,
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setHiddenList(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchDataTitle, hiddenToggle]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const data = {
    labels: myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dateTime),
    datasets: [
      {
        label: '',
        data: myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount),
        backgroundColor: [color.mainColor],
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'bar',
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgb(255 ,255 ,255)',
        titleFont: {
          family: 'suitMedium',
          weight: 500,
        },
        bodyFont: {
          family: 'suitMedium',
        },
        titleColor: 'rgb(0 ,0 ,0)',
        bodyColor: 'rgb(0 ,0 ,0)',
        borderColor: color.mainColor,
        borderWidth: 1,
        displayColors: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          offset: false,
        },
        ticks: {
          callback: function (val, index) {
            if (index === 29) {
              return this.getLabelForValue('오늘');
            } else {
              return index % 5 === 0 ? this.getLabelForValue(val).substr(5, 10).replace('-', '.') : '';
            }
          },
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor, color.darkgrayColor, color.darkgrayColor, color.darkgrayColor, color.mainColor],
        },
      },
      y: {
        min: 0,
        max: Math.max(...(myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount))),
        ticks: {
          padding: 10,
          stepSize: Math.round(Math.max(...(myLinkData.dayLinkClick === undefined ? '' : myLinkData.dayLinkClick.map((item) => item.dayLinkClickCount))) / 2),
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor],
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  return (
    <DashboardMyLinkWrapper>
      <SearchWrapper>
        <div>
          <p>'{searchDataTitle}'이 포함된 검색 결과</p>
        </div>
        <div>
          <div>
            <span>
              총 <strong>{numberWithCommas(hiddenToggle ? myLinks.length + hiddenList.length : myLinks.length)}</strong> 개의 링크
            </span>
          </div>
          <div>
            <span>숨겨진 링크 검색 결과에 표시</span>
            <ToggleButton toggle={hiddenToggle} handleToggle={() => setHiddenToggle(!hiddenToggle)} />
          </div>
        </div>
      </SearchWrapper>

      {myLinks.concat(hiddenList).length === 0 ? (
        <EmptyLinkBox />
      ) : myLinks === '' ? (
        ''
      ) : (
        myLinks.concat(hiddenList).map((item, key) => {
          return (
            <LinkDetailBox
              setRefreshTag={setRefreshTag}
              refreshTag={refreshTag}
              key={key}
              urlId={item.urlId}
              originUrl={item.originUrl}
              customUrl={item.customUrl}
              favoriteStatus={item.favoriteStatus}
              insertDate={item.insertDate}
              clickCount={item.clickCount}
              tag={item.tag}
              urlTitle={item.urlTitle}
              usId={item.usId}
              tagArray={tagArray}
            />
          );
        })
      )}
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <LoadingOverlay state={loadingToggle}>
        <img src={LoadingMotion} alt='loading motion gif' />
      </LoadingOverlay>
    </DashboardMyLinkWrapper>
  );
}

export default SearchPage;
