import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { DATA_API_URL } from '../Url';
import * as color from '../css/ColorVariable';
import LinkDetailBox from '../components/Box/LinkDetailBox';
import TagContainer from '../components/Box/TagContainer';
import SearchControlBox from '../components/Box/SearchControlBox';
import TokenExpiredModal from '../components/Modal/TokenExpiredModal';
import SearchPage from './SearchPage';
import EmptyLinkBox from '../components/Box/EmptyLinkBox';
import SearchTagContainer from '../components/Box/SearchTagContainer';
import useTitle from '../utils/useTitle';

const cookies = new Cookie();

const ContainerWrapper = styled.div`
  margin-top: ${(props) => (!!props.mt ? props.mt : 70)}px;
`;

const SearchWithLinksWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
  width: 100%;
  box-sizing: border-box;
  & > div:first-child {
    position: sticky;
    top: 14px;
    margin-top: 14px;
  }
  & > div:last-child {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    & > div:first-child {
      position: sticky;
      top: 0px;
      background-color: #f2f1fd;
      z-index: 4;
      height: 56px;
      align-items: center;
      display: flex;
      width: 100%;
      & > p {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
`;

const DashboardMyLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 4% 2% 0 2%;
  background-color: #f2f1fd;
  min-height: 100%;
  & > h1 {
    font-family: suitExBold;
    font-weight: 500;
    font-size: 24px;
  }
`;

function DashboardHiddenLink({ searchDataTitle, handleSearchDataTitle, setBackColor }) {
  useTitle('SORTI | 숨겨진 링크');
  const [myLinks, setMyLinks] = React.useState('');
  const [searchStart, setSearchStart] = React.useState('');
  const [searchEnd, setSearchEnd] = React.useState('');
  const [sortType, setSortType] = React.useState('최근 날짜 순');
  const [refreshTag, setRefreshTag] = React.useState(false);
  const [searchData, setSearchData] = React.useState('');

  const [tagArray, setTagArray] = React.useState([]);
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const handleTagArray = (tagName) => {
    if (tagArray.includes(tagName)) {
      return 0;
    } else {
      setTagArray([...tagArray, tagName]);
    }
  };

  const handleDeleteTagArray = (tagName) => {
    setTagArray(tagArray.filter((tagArray) => tagArray !== tagName));
  };
  const handleSortType = (name) => {
    setSortType(name);
  };

  const handleStart = (e) => {
    setSearchStart(e.target.value);
  };

  const handleEnd = (e) => {
    setSearchEnd(e.target.value);
  };
  useEffect(() => {
    let search = !!searchDataTitle && searchDataTitle !== '' ? searchDataTitle : searchData;
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/links/hidden',
      params: {
        token: cookies.get('token'),
        tagNameList: tagArray,
        searchData: search,
        startDate: searchStart,
        endDate: searchEnd,
        sortType: sortType,
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setMyLinks(response.data.data);
          console.log(myLinks);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchDataTitle, searchStart, searchEnd, sortType, tagArray, refreshTag, searchData]);

  useEffect(() => {
    setBackColor('#f2f1fd');
  }, []);

  if (!!searchDataTitle && searchDataTitle !== '') {
    return <SearchPage searchDataTitle={searchDataTitle} handleSearchDataTitle={handleSearchDataTitle} />;
  }
  return (
    <DashboardMyLinkWrapper>
      <h1>숨겨진 링크</h1>
      <ContainerWrapper mt={56}>
        <SearchWithLinksWrapper>
          <div>
            <SearchTagContainer
              searchStart={searchStart}
              searchEnd={searchEnd}
              handleStart={handleStart}
              handleEnd={handleEnd}
              tagArray={tagArray}
              setTagArray={setTagArray}
              searchData={searchData}
              setSearchData={setSearchData}
            />
          </div>
          <div>
            <div>
              <SearchControlBox linkCount={myLinks.length} sortType={sortType} handleSortType={handleSortType} />
            </div>
            <div>
              {myLinks.length === 0 ? (
                <EmptyLinkBox />
              ) : myLinks === '' ? (
                ''
              ) : (
                myLinks.map((item, key) => {
                  return (
                    <LinkDetailBox
                      setRefreshTag={setRefreshTag}
                      refreshTag={refreshTag}
                      key={key}
                      urlId={item.urlId}
                      originUrl={item.originUrl}
                      customUrl={item.customUrl}
                      favoriteStatus={item.favoriteStatus}
                      insertDate={item.insertDate}
                      clickCount={item.clickCount}
                      tag={item.tag}
                      urlTitle={item.urlTitle}
                      usId={item.usId}
                      tagArray={tagArray}
                    />
                  );
                })
              )}
            </div>
          </div>
        </SearchWithLinksWrapper>
      </ContainerWrapper>
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
    </DashboardMyLinkWrapper>
  );
}

export default DashboardHiddenLink;
