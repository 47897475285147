import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { triggerBase64Download } from 'react-base64-downloader';

import IconButton from '../Button/IconButton';
import ToastBox from './ToastBox';

import CopyIcon from '../../asset/img/copyIcon.png';
import QrIcon from '../../asset/img/qrIcon.png';
import ThreeDot from '../../asset/img/threedot.png';
import ShareIcon from '../../asset/img/shareIcon.png';
import ShareSample1 from '../../asset/img/shareSample1.png';
import ShareSample2 from '../../asset/img/shareSample2.png';
import ShareSample3 from '../../asset/img/shareSample3.png';
import ShareSample4 from '../../asset/img/shareSample4.png';
import ShareSample5 from '../../asset/img/shareSample5.png';

import Favorite from '../../asset/img/nofavor.png';
import FavoriteActive from '../../asset/img/favoritestar.png';
import CopyLinkGreen from '../../asset/img/copylinkgreen.png';
import Cancel from '../../asset/img/tagdelete.png';
import UpdateUrl from '../../asset/img/updateurl.png';
import Mouse from '../../asset/img/mouse.png';
import Plus from '../../asset/img/plus.png';
import KakaoShare from '../../asset/img/kakaoShare.png';
import Tag from './Tag';

import { DATA_API_URL, LINK_API_URL } from '../../Url';
import ContentModal from '../Modal/ContentModal';
import LinkDetailPopover from './LinkDetailPopover';
import SortiButton from '../SortiButton';
import ConfirmModal from '../Modal/ConfirmModal';
import CompleteModal from '../Modal/CompleteModal';
import LinkTagPopover from './LinkTagPopover';
import { useNavigate } from 'react-router-dom';
import TokenExpiredModal from '../Modal/TokenExpiredModal';

const cookies = new Cookie();

const scrH = document.getElementById('scroll-height');

const ShareContents = styled.div`
  display: flex;
  font-family: suitRegular;

  & > div:first-child {
    display: flex;
    margin-right: 20px;
    & > div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      & > img {
        width: 80px;
        height: 80px;
      }
      & > span {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 16px;
      }
    }
  }
  & > div:not(:first-child) {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 10px 0px;
      & > img {
        width: 60px;
        height: 60px;
        margin: 0 10px;
      }
    }
    & > span {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 1px 0;
      align-items: center;
      margin-top: 16px;
      font-size: 14px;
      color: ${color.darkgrayColor};
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  display: ${(props) => (props.toggle ? 'block' : 'none')} !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 15;
`;

const LinkDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  & > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    & > p {
      font-family: suitRegular;
      font-size: 14px;
      color: ${color.darkgrayColor};
    }
    & > div {
      display: flex;
      column-gap: 15px;
      & > img {
        width: 30px;
        cursor: pointer;
      }
    }
  }
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    column-gap: 10px;
    & > p {
      font-family: suitExBold;
      font-size: 24px;
      cursor: pointer;
    }
    & > img {
      width: 18px;
      cursor: pointer;
    }
  }
  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    column-gap: 10px;
    & > a {
      font-family: suitSemiBold;
      color: black;
      text-decoration: none;
      max-width: 100%;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    & > img {
      width: 14px;
      cursor: pointer;
    }
  }
  & > div:nth-child(4) {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    & > a {
      font-family: suitRegular;
      font-size: 16px;
      color: ${color.darkgrayColor};
      text-decoration: none;
      max-width: 100%;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 20px;
    }
    & > img {
      width: 15px;
      cursor: pointer;
    }
  }
  & > div:nth-child(5) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div:first-child {
      display: flex;
      align-items: center;
      column-gap: 15px;
      & > div {
        border: 1px solid ${color.brightgrayColor};
      }
    }
    & > div:last-child {
      display: flex;
      column-gap: 7px;
      align-items: center;
      & > p {
        font-family: suitRegular;
      }
      & > img {
        width: 14px;
      }
    }
  }
`;

const QrContents = styled.div`
  text-align: center;
  & > img {
    width: 200px;
  }
`;

const UrlModifyContents = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
    width: 100%;
    & > p {
      font-family: suitMedium;
      color: ${color.mainColor};
    }
    & > div {
      box-sizing: border-box;
      border: 1px solid ${color.brightgrayColor};
      border-radius: 10px;
      width: 100%;
      font-family: suitRegular;
      padding: 4%;
      & > p {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 원하는 라인수
        -webkit-box-orient: vertical;
      }
    }
    & > textarea {
      margin: 0;
      vertical-align: middle;
      line-height: 1.2;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid ${color.brightgrayColor};
      border-radius: 8px;
      padding: 4%;
      resize: none;
      overflow: hidden;
      /* text-overflow: ellipsis; */
      font-size: 16px;
      font-family: suitRegular;
    }
  }
  & > img {
    padding-top: 30px;
    width: 15px;
  }
`;

const TitleUpdate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  & > div {
    width: 400px;
    border-bottom: 1px solid ${color.mainColor};
    & > input {
      border: none;
      width: 90%;
      font-family: suitExBold;
      font-size: 24px;
      &:focus {
        outline: none;
      }
    }
    & > img {
      width: 20px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
  & > button {
    padding: 5px 10px;
    box-shadow: none;
  }
`;

const LinkPopover = styled.div`
  position: relative;
  border: none !important;
  display: flex;
  & > img {
    width: 20px;
    cursor: pointer;
  }
`;

const NewTag = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  & > input {
    border: none;
    width: 60%;
    border-bottom: 1px solid ${color.mainColor};
    &:focus {
      outline: none;
    }
  }
  & > button {
    color: white;
    border: none;
    background-color: ${color.mainColor};
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  & > img {
    width: 15px;
    cursor: pointer;
    position: absolute;
    left: 55%;
  }
`;

function LinkDetailBox({ refreshTag, setRefreshTag, urlId, originUrl, customUrl, favoriteStatus, insertDate, clickCount, tag, urlTitle, tagArray }) {
  const navigate = useNavigate();
  //let linkTags = tag;

  const [favorToggle, setFavorToggle] = React.useState(favoriteStatus === 'Y');
  const [toast, setToast] = React.useState(false);
  const [QRImg, setQRImg] = React.useState('');

  const [toggleQRModal, setToggleQRModal] = React.useState(false);
  const [toggleOriginModifyModal, setToggleOriginModifyModal] = React.useState(false);
  const [togglePopover, setTogglePopover] = React.useState(false);
  const [toggleTitleUpdate, setToggleTitleUpdate] = React.useState(false);

  const [toggleShareModal, setToggleShareModal] = React.useState(false);

  const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  const [toggleHideModal, setToggleHideModal] = React.useState(false);
  const [toggleToMyModal, setToggleToMyModal] = React.useState(false);

  const [title, setTitle] = React.useState(urlTitle);
  const [newOriginUrl, setNewOriginUrl] = React.useState('');

  const [toggleConfirmModal, setToggleConfirmModal] = React.useState(false);

  const [toggleLinkTagPopover, setToggleLinkTagPopover] = React.useState(false);

  const [confirmText, setConfirmText] = React.useState('');

  const [linkTags, setLinkTags] = React.useState(tag);
  const [toggleNewLink, setToggleNewLink] = React.useState(false);
  const [newLink, setNewLink] = React.useState('');
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const [tagMaxErrortoggle, setTagMaxErrorToggle] = React.useState(false);
  const [maxErrorText, setMaxErrorText] = React.useState('');

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const goToLinkDetail = () => {
    navigate(`/dashboard/linkdetail/:${urlId}`);
  };

  const handleNewLink = (e) => {
    setNewLink(e.target.value);
  };

  const handleToggleNewLink = () => {
    setToggleNewLink(!toggleNewLink);
  };

  const handleToggleLinkTagPopover = (event) => {
    // event.stopPropagation();
    setToggleLinkTagPopover(!toggleLinkTagPopover);
  };

  const handleConfirmModal = () => {
    setToggleConfirmModal(!toggleConfirmModal);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleToMyModal = () => {
    setToggleToMyModal(!toggleToMyModal);
  };

  const handleDeleteModal = () => {
    setToggleDeleteModal(!toggleDeleteModal);
  };

  const handleHideModal = () => {
    setToggleHideModal(!toggleHideModal);
  };

  const handleTitleUpdate = () => {
    setToggleTitleUpdate(!toggleTitleUpdate);
  };

  const handleNewOriginUrl = (e) => {
    setNewOriginUrl(e.target.value);
    const box = document.getElementById('LinkDetailBox-LinkChange-textarea');

    if (box.scrollHeight > 500) {
    } else {
      box.style.height = 1;
      box.style.height = box.scrollHeight + 2 + 'px';
    }
  };

  const handlePopover = (event) => {
    event.stopPropagation();
    setTogglePopover(!togglePopover);
  };

  //클립보드 캡쳐
  const copyClipBoard = (text, event) => {
    event.stopPropagation();

    handleToast();
    navigator.clipboard.writeText(text);
  };

  const handleToast = () => {
    setToast(!toast);
  };

  const handleToggleQRModal = (event) => {
    event.stopPropagation();
    setToggleQRModal(!toggleQRModal);
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/qr',
      params: {
        token: cookies.get('token'),
        urlId: urlId.toString(),
        image64:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAAUCAYAAAA6NOUqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIuSURBVHgB7VfLbcJAEF2iFEAJlEAJlEAJLsEFRILcE0EHpoFIPuUKFQCHKFdbyjWSfYsiRdrsszSrx3r8CUK+OE8aAcvbmXk7s2OYWGtnZqSYOPHWjBP5nRkxRi3+vu3L8/lc2XQ6NbPZzMzn80ZuWZYVN89zz8U+jZemaW0dexaLRS+uAHzsE+7hcDDL5dL0hlXgRFiXPGbBhblAdr1e1/jb7dY6oTW+xo2iqMZj/6fTyXPxuYkLQ8yiKCqT+FrMBmQ18VmWdQZlIFgb11Xngo/PfQT9RTxyljUc7tXikyTxjuI49ieLdSS+2Ww8d7/fXySDwOCF1UVnaOIZrl39umt1Xwj4g3FnyRo6VHg3F4+EIFAqEYIT5kMJDwaJd4nHQcv6brerxeIuqKm4lXi+P2yYAUgQgQTCY3EMFir7eA2JinEsjjGoeHHWdDeRhHSCiMeaBu4MTbxmq9VK9TWYeAFEon1xZzm4tCU/EcJqhYNTwOLxnn2Ew3Fw8RCMCR7eOxxAOMB40iM5HBSAVxbFCYV3HvFYGA/HwcVj0rIgtC4c8hyQKoeJN10T7gpt4IXDURuw14jPvl1+b8/Onmzx89UtHj8y2gSFd7LtdwHW5XEk4DnA4GkvjzqGHJo2XFk8/AiSz6M1x4fK0vK9W7zf6B55SBRB8Qqn0tZ9+GhfrYIyTMNHI7ioGifPQFHgF3E0oCj4nrsM1Y7yFxt/vGpbsv+/tGPF6P/SPppxovwFBGawCSlJt4YAAAAASUVORK5CYII=',
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setQRImg(response.data.data.qr_image);
          setTitle(urlTitle);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleToggleShareModal = () => {
    setToggleShareModal(!toggleShareModal);
  };

  const handleToggleLinkModifyModal = (event) => {
    event.stopPropagation();
    setToggleOriginModifyModal(!toggleOriginModifyModal);
  };

  //카카오 공유
  const handleKakaoShare = () => {
    if (!window.Kakao) return;

    const kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      kakao.init('e78ff373ae1bbe34241103908d02677d');
    }

    if (!!urlTitle) {
      kakao.Share.sendCustom({
        templateId: 86295,
        templateArgs: {
          url: `https://sorti.me/${customUrl}`,
          description: urlTitle,
        },
      });
    } else {
      axios({
        method: 'get',
        url: DATA_API_URL + '/sorti/api/data/link/title',
        params: {
          token: cookies.get('token'),
          urlId: urlId,
        },
      })
        .then((response) => {
          kakao.Share.sendCustom({
            templateId: 86295,
            templateArgs: {
              url: `https://sorti.me/${customUrl}`,
              description: response.data.data,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const resetTitle = (event) => {
    event.stopPropagation();
    setTitle('');
  };

  const linkTagDelete = (tagName, event) => {
    // event.stopPropagation();
    axios({
      method: 'delete',
      url: LINK_API_URL + '/sorti/api/link/tag/unregist',
      params: {
        token: cookies.get('token'),
        urlId: parseInt(urlId),
        tagName: tagName,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          if (!!setRefreshTag) {
            setRefreshTag(!refreshTag);
          } else {
            window.location.reload();
          }
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  //즐겨찾기 등록
  const linkFavorRegister = (event) => {
    event.stopPropagation();
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/favorite/regist',
      params: {
        token: cookies.get('token'),
        urlId: [urlId],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setFavorToggle(true);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
        console.log(linkTags);
      })
      .catch(function (error) {});
  };

  //즐겨찾기 해제
  const linkFavorUnRegister = (event) => {
    event.stopPropagation();
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/favorite/unregist',

      params: {
        token: cookies.get('token'),
        urlId: [urlId],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setFavorToggle(true);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
        setFavorToggle(false);
      })
      .catch(function (error) {});
  };

  const UpdateUrlFunc = () => {
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/originUrl',
      params: {
        token: cookies.get('token'),
        customUrl: customUrl,
        newOriginUrl: newOriginUrl,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          window.location.reload();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const UpdateTitle = (event) => {
    event.stopPropagation();
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/urlTitle',
      params: {
        token: cookies.get('token'),
        urlId: parseInt(urlId),
        newUrlTitle: title,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          window.location.reload();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const hideLink = () => {
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/hidden/regist',
      params: {
        token: cookies.get('token'),
        urlId: [urlId],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setConfirmText('링크를 숨겼습니다.');
          handleHideModal();
          handleConfirmModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const deleteLink = () => {
    axios({
      method: 'delete',
      url: LINK_API_URL + '/sorti/api/link',
      params: {
        token: cookies.get('token'),
        urlId: [urlId],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setConfirmText('링크를 삭제했습니다.');
          handleDeleteModal();
          handleConfirmModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const toMyLink = () => {
    axios({
      method: 'put',
      url: LINK_API_URL + '/sorti/api/link/hidden/unregist',
      params: {
        token: cookies.get('token'),
        urlId: [urlId],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setConfirmText('링크를 숨기기 해제했습니다.');
          handleToMyModal();
          handleConfirmModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const registerTag = () => {
    axios({
      method: 'post',
      url: LINK_API_URL + '/sorti/api/link/tag/regist',
      params: {
        token: cookies.get('token'),
        urlId: parseInt(urlId),
        tagName: newLink,
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          if (!!setRefreshTag) {
            setRefreshTag(!refreshTag);
            handleToggleNewLink();
          } else {
            window.location.reload();
          }
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else if (response.data.result['code'] === '105') {
          setMaxErrorText(response.data.result['message']);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const closeMaxErrorModal = () => {
    setTagMaxErrorToggle(false);
    setRefreshTag(!refreshTag);
    setMaxErrorText('');
    handleToggleNewLink();
  };

  useEffect(() => {
    if (!maxErrorText || maxErrorText === '') return;
    setTagMaxErrorToggle(true);
  }, [maxErrorText]);

  const downloadFunction = () => {
    const base64 = `data:image/png;base64,${QRImg}`;
    triggerBase64Download(base64, 'SORTI_QR');
  };

  return (
    <LinkDetailBoxWrapper>
      <div>
        <p>
          {insertDate.substring(0, 4)}. {insertDate.substring(5, 7)}. {insertDate.substring(8, 10)}
        </p>
        <div>
          <IconButton text='QR 코드' img={QrIcon} background={'#F6F1FD'} imgWidth={'11px'} cursor={'pointer'} onClick={(e) => handleToggleQRModal(e)} />
          <IconButton
            text='링크 복사'
            img={CopyIcon}
            background={'#F2F1FD'}
            imgWidth={'12.35px'}
            cursor={'pointer'}
            onClick={(e) => copyClipBoard(`https://sorti.me/${customUrl}`, e)}
          />
          <img src={ShareIcon} alt='ShareIcon' onClick={() => setToggleShareModal(true)} />
          <img src={ThreeDot} alt='ThreeDot' onClick={(e) => handlePopover(e)} />
          <LinkDetailPopover
            toggle={togglePopover}
            toggleFunc={handlePopover}
            urlId={urlId}
            handleTitleUpdate={handleTitleUpdate}
            deleteModal={handleDeleteModal}
            hideModal={handleHideModal}
            tomyModal={handleToMyModal}
          />
        </div>
      </div>
      <div>
        {toggleTitleUpdate ? (
          <TitleUpdate>
            <div>
              <input type='text' value={title} onChange={handleTitle} />
              <img src={Plus} alt='Plus' onClick={(e) => resetTitle(e)} />
            </div>
            <SortiButton text={'저장'} onClick={(e) => UpdateTitle(e)} />
          </TitleUpdate>
        ) : (
          <p onClick={goToLinkDetail}>{urlTitle === '' ? '링크 제목을 만들어주세요!' : urlTitle}</p>
        )}
        {favorToggle ? (
          <img src={FavoriteActive} alt='FavoriteActive' onClick={(e) => linkFavorUnRegister(e)} />
        ) : (
          <img src={Favorite} alt='' onClick={(e) => linkFavorRegister(e)} />
        )}
      </div>
      <div>
        <a href={`https://sorti.me/${customUrl}`} target='_blank' rel='noreferrer'>
          https://sorti.me/{customUrl}
        </a>
        <img onClick={(e) => copyClipBoard(`https://sorti.me/${customUrl}`, e)} src={CopyLinkGreen} alt={CopyLinkGreen} />
      </div>
      <div>
        <a href={originUrl} target='_blank' rel='noreferrer'>
          {originUrl}
        </a>
        <img src={UpdateUrl} alt={UpdateUrl} onClick={(e) => handleToggleLinkModifyModal(e)} />
      </div>
      <div>
        <div>
          {tag === null ? '' : tag.map((item, key) => <Tag text={item.tagName} tagArray={tagArray} key={key} onDelete={(e) => linkTagDelete(item.tagName, e)} />)}
          <LinkPopover>
            {toggleNewLink ? (
              <NewTag>
                <input type='text' placeholder={'새로운 태그'} onChange={handleNewLink} />
                <img src={Cancel} alt='Cancel' onClick={handleToggleNewLink} />
                <button onClick={registerTag}>추가</button>
              </NewTag>
            ) : (
              <img src={Plus} alt={'Plus'} onClick={(e) => handleToggleLinkTagPopover(e)} />
            )}
            <LinkTagPopover
              refreshTag={refreshTag}
              setRefreshTag={setRefreshTag}
              toggle={toggleLinkTagPopover}
              toggleFunc={handleToggleLinkTagPopover}
              urlId={urlId}
              linkTags={linkTags}
              handleLinkTags={setLinkTags}
              handleToggleNewLink={handleToggleNewLink}
            />
          </LinkPopover>
        </div>
        <div>
          <p>{clickCount}</p>
          <img src={Mouse} alt={'Mouse'} />
        </div>
      </div>
      {toast ? <ToastBox handleToast={setToast} text={'링크가 복사되었어요.'} /> : ''}
      <ContentModal
        title={'QR코드'}
        desc={'QR코드 이미지로 내 링크를 사람들에게 공유해보세요.'}
        buttonText={'QR 코드 다운로드 (.png)'}
        url={`https://sorti.me/${customUrl}`}
        urlId={urlId}
        toggle={toggleQRModal}
        toggleFunc={handleToggleQRModal}
        confirmFunc={downloadFunction}
      >
        <QrContents>
          <img src={`data:image/png;base64,${QRImg}`} alt='QRImg' />
        </QrContents>
      </ContentModal>

      <ContentModal
        width='524px'
        title={'공유하기'}
        desc={'줄여진 SORTI 링크를 사람들에게 공유해보세요!'}
        buttonText={'링크 복사하기'}
        url={`https://sorti.me/${customUrl}`}
        toggle={toggleShareModal}
        toggleFunc={handleToggleShareModal}
        confirmFunc={copyClipBoard.bind(this, `https://sorti.me/${customUrl}`)}
      >
        <ShareContents>
          <div>
            <div onClick={handleKakaoShare}>
              <img src={KakaoShare} alt='카카오 공유 이미지' />
              <span>카카오톡</span>
            </div>
          </div>
          <div>
            <div>
              <img src={ShareSample1} alt='Share Sample Icon' />
              <img src={ShareSample2} alt='Share Sample Icon' />
              <img src={ShareSample3} alt='Share Sample Icon' />
              <img src={ShareSample4} alt='Share Sample Icon' />
              <img src={ShareSample5} alt='Share Sample Icon' />
            </div>
            <span>더 많은 공유 서비스를 준비 중이예요!</span>
          </div>
        </ShareContents>
      </ContentModal>

      <ContentModal
        title={'목적지 링크 변경'}
        desc={'줄여진 SORTI 링크는 유지하고 목적지 링크만 변경할 수 있습니다.'}
        desc2={'링크를 변경해도 통계는 유지됩니다.'}
        buttonText={'원본 링크 변경하기'}
        url={`https://sorti.me/${customUrl}`}
        urlId={urlId}
        width={'524px'}
        toggle={toggleOriginModifyModal}
        toggleFunc={handleToggleLinkModifyModal}
        confirmFunc={UpdateUrlFunc}
      >
        <UrlModifyContents>
          <TitleUpdate>
            <p>원본</p>
            <div>
              <p>{originUrl}</p>
            </div>
          </TitleUpdate>
          <img src={UpdateUrl} alt={UpdateUrl} />
          <div>
            <p>변경</p>
            <textarea id='LinkDetailBox-LinkChange-textarea' placeholder='변경할 목적지 링크를 입력해주세요.' type='text' value={newOriginUrl} onChange={handleNewOriginUrl} />
          </div>
        </UrlModifyContents>
      </ContentModal>

      <ConfirmModal
        title={'링크 숨기기'}
        desc={'선택한 링크를 숨기시겠어요?'}
        desc2={'숨겨진 링크 탭에서 확인할 수 있으며,'}
        desc3={'언제든지 나의 링크로 옮길 수 있습니다.'}
        button1={'네, 숨길래요'}
        button2={'다시 생각해볼래요'}
        confirmFunc={hideLink}
        button1color={color.mainColor}
        button2color={color.darkgrayColor}
        toggle={toggleHideModal}
        toggleFunc={handleHideModal}
        urlId={urlId}
        isDelete={false}
        confirmText={'링크를 숨겼습니다.'}
      />
      <ConfirmModal
        title={'링크 삭제'}
        desc={'링크를 삭제를 하시겠어요?'}
        desc2={'삭제한 링크는 복구할 수 없습니다.'}
        button1={'삭제할래요'}
        button2={'다시 생각해볼래요'}
        confirmFunc={deleteLink}
        button1color={color.subRed}
        button2color={color.darkgrayColor}
        toggle={toggleDeleteModal}
        toggleFunc={handleDeleteModal}
        urlId={urlId}
        isDelete={true}
        confirmText={'링크를 삭제했습니다.'}
      />
      <ConfirmModal
        title={'링크 숨기기 해제'}
        desc={'링크 숨기기를 해제하시겠어요?'}
        desc2={'링크를 다시 ’나의 링크’탭에서 볼 수 있습니다.'}
        button1={'숨기기 해제할래요'}
        button2={'다시 생각해볼래요'}
        confirmFunc={toMyLink}
        button1color={color.mainColor}
        button2color={color.darkgrayColor}
        toggle={toggleToMyModal}
        toggleFunc={handleToMyModal}
        urlId={urlId}
        isDelete={true}
        confirmText={'링크를 숨기기 해제 했습니다.'}
      />

      <CompleteModal toggle={toggleConfirmModal} toggleFunc={handleConfirmModal} confirmText={confirmText} />
      <CompleteModal toggle={tagMaxErrortoggle} toggleFunc={closeMaxErrorModal} confirmText={maxErrorText} confirmFunc={closeMaxErrorModal} />
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <Overlay toggle={togglePopover} onClick={handlePopover} />
      <Overlay toggle={toggleLinkTagPopover} onClick={handleToggleLinkTagPopover} />
    </LinkDetailBoxWrapper>
  );
}

export default LinkDetailBox;
