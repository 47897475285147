import React from 'react';
import styled from 'styled-components';
import * as color from '../css/ColorVariables';
import SortiLogo from '../asset/img/sortiGreen.png';

const FooterWrapper = styled.div`
  background-color: ${color.whiteColor};
  padding: 15% 10% 5% 10%;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & > p {
    font-family: suitSemiBold;
    color: ${color.darkgrayColor};
  }
  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;
    @media screen and (max-width: 425px) {
      column-gap: 10px;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }
    & > p:first-child {
      text-decoration: underline;
      cursor: pointer;
    }
    & > p {
      font-family: suitSemiBold;
      color: ${color.darkgrayColor};
    }
  }
  & > p.footer-email {
    cursor: pointer;
    text-decoration: underline;
  }

  & > img {
    width: 130px;
    padding-top: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

function Footer(props) {
  const handleOpen = () => {
    window.open('/privacyPolicy');
  };

  const handleEmail = () => {
    window.location.href = 'mailto:sorti-service@sortielab.com';
  };

  return (
    <FooterWrapper id={props.id}>
      <p>(주) 솔티랩</p>
      <p>사업자 등록 번호 856-88-02113</p>
      <p>경기도 안산시 단원구 고잔2길 9, 406호 -A07(고잔동, 하이베라스)</p>
      <div>
        <p onClick={handleOpen}>개인정보 처리 방침</p>
        <p>SortieLab Corp. All rights reserved</p>
      </div>
      <p className={'footer-email'} onClick={handleEmail}>
        sorti-service@sortielab.com
      </p>
      <img src={SortiLogo} alt='' />
    </FooterWrapper>
  );
}

export default Footer;
