import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const ToastAnimation = keyframes`
  0% {
    opacity : 0;
  }
  70% {
    opacity : 1;
  }
  100% {
    opacity : 0;
  }
`;

const ToastBoxWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  animation: ${ToastAnimation} 1.5s 1 linear alternate;
  opacity: 0;
  z-index: 10000000;
  & > div {
    position: absolute;
    font-family: suitMedium;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #00ce7c;
    box-shadow: 0px 8px 15px rgba(208, 227, 220, 0.3);
    border-radius: 10px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    width: fit-content;
  }
`;

function ToastBox({ toggle, handleToast, text }) {
  useEffect(() => {
    let timer = setTimeout(() => {
      handleToast(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ToastBoxWrapper>
      <div>{text}</div>
    </ToastBoxWrapper>
  );
}

export default ToastBox;
