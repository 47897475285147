import React from 'react';
import styled from 'styled-components';

import SelectBox from '../Input/SelectBox';
import Tag from './Tag';
import FilterTagPopover from './FilterTagPopover';

const TagContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: relative;
`;

function TagContainer({ tagArray, handleTagArray, handleDeleteTagArray }) {
  const [togglePopover, setTogglePopover] = React.useState(false);

  const handleTogglePopover = () => {
    setTogglePopover(!togglePopover);
  };

  return (
    <TagContainerWrapper>
      <SelectBox onClick={handleTogglePopover} />
      {tagArray.map((item, key) => (
        <Tag key={key} text={item} onDelete={handleDeleteTagArray} />
      ))}
      <FilterTagPopover toggle={togglePopover} toggleFunc={handleTogglePopover} handleTagArray={handleTagArray} />
    </TagContainerWrapper>
  );
}

export default TagContainer;
