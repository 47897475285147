import React from 'react';
import styled, { keyframes } from 'styled-components';

import Graph from '../asset/img/graph.png';
import Graphbox from '../asset/img/graphbox.png';
import { isMobileFunc } from '../utils/utils';

const isMobile = isMobileFunc();

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    top: ${isMobile ? '42%' : '40%'};
    bottom: 50%;
    transform: translate(0, -50%);
    & > p {
      text-align: center;
      position: absolute;
      font-family: suitExBold;
      font-size: ${isMobile ? '24px' : '40px'};
      line-height: ${isMobile ? '32px' : '48px'};
    }
    & > img {
      margin-top: ${isMobile ? '100px' : '140px'};
      width: ${isMobile ? '100%' : '100%'};
      max-width: 1080px;
    }
  }
`;

const BoxAnimation = keyframes`
    0%{
        transform: translate(0 , 0);
    }
    50%{
        transform: translate(0 , 10px);
    }
    100%{
        transform: translate(0 , 0);
    }
`;

const Img = styled.img`
  position: absolute;
  top: ${isMobile ? '-50%' : '0%'};
  width: ${isMobile ? '60% !important' : '40% !important'};
  animation: ${BoxAnimation} 1.5s infinite linear;
  max-width: 426px !important;
`;

function LinkReportContainer() {
  return (
    <Wrapper>
      <div>
        <p>
          내 URL, <br />
          얼마나 클릭됐을까?
        </p>
        <img src={Graph} alt='' />
        <Img src={Graphbox} alt='' />
      </div>
    </Wrapper>
  );
}

export default LinkReportContainer;
