import React from 'react';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import * as color from '../../css/ColorVariable';
import { useNavigate } from 'react-router-dom';
import Logout from '../../asset/img/logout.png';
import Mypage from '../../asset/img/mypage.png';
import Question from '../../asset/img/question.png';

const cookies = new Cookie();

const PopoverWrapper = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 15px;
  padding: 15px;
  width: 200px;
  position: absolute;
  right: 50px;
  top: 70px;
  background-color: white;
  border: 1px solid ${color.mainColor};
  border-radius: 10px;
  z-index: 20;
  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    & > img {
      width: 20px;
    }
    & > p {
      font-family: suitMedium;
    }
  }
  & > div:last-child {
    & > p {
      color: ${color.subRed};
    }
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  border-radius: 100px;
  background-color: ${color.brightgrayColor};
`;

const Overlay = styled.div`
  position: fixed;
  display: ${(props) => (props.toggle ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
`;

function ProfilePopover({ toggle, toggleFunc }) {
  const navigate = useNavigate();

  const goToMypage = () => {
    navigate('/dashboard/mypage');
  };

  const customCenter = () => {};

  const logout = () => {
    cookies.remove('token', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('email', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('name', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('loginType', { sameSite: 'none', secure: true, path: '/' });
    navigate('/');
  };

  return (
    <>
      <PopoverWrapper toggle={toggle}>
        <div onClick={goToMypage}>
          <img src={Mypage} alt='' />
          <p>마이페이지</p>
        </div>
        <HorizontalLine />
        <div onClick={customCenter}>
          <img src={Question} alt='' />
          <p>고객센터</p>
        </div>
        <HorizontalLine />
        <div onClick={logout}>
          <img src={Logout} alt='' />
          <p>로그아웃</p>
        </div>
      </PopoverWrapper>
      <Overlay toggle={toggle} onClick={toggleFunc} />
    </>
  );
}

export default ProfilePopover;
