import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Magnifier from '../../asset/img/magnifier.png';

const InputWrapper = styled.input`
  width: ${(props) => props.width};
  border: 1px solid #d0e3dc;
  border-radius: 10px;
  height: 48px;
  padding: ${(props) => (!!props.paddingHorizontal ? `0 ${props.paddingHorizontal}px` : '0 20px')};
  background-image: url(${(props) => props.img});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: ${(props) => `calc(100% - ${!!props.paddingHorizontal ? props.paddingHorizontal : 20}px)`} center;
  transition: 0.3s;
  &::placeholder {
    font-family: suitRegular;
    font-size: 16px;
    color: #a0b1ab;
  }
  &:focus {
    border: 1px solid #00ce7c;
    outline: none;
  }
`;

const propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
};

const defaultProps = {
  /**
   * 해당 인풋의 placeholder을 나타냅니다.
   */
  placeholder: '입력해 주세요',
  width: '200px',
  img: Magnifier,
};

function Input({ placeholder, img, width, value, onChange, name, paddingHorizontal }) {
  return <InputWrapper paddingHorizontal={paddingHorizontal} name={name} value={value} onChange={onChange} placeholder={placeholder} img={img} width={width} />;
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
