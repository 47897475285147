export const DEV_API_URL = 'http://localhost:3000/';

export const DEPLOY_API_URL = 'https://sorti.me';

export const DEPLOY_LOGIN_API_URL = 'http://sorti.me:8081';

export const LOGIN_API_URL = 'https://sorti.me';

export const LINK_API_URL = 'https://sorti.me';

export const DATA_API_URL = 'https://sorti.me';
