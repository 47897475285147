import React from 'react'
import styled from "styled-components";

import Cancel from '../../asset/img/tagdelete.png';
import * as color from '../../css/ColorVariable';

const TagWrapper = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    column-gap: 10px; 
    box-sizing : border-box;
    background-color : white;
    padding : 5px 10px;
    border-radius : 10px;
    border : 1px solid ${color.brightgrayColor};
  cursor: pointer;
    & > p {
        font-family : suitRegular;
    }
`

function Tag({text , tagRegister}) {
    return (
        <TagWrapper onClick={tagRegister}>
            <p>{text}</p>
        </TagWrapper>
    )
}

export default Tag