import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  border: 0;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.1s linear;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) => (props.show ? 'scale(1)' : 'scale(0.9)')};
  width: 340px;
  & > p {
    font-family: suitMedium;
    font-weight: 500;
  }
  & > button {
    margin-top: 40px;
    width: 100%;
    font-family: suitBold;
    background-color: ${color.mainColor};
    color: white;
    border-radius: 10px;
    padding: 15px 0;
    cursor: pointer;
    border: none;
  }
`;
const BackWrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight};
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(34, 30, 31, 0.5);
`;
const CompleteModal = ({ toggle, toggleFunc, confirmText, confirmFunc }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(toggle);
  }, [toggle]);

  const pageReload = () => {
    if (!!confirmFunc) {
      confirmFunc();
    } else {
      navigate(0);
    }
  };

  return (
    <BackWrapper toggle={toggle} onClick={pageReload}>
      <ModalWrapper show={show} onClick={(e) => e.stopPropagation()}>
        <p>{confirmText}</p>
        <button onClick={pageReload}>확인</button>
      </ModalWrapper>
    </BackWrapper>
  );
};

export default CompleteModal;
