import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Magnifier from '../../asset/img/magnifier.png';

const IconButtonWrapper = styled.div`
    display : flex;
    align-items : center;
    padding : 5px 10px;
    border-radius : 10px;
    background-color : ${props => props.background};
    width : fit-content;
    column-gap : 5px;
    cursor: ${props => props.cursor};
    & > p {
        font-family : ${props => props.font};
        font-size : ${props => props.fontSize};
        color : ${props => props.color};
    }
    & > img {
        width : ${props => props.imgWidth};
    }
`

const propTypes = {
    text: PropTypes.string,
    background : PropTypes.string,
};
  
const defaultProps = {
  /**
  * 해당 인풋의 placeholder을 나타냅니다.
  */
  text: "입력해 주세요",
  background : '#F2F1FD',
  img : Magnifier,
  font : 'suitMedium',
  color : '#000000',
  imgWidth : '10px',
  cursor : '',
};

function IconButton({ text , img , background , font  , color , imgWidth , cursor , onClick}) {

  return (
    <IconButtonWrapper background={background} font={font} color={color} imgWidth={imgWidth} cursor={cursor} onClick={onClick}>
        <p>{text}</p>
        <img src={img} alt="" />
    </IconButtonWrapper>
  )
}

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton