import React from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariables';

import Circle from '../../asset/img/circle.png';
import { isMobileFunc } from '../../utils/utils';

const isMobile = isMobileFunc();

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  height: 30px;
  background: ${(props) => (props.toggle ? color.mainColor : color.brightgrayColor)};
  box-shadow: inset 0px 4.56px 6.08px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;
  & > img {
    position: absolute;
    width: 27%;
    height: auto;
    top: 50%;
    bottom: 50%;
    margin: 0 5px;
    transform: ${(props) => (props.toggle ? 'translate(220% , -50%)' : 'translate(0% , -50%)')};
    transition: 0.5s;
  }
`;

const OffText = styled.p`
  font-size: 18px;
  color: white;
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin: 0 10px;
  right: 0;
  transform: translate(0, -50%);
  height: 61%;
  opacity: ${(props) => (props.toggle ? '0' : '1')};
`;

const OnText = styled.p`
  font-size: 18px;
  color: white;
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin: 0 12px;
  left: 0;
  transform: translate(0, -50%);
  height: 61%;
  opacity: ${(props) => (props.toggle ? '1' : '0')};
`;

function ToggleButton(props) {
  return (
    <Wrapper toggle={props.toggle} onClick={props.handleToggle}>
      <img src={Circle} alt={''} />
      <OffText toggle={props.toggle}>OFF</OffText>
      <OnText toggle={props.toggle}>ON</OnText>
    </Wrapper>
  );
}

export default ToggleButton;
