import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

import GraphIcon from '../../asset/img/graphicon.png';

const GraphRangeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  background-color: white;
  border-radius: 10px;
  padding : 10px;
  width: fit-content;
  & > img {
    width: 10px;
  }
`

const InputBox = styled.label`
    border-radius : 8px;
    cursor: pointer;
    font-family: suitMedium;
    padding : 4px 10px;
`

const FilterCheckBox = styled.input`
    display : none;
    &:checked + ${InputBox}{
      background-color: ${color.subViolet};
      color : white;
    }
`

const FilterCheckBox2 = styled(FilterCheckBox)`
  &:checked + ${InputBox}{
    background-color: ${color.subBluish};
  }
`

const FilterCheckBox3 = styled(FilterCheckBox)`
  &:checked + ${InputBox}{
    background-color: ${color.mainColor};
  }
`

const FilterCheckBox4 = styled(FilterCheckBox)`
  &:checked + ${InputBox}{
    background-color: ${color.subSkyBlue};
  }
`

function GraphRangeInput({graphSortType , onChange}) {
    return (
        <GraphRangeInputWrapper>
            <img src={GraphIcon} alt="GraphIcon"/>
            <FilterCheckBox name='filter' checked={graphSortType === '일간'} id={'일간'} type='radio' onChange={onChange}/><InputBox htmlFor={'일간'}>일간</InputBox>
            <FilterCheckBox2 name='filter' checked={graphSortType === '주간'} id={'주간'} type='radio' onChange={onChange}/><InputBox htmlFor={'주간'}>주간</InputBox>
            <FilterCheckBox3 name='filter' checked={graphSortType === '월간'} id={'월간'} type='radio' onChange={onChange}/><InputBox htmlFor={'월간'}>월간</InputBox>
            <FilterCheckBox4 name='filter' checked={graphSortType === '연간'} id={'연간'} type='radio' onChange={onChange}/><InputBox htmlFor={'연간'}>연간</InputBox>
        </GraphRangeInputWrapper>
    );
}

export default GraphRangeInput;