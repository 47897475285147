import { useEffect } from 'react';

function KakaoShare() {
  useEffect(() => {
    const urlParameter = window.location.search;
    const params = new URLSearchParams(urlParameter);
    const url = params.get('url');
    const title = params.get('title');

    if (!window.Kakao) return;

    const kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      kakao.init('e78ff373ae1bbe34241103908d02677d');
    }

    kakao.Share.sendCustom({
      templateId: 86295,
      templateArgs: {
        url: url,
        description: title,
      },
    });
    window.close();
  }, []);

  return <div />;
}

export default KakaoShare;
