import React from "react";
import styled from "styled-components";
import * as color from "../css/ColorVariable";

import notFoundImg from "../asset/img/404.png";
import notFoundBackground from "../asset/img/404Background.png";
import SortiButton from "../components/SortiButton";
import { useNavigate } from "react-router-dom";
import useTitle from "../utils/useTitle";

const NotFoundPageWrapper = styled.div`
  position: relative;
  height: 100vh;
  padding-top: 150px;
  background-image: url(${notFoundBackground});
  background-size: cover;
  background-color: #fbf5f4;
`;

const NotFoundImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 800px;
  }
  & > div {
    text-align: center;
    & > p {
      font-size: 20px;
      font-family: suitMedium;
      line-height: 160%;
      & > span {
        font-family: suitBold;
      }
    }
  }
  & > button {
    width: fit-content;
  }
`;

function NotFoundPage(props) {
  const navigate = useNavigate();

  useTitle("SORTI | Not Found");

  const goToMain = () => {
    navigate("/");
  };

  return (
    <NotFoundPageWrapper styled={{ height: `300px` }}>
      <NotFoundImg>
        <img src={notFoundImg} alt="" />
        <div>
          <p>갈곳을 잃었습니다.</p>
          <p>
            <span>링크가 존재하지 않거나 잘못된 링크</span>인 것 같아요.
          </p>
        </div>
        <SortiButton
          text={"SORTI 메인으로 이동하기"}
          width={"fit-content"}
          onClick={goToMain}
        />
      </NotFoundImg>
    </NotFoundPageWrapper>
  );
}

export default NotFoundPage;
