import React from 'react';
import styled from 'styled-components';
import SortiButton from './SortiButton';

import { useNavigate } from 'react-router-dom';
import BackgroundMobile from '../asset/img/BackMobile.png';
import Background from '../asset/img/sortiStartBack.png';
import { isMobileFunc } from '../utils/utils';
import Cookies from 'universal-cookie';

const isMobile = isMobileFunc();

const StartSortiWrapper = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  background: url(${isMobile ? BackgroundMobile : Background});
  background-size: cover;
  /* width: 100%; */
  & > div {
    position: absolute;
    width: ${isMobile ? '80%' : '40%'};
    top: 45%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    & > p {
      font-family: suitExBold;
      padding-bottom: 30px;
      font-size: ${isMobile ? '24px' : '40px'};
      line-height: ${isMobile ? '30px' : '50px'};
      margin-bottom: 40px;
      line-height: ${isMobile ? '40px' : ''};
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100% !important;
  max-width: ${isMobile ? '393px' : '288px'};
  height: 60px !important;
  z-index: 100;
  padding: 0 28px;
  box-sizing: border-box;
  & > button {
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 12px;
    font-size: 18px;
    white-space: nowrap;
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 6 !important;
  width: 90%;
  bottom: 0 !important;
  height: ${isMobile ? '40% !important' : '33% !important'};
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

function StartSortiContainer() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const goToSignUp = () => {
    const flag = !!cookies.get('token') && !!cookies.get('name') && !!cookies.get('email') && !!cookies.get('loginType');

    if (!!flag) {
      navigate('/dashboard/mylink');
    } else {
      navigate('/login');
    }
  };

  return (
    <StartSortiWrapper>
      <div>
        <p>
          하나뿐인 나만의 URL,
          <br />
          SORTI와 함께 시작해보세요
        </p>
        <ButtonWrapper>
          <SortiButton onClick={goToSignUp} text={'SORTI 시작하기'} />
        </ButtonWrapper>
      </div>
    </StartSortiWrapper>
  );
}

export default StartSortiContainer;
