import React, { useEffect, useState } from 'react';
import axios from 'axios';

import styled from 'styled-components';
import Input from '../Input/Input';
import * as color from '../../css/ColorVariable';
import Calendar from '../../asset/img/calendar.png';
import RedX from '../../asset/img/RedX.png';
import Cookies from 'universal-cookie';
import { DATA_API_URL } from '../../Url';
import TokenExpiredModal from '../Modal/TokenExpiredModal';

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
`;

const TagSpan = styled.span`
  display: flex;
  box-sizing: border-box;
  border: 1px solid ${(props) => (props.toggle ? '#252525' : color.brightgrayColor)};
  background-color: ${(props) => (props.toggle ? '#252525' : 'white')};
  color: ${(props) => (props.toggle ? 'white' : '#252525')};
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-family: suitMedium;
  cursor: pointer;
`;

const SearchTagContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 276px;
  row-gap: 12px;
  & > div:first-child {
    width: 100%;
    & > input {
      width: 100%;
    }
  }
  & > div:nth-child(3) {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    row-gap: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${color.brightgrayColor};
    & > input {
      border: none;
      width: 100%;
      font-family: suitMedium;
      font-size: 16px;
      font-feature-settings: 'tnum';
      box-sizing: border-box;
      background-color: ${color.backgroundColor};
      height: 32px;
      padding: 4 12;
      border-radius: 8px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #a0b1ab;
      }
    }
    & > div {
      max-height: 200;
      overflow: scroll;
    }
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    & > span {
      font-size: 16px;
      line-height: 24px;
      font-family: suitSemiBold;
      color: ${color.darkgrayColor};
      text-decoration: underline;
      cursor: pointer;
    }
    & > img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;
const DateInputWrapper = styled.div`
  height: 48px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: #a0b1ab;
  border: 1px solid ${color.brightgrayColor};
  overflow: hidden;
  padding: 12px 12px;
  box-sizing: border-box;
  & > span {
    width: 10%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div {
    position: relative;
    display: flex;
    width: 45%;
    align-items: center;
    & > img {
      width: 16px;
      height: 16px;
      z-index: 1;
    }
    & > input {
      border: none;
      width: 100%;
      font-family: suitMedium;
      font-size: 16px;
      font-feature-settings: 'tnum';
      box-sizing: border-box;
      z-index: 3;
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #a0b1ab;
      }
    }
    & > input[type='date'] {
      opacity: 0;
      position: absolute;
      z-index: 2;
    }
  }
`;

const cookies = new Cookies();

function SearchTagContainer({ searchData, setSearchData, tagArray, setTagArray, searchStart, searchEnd, handleStart, handleEnd }) {
  const [myTagList, setMyTagList] = useState([]);
  const [searchTagData, setSearchTagData] = useState('');
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };

  const handleTagArray = (tagName) => {
    if (tagArray.includes(tagName)) {
      setTagArray(tagArray.filter((tagArray) => tagArray !== tagName));
    } else {
      setTagArray([...tagArray, tagName]);
    }
  };

  const handleValue = (setFunc, e) => {
    let text = e.target.value;
    text = text.replaceAll(/[^0-9\-]/g, '');
    if (text.length > 10) return;
    if (text.length > 4) {
      if (text[4] !== '-') {
        text = text.slice(0, 4) + '-' + text.slice(4);
      }
    }
    if (text.length > 7) {
      if (text[7] !== '-') {
        text = text.slice(0, 7) + '-' + text.slice(7);
      }
    }
    setFunc({ target: { value: text } });
  };

  const resetSearch = () => {
    handleStart({ target: { value: '' } });
    handleEnd({ target: { value: '' } });
    setSearchData('');
    setSearchTagData('');
    setTagArray([]);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/tags',
      params: {
        token: cookies.get('token'),
        searchData: searchTagData,
        tagNameList: [],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setMyTagList(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchTagData]);

  return (
    <SearchTagContainerWrapper>
      <div>
        <Input paddingHorizontal={12} placeholder={'나의 링크 검색'} value={searchData} onChange={(e) => setSearchData(e.target.value)} />
      </div>
      <div>
        <DateInputWrapper>
          <div>
            <input placeholder='시작 날짜' value={searchStart} onChange={handleValue.bind(this, handleStart)} />
            <img src={Calendar} alt='start date' />
            <input type='date' value={searchStart} onChange={handleStart} />
          </div>
          <span>~</span>
          <div>
            <input placeholder='종료 날짜' value={searchEnd} onChange={handleValue.bind(this, handleEnd)} />
            <img src={Calendar} alt='end date' />
            <input type='date' value={searchEnd} onChange={handleEnd} />
          </div>
        </DateInputWrapper>
      </div>
      <div>
        <input placeholder='태그 검색' value={searchTagData} onChange={(e) => setSearchTagData(e.target.value)} />
        <TagWrapper>
          {myTagList.map((t, i) => {
            return (
              <TagSpan toggle={tagArray.includes(t)} onClick={handleTagArray.bind(this, t)}>
                {t}
              </TagSpan>
            );
          })}
        </TagWrapper>
      </div>
      <div>
        <span onClick={resetSearch}>검색 초기화</span>
        <img onClick={resetSearch} src={RedX} alt='reset' />
      </div>
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
    </SearchTagContainerWrapper>
  );
}

export default SearchTagContainer;
