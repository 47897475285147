import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';
import Mouse from "../../asset/img/mouse.png";
import GreenArrow from "../../asset/img/greenarrow.png";
import LinkHistoryBoxList from "./LinkHistoryBoxList";
import DownArrow from "../../asset/img/greendownarrow.png";

const LinkHistoryBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: suitMedium;
  font-feature-settings: 'tnum';
  background-color: white;
  border-radius: 10px;
  padding : 15px 21px;
  & > div:first-child{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    & > div:first-child{
      display: flex;
      column-gap: 12px;
      align-items: center;
      color : ${color.mainColor};
      & > div {
        display: flex;
        column-gap: 5px;
        align-items: center;
        & > p {
          color : black;
        }
        & > img{
          width: 9px;
        }
      }
    }
    & > div:last-child{
      display: flex;
      align-items: center;
      column-gap: 12px;
      & > img {
        width : 10px;
        transform: ${props => props.toggle ? 'rotate(270deg)' : 'rotate(90deg)'} !important;
        transition: all .5s;
      }
    }
  }
`

const TableWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 15px;
  overflow: hidden;
  margin-top: ${props => props.toggle ? '30px' : '0px'};
  visibility: ${props => props.toggle ? 'visible' : 'hidden'};
  max-height: ${props => props.toggle ? '500px' : '0px'};
  transition: all 1s;
  & > div:first-child{
    display: flex;
    justify-content: space-between;
    padding : 5px 15px;
    & > p:first-child{
      color : ${color.mainColor};
      width : 30px;
    }
    & > p:nth-child(2){
      width: 60px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > img {
        width: 14px;
        cursor : pointer;
      }
    }
    & > p:nth-child(3){
      width: 30px;
    }
    & > p:nth-child(4){
      width: 150px;
    }
    & > p:nth-child(5){
      width: 80px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > img {
        width: 14px;
        cursor : pointer;
      }
    }
    & > p:nth-child(6){
      width: 80px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > img {
        width: 14px;
        cursor : pointer;
      }
    }
    & > p:nth-child(7){
      width: 50px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > img {
        width: 14px;
        cursor : pointer;
      }
    }
    & > p:nth-child(8){
      width: 160px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > img {
        width: 14px;
        cursor : pointer;
      }
    }
  }
  & > div:nth-child(2){
    display: flex;
    position: relative;
    flex-direction: column;
    row-gap: 15px;
    max-height: 300px;
    overflow: scroll;
    padding-bottom: 10px;
  }
`

const Overlay = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 100%);
    position : absolute;
    width : 100%;
    height : 20px;
    bottom : 0;
`
const Img = styled.img`
  transform : ${props => props.active ? 'rotate(180deg)' : ''};
  transition: all .5s;
`

function LinkHistoryBox({history , historyDate , total , historySortType , setHistorySortType}) {

    const [toggleTable , setToggleTable] = React.useState(false);

    const handleToggleTable = () => {
        setToggleTable(!toggleTable);
    }

    const handleDate = () => {
        if(historySortType === '날짜 역순'){
            setHistorySortType('날짜');
        }else{
            setHistorySortType('날짜 역순');
        }
    }

    const handleDevice = () => {
        if(historySortType === '디바이스 역순'){
            setHistorySortType('디바이스');
        }else{
            setHistorySortType('디바이스 역순');
        }
    }

    const handleBrowser = () => {
        if(historySortType === '브라우저 역순'){
            setHistorySortType('브라우저');
        }else{
            setHistorySortType('브라우저 역순');
        }
    }

    const handleOs = () => {
        if(historySortType === 'OS 역순'){
            setHistorySortType('OS');
        }else{
            setHistorySortType('OS 역순');
        }
    }

    const handleCountry = () => {
        if(historySortType === '국가 역순'){
            setHistorySortType('국가');
        }else{
            setHistorySortType('국가 역순');
        }
    }
    return (
        <LinkHistoryBoxWrapper toggle={toggleTable}>
            <div onClick={handleToggleTable}>
                <div>
                    <p>{historyDate === undefined ? '' : historyDate.substring(2 , 10).replaceAll('-' , '. ')}</p>
                    <p>{historyDate === undefined ? '' : historyDate.substring(11 , 16)}</p>
                    <div>
                        <p>{total}</p>
                        <img src={Mouse} alt={'Mouse'}/>
                    </div>
                </div>
                <div>
                    <p>내역</p>
                    <img src={GreenArrow} alt=""/>
                </div>
            </div>
            <TableWrapper toggle={toggleTable}>
                <div>
                    <p>No.</p>
                    <p>
                        날짜
                        <Img src={DownArrow} active={historySortType !== '날짜 역순'} alt="DownArrow" onClick={handleDate}/>
                    </p>
                    <p>시간</p>
                    <p>IP 주소</p>
                    <p>디바이스<Img src={DownArrow} active={historySortType !== '디바이스 역순'} alt="DownArrow" onClick={handleDevice}/></p>
                    <p>브라우저<Img src={DownArrow} active={historySortType !== '브라우저 역순'} alt="DownArrow" onClick={handleBrowser}/></p>
                    <p>OS<Img src={DownArrow} active={historySortType !== 'OS 역순'} alt="DownArrow" onClick={handleOs}/></p>
                    <p>국가<Img src={DownArrow} active={historySortType !== '국가 역순'} alt="DownArrow" onClick={handleCountry}/></p>
                </div>
                <div>
                    {history === undefined ? '' :
                        history.map((item)=>(
                            <LinkHistoryBoxList
                                no={item.rowNum}
                                clientIp={item.clientIp}
                                device={item.device}
                                browser={item.browser}
                                country={item.country}
                                date={item.date}
                                osInfo={item.osInfo}
                                hour={item.hour}
                            />
                        ))
                    }
                </div>
                <Overlay/>
            </TableWrapper>
        </LinkHistoryBoxWrapper>
    );
}

export default LinkHistoryBox;