import React, {useEffect} from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';
import VioletMouse from "../../asset/img/violetmouse.png";
import BluishMouse from "../../asset/img/bluishmouse.png";
import Mouse from "../../asset/img/mouse.png";
import SkyMouse from "../../asset/img/skymouse.png";

const LinkDetailBoxWrapper = styled.div`
    display : flex;
    flex-direction : column;
    row-gap : 10px;
    background-color: white;
    padding : 70px;
    border-radius: 10px;
    text-align: center;
  & > h1 {
    font-family: suitBold;
    font-size: 24px;
    color : ${props => props.colors};
  }
  & > p {
    font-family: suitRegular;
  }
`

function EmptyGraphBox({graphSortType}) {

    const [colors , setColors] = React.useState(color.subViolet);

    const fontColor = (graphSortType) => {
        switch (graphSortType){
            case '일간' :
                setColors(color.subViolet);
                break;
            case '주간' :
                setColors(color.subBluish);
                break;
            case '월간' :
                setColors(color.mainColor);
                break;
            case '연간' :
                setColors(color.subSkyBlue);
                break;
            default :
                break;
        }
    }

    useEffect(()=>{
        fontColor(graphSortType);
    },[graphSortType])

    return (
        <LinkDetailBoxWrapper colors={colors}>
            <h1>해당 기간의 클릭이 없어요</h1>
            <p>사람들에게 여러분의 링크를 알려주세요!</p>
        </LinkDetailBoxWrapper>
    );
}

export default EmptyGraphBox;