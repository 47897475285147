import React from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import Magnifier from '../../asset/img/magnifier.png';
import Calendar from '../../asset/img/calendar.png';
import DownArrow from '../../asset/img/greendownarrow.png';
import FilterPoporver from './FilterPoporver';

const SearchControlBoxWrapper = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 25px;
    & > div:first-child {
      font-family: suitMedium;
      color: ${color.darkgrayColor};
      /* padding-left: 15px; */
      & > span {
        font-feature-settings: 'tnum';
        color: #000000;
      }
    }
    & > input,
    & > div > input {
      border: none;
      border-radius: 10px;
      background-image: url(${Magnifier});
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      padding: 6px 10px 7px 10px;
    }
    & > div:last-child {
      display: flex;
      align-items: center;
      column-gap: 5px;
      & > input {
        background-image: url(${Calendar});
        background-size: 14px;
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
        }
      }
    }
  }
  & > div:nth-child(2) {
    position: relative;
    font-family: suitRegular;
    border: none;
    border-radius: 10px;
    background-image: url(${DownArrow});
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 6px 34px 7px 10px;
    background-color: white;
    cursor: pointer;
  }
`;

function SearchControlBox({ linkCount, sortType, handleSortType }) {
  const [togglePopover, setTogglePopover] = React.useState(false);

  const handleTogglePopover = () => {
    setTogglePopover(!togglePopover);
  };

  return (
    <SearchControlBoxWrapper>
      <div>
        <div>
          총 <span>{linkCount}</span>개의 링크
        </div>
        {/* <input type="text" placeholder={'나의 링크 검색'} onChange={handleSearchDataTitle}/>
                <div>
                    <input type="date" onChange={handleStart} min="1997-03-13" max="9999-12-31"/>~
                    <input type="date" onChange={handleEnd} min={searchStart} max="9999-12-31"/>
                </div> */}
      </div>
      <div onClick={handleTogglePopover}>
        {sortType}
        <FilterPoporver toggle={togglePopover} toggleFunc={handleTogglePopover} handleSortType={handleSortType} />
      </div>
    </SearchControlBoxWrapper>
  );
}

export default SearchControlBox;
