import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

const LinkDetailBoxWrapper = styled.div`
    display : flex;
    flex-direction : column;
    row-gap : 10px;
    background-color: white;
    padding : 70px;
    border-radius: 10px;
    text-align: center;
  & > h1 {
    font-family: suitBold;
    font-size: 24px;
    color : ${color.mainColor};
  }
  & > p {
    font-family: suitRegular;
  }
`

function EmptyLinkBox(props) {
    return (
        <LinkDetailBoxWrapper>
            <h1>아직 생성된 링크가 없어요</h1>
            <p>첫 링크를 만들어보세요!</p>
        </LinkDetailBoxWrapper>
    );
}

export default EmptyLinkBox;