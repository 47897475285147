import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';

import Cancel from '../../asset/img/cancel.png';
import Mysorti from '../../asset/img/mysorti.png';
import SortiButton from '../../components/SortiButton';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  border: 0;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.1s linear;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) => (props.show ? 'scale(1)' : 'scale(0.9)')};
  min-width: 524px;
  & > div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    & > h1 {
      line-height: 40px;
      font-family: suitExBold;
      font-size: 24px;
      font-weight: 500;
    }
    & > img {
      width: 24px;
      cursor: pointer;
    }
  }
  & > p {
    font-family: suitRegular;
    line-height: 24px;
  }
  & > div:nth-child(5) {
    padding: 5px 0;
    text-align: center;
    border-radius: 10px;
    background-color: ${color.backgroundColor};
    font-family: suitMedium;
    background-image: url(${Mysorti});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px center;
    margin: 15px 0;
    line-height: 24px;
  }
  & > button {
    box-shadow: none;
    line-height: 24px;
  }

  & > div.contentModal-children {
    margin: 60px 0;
  }
`;
const BackWrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight};
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(34, 30, 31, 0.5);
`;
const ContentModal = ({ title, desc, desc2, url, urlId, buttonText, children, toggle, toggleFunc, confirmFunc, width }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(toggle);
  }, [toggle]);
  return (
    <BackWrapper toggle={toggle} onClick={toggleFunc}>
      <ModalWrapper show={show} onClick={(e) => e.stopPropagation()}>
        <div>
          <h1>{title}</h1>
          <img src={Cancel} alt='' onClick={toggleFunc} />
        </div>
        <p>{desc}</p>
        <p>{desc2}</p>
        <div className='contentModal-children'>{children}</div>
        <div>{url}</div>
        <SortiButton text={buttonText} onClick={confirmFunc}></SortiButton>
      </ModalWrapper>
    </BackWrapper>
  );
};

export default ContentModal;
