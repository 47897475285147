import React, {useEffect} from "react";
import Modal from "react-modal";
import Cookie from 'universal-cookie';
import styled from "styled-components";

import CheckBox from '../../asset/img/checkBox.png';
import CheckBoxActive from '../../asset/img/checkBoxActive.png';

const cookies = new Cookie();

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  & > h1 {
    font-family: suitMedium;
    font-weight: 500;
  }
  & > div {
    text-align: center;
    line-height: 120%;
    & > div {
      display: flex;
      column-gap: 8px;
      align-items: center;
      padding-top: 10px;
      & > p {
        font-family: suitMedium;
        cursor: pointer;
      }
      & > img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
  & > div > p {
    font-family: suitRegular;
  }
  & > button {
    width: 100%;
    font-family: suitBold;
    border-radius: 10px;
    padding : 15px 0;
    cursor : pointer;
  }
  & > button:nth-last-child(2){
    background-color: ${props => props.toggle ? props.button1color : ''};
    cursor : ${props => props.toggle ? 'pointer' : 'auto'} !important;
    color: white;
    border : none;
  }
  & > button:last-child{
    color: ${props => props.button2color};
    border : 1px solid ${props => props.button2color};
    background-color: white;
  }
`

const DeleteModal = ({ title , desc , desc2 , toggle , toggleFunc , button1 , button2 , button1color , button2color , confirmFunc , children}) => {

    const [toggleCheckBox , setToggleCheckBox] = React.useState(false);

    const handleToggleCheckBox = () => {
        setToggleCheckBox(!toggleCheckBox);
    }

    return(
        <Modal isOpen={toggle} onRequestClose={toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)',
                       zIndex : 9999999,
                   },
                   content : {
                       height : 'fit-content',
                       width : 'fit-content',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       borderRadius : '10px',
                       border : 'none',
                       minWidth : '300px'
                   }
               }}
        >
            <ModalWrapper button1color={button1color} button2color={button2color} toggle={toggleCheckBox}>
                <h1>{title}</h1>
                <div>
                    <p>{desc}</p>
                    <p>{desc2}</p>
                    <div>
                        {
                            toggleCheckBox ?
                                <img src={CheckBoxActive} alt="CheckBoxActive" onClick={handleToggleCheckBox}/>
                                :
                                <img src={CheckBox} alt="CheckBox" onClick={handleToggleCheckBox}/>
                        }
                        <p onClick={handleToggleCheckBox}>유의사항을 확인했고 탈퇴하겠습니다</p>
                    </div>
                </div>
                {children}
                <button onClick={toggleCheckBox ? confirmFunc : ''}>{button1}</button>
                <button onClick={toggleFunc}>{button2}</button>
            </ModalWrapper>

        </Modal>
    )
}

export default DeleteModal;