import React from 'react'
import styled from 'styled-components';
import PropTypes from "prop-types";

const ButtonWrapper = styled.button`
    color : #F1F7F4;
    font-family : suitBold;
    cursor: pointer;
    border : none;
    font-size : 16px;
    width : ${props => props.width};
    border-radius : 10px;
    background-color : ${props => props.hover ? '#07EB90' : '#00CE7C'};
    transform : ${props => props.hover ? 'scale(0.95)' : ''};
    transition : 0.3s;
    padding : 15px 18px;
    box-shadow: 4px 4px 25px rgba(0, 206, 124, 0.4);
`

const propTypes = {
    text: PropTypes.string,
};
  
const defaultProps = {
    /**
    * 해당 인풋의 placeholder을 나타냅니다.
    */
    text: "버튼",
  };

function Button({onClick , text , width}) {

    const [onHover , setOnHover] = React.useState(false);

    const handleOnHover = () => {
        setOnHover(!onHover);
    }

  return (
    <ButtonWrapper width={width} onClick={onClick} hover={onHover} onMouseEnter={() => handleOnHover()} onMouseOut={() => handleOnHover()}>
        {text}
    </ButtonWrapper>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps;

export default Button