import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { DATA_API_URL } from '../Url';
import * as color from '../css/ColorVariable';

import LinkDetailBox from '../components/Box/LinkDetailBox';
import Mouse from '../asset/img/mouse.png';
import VioletMouse from '../asset/img/violetmouse.png';
import BluishMouse from '../asset/img/bluishmouse.png';
import SkyMouse from '../asset/img/skymouse.png';
import GoBack from '../asset/img/goBack.png';
import GreenArrow from '../asset/img/greenarrow.png';
import GrayArrow from '../asset/img/grayArrow.png';
import Calendar from '../asset/img/graphcalendar.png';
import { Bar, getDatasetAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useNavigate, useParams } from 'react-router-dom';
import GraphRangeInput from '../components/Input/GraphRangeInput';
import EmptyGraphBox from '../components/Box/EmptyGraphBox';
import InformationList from '../components/Box/InformationList';
import InformationBox from '../components/Box/InformationBox';
import LinkHistoryBox from '../components/Box/LinkHistoryBox';
import TokenExpiredModal from '../components/Modal/TokenExpiredModal';
import useTitle from '../utils/useTitle';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const cookies = new Cookie();

const DashboardLinkDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 4% 2% 4% 2%;
  & > img {
    width: 14px;
    cursor: pointer;
    padding: 0 20px 20px 0;
  }
  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
`;

const GraphBoxWrapper = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    & > div:first-child {
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > p {
        font-family: suitMedium;
        color: black;

        font-feature-settings: 'tnum';
      }
      & > img:first-child {
        width: 9px;
        cursor: pointer;
      }
      & > img:nth-child(2) {
        width: 14px;
      }
      & > img:last-child {
        width: 9px;
        cursor: pointer;
        transform: rotate(180deg);
      }

      & > input {
        border: none;
        width: 123px;
        background-repeat: no-repeat;
        background-position: calc(100%) center;
        background-image: url(${Calendar});
        background-size: 14px;
        font-family: suitMedium;
        font-size: 16px;
        font-feature-settings: 'tnum';
        font-feature-settings: 'tnum';
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          cursor: pointer;
        }
        &:focus {
          outline: none;
        }
      }
    }
    & > div:last-child {
      display: flex;
      align-items: center;
      column-gap: 8px;
      & > p {
        font-family: suitMedium;
        font-feature-settings: 'tnum';
      }
      & > img {
        width: 10px;
      }
    }
  }
  & > div:last-child {
    height: 100%;
    & > canvas {
      min-width: 1150px !important;
      height: 300px !important;
    }
  }
`;

const HistoryWrapper = styled.div``;

const InformationWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 30px;
  & > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 30px;
    & > div {
      display: flex;
      width: calc(100% - 42px);
      flex-direction: column;
      background-color: white;
      padding: 15px 21px;
      border-radius: 10px;
      & > div:first-child {
        display: flex;
        justify-content: space-between;
        & > p {
          font-family: suitBold;
          color: ${color.mainColor};
        }
        & > img {
          width: 10px;
          transform: rotate(270deg);
        }
      }
      & > div:last-child {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin: 0 !important;
      }
    }
  }
`;

function DashboardLinkDetail({}) {
  const chartRef = useRef();

  useTitle('SORTI | 링크 상세');

  const { id } = useParams();

  let today = new Date();

  const navigate = useNavigate();

  const [myLinkData, setMyLinkData] = React.useState({});
  const [myLinks, setMyLinks] = React.useState('');
  const [graphSortType, setGraphSortType] = React.useState('일간');
  const [mouse, setMouse] = React.useState(VioletMouse);
  const [year, setYear] = React.useState(2022);
  const [searchDate, setSearchDate] = React.useState(
    `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`
  );
  const [graphClickDate, setGraphClickDate] = React.useState('');

  const [browser, setBrowser] = React.useState([]);
  const [os, setOs] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [device, setDevice] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [historySortType, setHistorySortType] = React.useState('날짜 역순');
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  let date = new Date(searchDate.replaceAll('-', '/'));
  let newDate = new Date(date.setDate(date.getDate() - 6));
  newDate = newDate.toISOString().substring(0, 10);

  let label = myLinkData.dayLinkClickData === undefined ? '' : myLinkData.dayLinkClickData.map((item) => item.dateTime);

  const [searchStart, setSearchStart] = React.useState(newDate);

  const handleSearchDate = (e) => {
    setSearchDate(e.target.value);
  };

  useEffect(() => {
    setSearchStart(newDate);
  }, [searchDate]);

  const handleMouseColor = (sort) => {
    switch (sort) {
      case '일간':
        setMouse(VioletMouse);
        setGraphClickDate('');
        setSearchDate(
          `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${
            today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
          }`
        );
        break;
      case '주간':
        setMouse(BluishMouse);
        setGraphClickDate('');
        setSearchDate(
          `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${
            today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
          }`
        );
        break;
      case '월간':
        setMouse(Mouse);
        setGraphClickDate('');
        setSearchDate(`${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}`);
        break;
      case '연간':
        setMouse(SkyMouse);
        setGraphClickDate('');
        setSearchDate(today.getFullYear().toString());
        setYear(today.getFullYear());
        break;
      default:
        break;
    }
  };

  const handleGraphSortType = (e) => {
    setGraphSortType(e.target.id);
    handleMouseColor(e.target.id);
  };

  const goBack = () => {
    navigate(-1);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const data = {
    labels: myLinkData.dayLinkClickData === undefined ? '' : myLinkData.dayLinkClickData.map((item) => item.dateTime),
    datasets: [
      {
        label: '',
        data: myLinkData.dayLinkClickData === undefined ? '' : myLinkData.dayLinkClickData.map((item) => item.dayLinkClickCount),
        backgroundColor: [graphSortType === '일간' ? color.subViolet : graphSortType === '주간' ? color.subBluish : graphSortType === '월간' ? color.mainColor : color.subSkyBlue],

        borderRadius: 10,
        maxBarThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    type: 'bar',
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgb(255 ,255 ,255)',
        titleFont: {
          family: 'suitMedium',
          weight: 500,
        },
        bodyFont: {
          family: 'suitMedium',
        },
        titleColor: 'rgb(0 ,0 ,0)',
        bodyColor: 'rgb(0 ,0 ,0)',
        borderColor: graphSortType === '일간' ? color.subViolet : graphSortType === '주간' ? color.subBluish : graphSortType === '월간' ? color.mainColor : color.subSkyBlue,
        borderWidth: 1,
        displayColors: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          offset: false,
        },
        ticks: {
          callback: function (val, index) {
            if (graphSortType === '일간') {
              if (index === 23) {
                return this.getLabelForValue('23:00');
              } else {
                return index % 3 === 0 ? this.getLabelForValue(val) : '';
              }
            } else if (graphSortType === '주간') {
              return this.getLabelForValue(val).substring(5, 10).replace('-', '.');
            } else if (graphSortType === '월간') {
              return index % 5 === 0 ? this.getLabelForValue(val).substring(5, 10).replace('-', '.') : '';
            } else if (graphSortType === '연간') {
              return Number(this.getLabelForValue(val).substring(5, 7)) + '월';
            }
          },
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor],
        },
      },
      y: {
        min: 0,

        max: Math.max(...(myLinkData.dayLinkClickData === undefined ? '' : myLinkData.dayLinkClickData.map((item) => item.dayLinkClickCount))),
        ticks: {
          padding: 10,
          stepSize: Math.round(Math.max(...(myLinkData.dayLinkClickData === undefined ? '' : myLinkData.dayLinkClickData.map((item) => item.dayLinkClickCount))) / 2),
          font: {
            family: 'suitMedium',
          },
          color: [color.darkgrayColor],
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/link/detail',
      params: {
        token: cookies.get('token'),
        urlId: id.replace(':', ''),
        searchDate: graphSortType === '주간' ? searchStart : graphSortType === '연간' ? year : searchDate,
        historySortType: historySortType,
        graphSortType: graphSortType,
        graphClickDate: graphClickDate,
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        console.log(response);

        if (response.data.result['code'] === '200') {
          setMyLinks(response.data.data.linkData);
          setMyLinkData(response.data.data);
          setDevice(response.data.data.historyAnalysisData['device']);
          setOs(response.data.data.historyAnalysisData['os']);
          setBrowser(response.data.data.historyAnalysisData['browser']);
          setCountry(response.data.data.historyAnalysisData['country']);
          setHistoryData(response.data.data.linkHistoryDatas);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [graphSortType, searchDate, year, searchStart, graphClickDate, historySortType]);

  const graphSearch = () => {
    switch (graphSortType) {
      case '일간':
        return (
          <div>
            <input type='date' value={searchDate} onChange={handleSearchDate} min='1997-03-13' max='9999-12-31' />
          </div>
        );
      case '주간':
        return (
          <div>
            <p>{searchStart.replaceAll('-', '. ')}</p>
            <p>~</p>
            <input type='date' value={searchDate} onChange={handleSearchDate} min='1997-03-13' max='9999-12-31' />
          </div>
        );
      case '월간':
        return (
          <div>
            <input type='month' value={searchDate} onChange={handleSearchDate} min='1997-03-13' max='9999-12-31' />
          </div>
        );
      case '연간':
        return (
          <div>
            <img src={GreenArrow} alt='' onClick={decreaseYear} />
            <img src={Calendar} alt='Calendar' />
            <p>{year}</p>
            {today.getFullYear() === year ? <img src={GrayArrow} style={{ transform: 'rotate(360deg)' }} alt='' /> : <img src={GreenArrow} alt='' onClick={increaseYear} />}
          </div>
        );
      default:
        break;
    }
  };

  const decreaseYear = () => {
    setYear(year - 1);
  };

  const increaseYear = () => {
    setYear(year + 1);
  };

  const chartClick = (e) => {
    setGraphClickDate(label[getElementsAtEvent(chartRef.current, e)[0].index]);
  };

  return (
    <DashboardLinkDetailWrapper>
      <img src={GoBack} alt='GoBack' onClick={goBack} />
      {myLinks === '' ? (
        ''
      ) : (
        <LinkDetailBox
          urlId={myLinks.urlId}
          originUrl={myLinks.originUrl}
          customUrl={myLinks.customUrl}
          favoriteStatus={myLinks.favoriteStatus}
          insertDate={myLinks.insertDate}
          clickCount={myLinks.clickCount}
          tag={myLinks.tag}
          urlTitle={myLinks.urlTitle}
          usId={myLinks.usId}
        />
      )}
      <div>
        <GraphRangeInput graphSortType={graphSortType} onChange={handleGraphSortType} />
      </div>
      <GraphBoxWrapper>
        <div>
          {graphSearch()}
          <div>
            <p>{myLinkData.totalLinkClickCount === undefined ? '' : numberWithCommas(myLinkData.totalLinkClickCount)}</p>
            <img src={mouse} alt='' />
          </div>
        </div>
        <div>
          {myLinkData.totalLinkClickCount === 0 ? <EmptyGraphBox graphSortType={graphSortType} /> : <Bar data={data} options={options} ref={chartRef} onClick={chartClick} />}
        </div>
      </GraphBoxWrapper>
      <LinkHistoryBox
        history={historyData}
        historyDate={myLinkData.historyDate}
        total={myLinkData.historyCount}
        historySortType={historySortType}
        setHistorySortType={setHistorySortType}
      />
      <InformationWrapper>
        <div>
          <InformationBox title={'OS'} arr={os} />
          <InformationBox title={'디바이스'} arr={device} />
        </div>
        <div>
          <InformationBox title={'브라우저'} arr={browser} />
          <InformationBox title={'국가'} arr={country} />
        </div>
      </InformationWrapper>
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
    </DashboardLinkDetailWrapper>
  );
}

export default DashboardLinkDetail;
