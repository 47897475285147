import { useEffect } from "react";
import axios from "axios";
import Cookie from "universal-cookie";
import { LOGIN_API_URL } from "../Url";

const cookies = new Cookie();

function Test() {
  const url = new URLSearchParams(window.location.search);
  const token = url.get("token");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios({
      method: "get",
      url: LOGIN_API_URL + "/sorti/api/user/token",
      params: {
        token: token,
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result["code"] === "200") {
          cookies.set("email", response.data.data.email, {
            sameSite: "none",
            secure: true,
          });
          cookies.set("name", response.data.data.name, {
            sameSite: "none",
            secure: true,
          });
          cookies.set("token", response.data.data.token, {
            sameSite: "none",
            secure: true,
          });
          cookies.set("loginType", response.data.data.loginType, {
            sameSite: "none",
            secure: true,
          });
          window.location.href = "/dashboard/mylink";
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
}

export default Test;
