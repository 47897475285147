import React from 'react';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

const InformationListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: suitMedium;
  background-color: ${color.whiteColor};
  padding : 5px;
  border-radius: 10px;
  & > div {
    width: 30%;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  & > p:nth-child(2) {
    width: 30%;
  }
`

const Round = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  border-radius: 30px;
`

function InformationList({index , dataName , dataCount , dataPercent}) {

    const colors = [color.mainColor , '#F4E661' , '#8AFFA4' , '#65F2D9' , '#65C8F2' , '#756BF1' , '#B287F8'];

    return (
        <InformationListWrapper>
            <div>
                <Round color={colors[index]}/>
                <p>{dataName === 'PC' ? '🖥' : dataName === 'Mobile' ? '📱' : ''} {dataName}</p>
            </div>
            <p>{dataCount}</p>
            <p>{dataPercent}%</p>
        </InformationListWrapper>
    );
}

export default InformationList;