import React, {useEffect} from "react";
import Modal from "react-modal";
import axios from 'axios';
import Cookie from 'universal-cookie';
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

import Cancel from '../../asset/img/cancel.png';
import Link from '../../asset/img/link.png';
import SortiButton from '../../components/SortiButton';
import {DATA_API_URL} from "../../Url";

const cookies = new Cookie();

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  text-align: center;
  & > div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    & > h1 {
      font-family: suitMedium;
      font-size: 16px;
      font-weight: 500;
    }
  }
  & > div {
    & > p {
      font-family: suitRegular;
      line-height: 120%;
    }
  }
  & > button {
    box-shadow: none;
  }
  & > p {
    font-family: suitMedium;
    color : ${color.darkgrayColor};
    text-decoration: underline;
    cursor: pointer;
  }
`

const MySortiModal = ({toggle , toggleFunc}) => {

    const goToMyPage = () => {
        window.location.href = '/dashboard/mypage';
    }

    return(
        <Modal isOpen={toggle} onRequestClose={toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)',
                       zIndex : 9999999,
                   },
                   content : {
                       height : 'fit-content',
                       width : '350px',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       borderRadius : '10px',
                       border : 'none',
                       minWidth : '400px'
                   }
               }}
        >
            <ModalWrapper>
                <div>
                    <h1>앗!</h1>
                </div>
                <div>
                    <p>아직 MY SORTI를 정하지 않으셨어요. </p>
                    <p>MY SORTI를 통해 나만의 URL을 만들 수 있습니다.</p>
                </div>
                <SortiButton text={'MY SORTI 만들러 가기'} onClick={goToMyPage}></SortiButton>
                <p onClick={toggleFunc}>다음에 만들래요</p>
            </ModalWrapper>

        </Modal>
    )
}

export default MySortiModal;