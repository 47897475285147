import React from 'react';
import styled from 'styled-components';
import useTitle from '../utils/useTitle';

const TermsOfServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8%;
  & > div {
    font-weight: 800;
    font-size: 32px;
    display: flex;
    margin-bottom: 16px;
    font-family: suitMedium !important;
  }
  & > pre {
    font-family: suitMedium !important;
    font-size: 24px;
    white-space: pre-wrap;
    line-height: normal;
  }
`;

const TermsOfService = () => {
  useTitle('SORTI');
  return (
    <TermsOfServiceWrapper>
      <div>솔티랩 이용약관</div>
      <pre>
        {`
SORTI 관련 서비스 이용과 관련하여 필요한 사항을 규정합니다.

"회사"는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법'), 개인정보보호법, 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다.

[제1조] 목적
이 약관은 솔티랩(이하 "회사"라 함)이 제공하는 "솔티(SORTI) 서비스"의 이용과 관련하여 "회사"와 "회원"간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

[제2조] 용어의 정의
1. "서비스"란 회사가 운영하고 제공하고 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)로 이용가능한 것을 말하며, 아래의 사이트를 말합니다.
 - https://sorti.one
2. "회원"이라 함은 "회사" 약관에 따라 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 사람을 말합니다.
3. "URL"은 웹페이지의 위치 등의 정보를 나타내는 인터넷 주소를 말합니다.
4. "지식재산권"은 특허, 저작권, 인격권, 영업 비밀, 상표, 불공정경쟁, 퍼블리시티권, 프라이버시나 다른 독점적 권리를 다루는 법에서 보호하는 권리 혹은 그러한 권리의 출원, 갱신, 연장, 복원, 재생 등으로 그 이후에 효력을 가지는 것을 말합니다.
5. "서비스"의 "사용"은 개인이 직접 혹은 간접으로 기계나 장치의 도움을 받거나 혹은 도움 없이 "서비스"의 기능에 접근하거나 접근을 시도하여 데이터를 전송, 수신 혹은 교환하거나, "서비스"를 어떤 방식으로든 활용하거나 이익을 얻거나 "서비스"의 기능 혹은 특징과 상호 작용하는 것을 말합니다.
6. “결제”란 회원이 특정 유료 ”서비스”를 이용하기 위하여 각종 지불수단을 통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.
7. “정기결제”란 "회원"이 유료 ”서비스”를 이용하기 위해 미리 등록한 결제수단을 통하여 월 단위의 이용기간에 대한 이용요금이 정기적으로 결제되고, 이용기간이 자동 갱신되는 "결제" 방식을 의미합니다.

[제3조] 약관의 게시와 개정
1. "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.
2. "회사"는 "온라인 디지털콘텐츠산업 발전법", "전자상거래 등에서의 소비자보호에 관한 법률", "약관의 규제에 관한 법률", "소비자기본법", "정보통신망 이용촉진 및 정보보호 등에 관한 법률"(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3. "회사"의 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제2.1항의 방식에 따라 그 개정약관의 적용일자 15일 전부터 적용일자 전일까지 공지합니다. 다만, "회원"에게 불리한 약관의 개정의 경우에는 그 적용일자 30일 이전부터 적용일 이후 상당한 기간 동안 공지하고, 공지 외에 전자우편 주소로 개정약관을 발송하여 명확히 통지하도록 합니다.
4. "회사"의 서비스 이용약관 전항에 따라 개정 약관을 공지 또는 통지하면서 "회원"에게 15일 또는 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이 명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.
5. "회원"이 개정약관의 적용에 동의하지 않는 경우 "회원"은 이 이용약관에 따른 이용계약을 해지할 수 있습니다. 이 경우 "회사"는 개정 약관의 내용을 적용할 수 없습니다.

[제4조] 이용계약 체결
1. 회원가입은 "회원"이 되고자 하는 자(이하 "가입신청자")가 이 약관을 읽고 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
2. 회원가입신청은 "회사" 웹사이트상에서 온라인 회원가입 신청서에 필수사항을 입력하고 "등록" 버튼 혹은 다른 확인 버튼을 누름으로써 이뤄지고, "회사" 모바일 앱을 통하여 신청할 경우에는 모바일 앱의 다운로드와 설치 이전에 제공되는 약관 동의 절차를 수락함으로써 이뤄집니다.
3. 간편회원가입 또는 간편로그인으로 서비스를 이용하거나, 회원가입 없이 "회사"가 제공하는 기능을 이용하는 경우 "서비스"의 이용 약관에 동의하는 것으로 간주합니다.
4. "회사" "가입신청자"의 회원가입신청에 대해 승낙함을 원칙으로 합니다. 다만, "회사"은 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
  - 실명이 아니거나 타인의 명의를 이용한 경우
  - 허위의 정보를 기재하거나, "회사" 제시하는 내용을 기재하지 않은 경우
  - "가입신청자"가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 "회사"의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
  - "가입신청자"의 귀책사유로 인하여 승인이 불가능하거나, 명백하게 사회질서 및 미풍양속에 반할 우려가 있거나 기타 약관이나 법령의 제반 사항을 위반하며 신청하는 경우
5. "회사"는 "서비스" 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
6. "서비스"의 회원가입 신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 "가입신청자"에게 알리도록 합니다.
7. 이용계약의 성립 시기는 "회사" 회원 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
8. "회사"는 "회원"에 대해 회사정책에 따라 등급별 또는 서비스 별로 구분하여 이용범위, 이용서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.

[제5조] 회원정보의 변경
1. 회원"은 마이페이지를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 실명 확인이나 본인 인증을 위해 필요한 정보는 수정이 불가능합니다.
2. "회원"은 회원가입 신청 시 기재한 사항이 변경되었을 경우 직접 온라인으로 수정을 하거나 전자우편 기타 방법으로 "회사"에게 그 변경사항을 알려야 합니다.
3. 변경사항을 수정하거나 "회사"에 알리지 않아 알리지 않아 발생한 불이익에 대하여 "회사"은 책임지지 않습니다.

[제6조] 개인정보보호
1. "회사"는 "개인정보보호법", "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 "개인정보 처리방침"이 적용됩니다.

[제7조] "회원"의 아이디 등의 관리
1. "회원"의 아이디와 비밀번호의 관리 책임은 "회원"에게 있으며, 제3자에 의한 부정사용 등 회원의 고의 또는 과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다.
2. "회사"는 "회원"의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사"의 운영자로 오인한 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
3. "회원"은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.

[제8조] "회원"에 대한 통지
1. "회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 "회원"의 등록된 전자우편 주소 및 연락처로 통지할 수 있습니다.
2. "회사"는 "회원" 전체에 대한 통지가 필요한 경우 7일 이상 "서비스"의 게시판이나 페이지(웹페이지나 모바일 페이지 포함)에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 단, "회원"의 정보와 관련하여 중대한 영향을 미치는 사항은 상당한 기간 동안 게시판을 통해 이를 공지하고 회사가 부여한 전자우편 주소로 개별통지 합니다.

[제9조] "회사"의 의무
1. "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
2. "회사"는 "회원"의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며, "서비스"의 제공과 관련하여 알게 된 "회원"의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다.
3. "회사"는 제3자와의 서비스 제공계약 등을 체결하여 "회원"에게 서비스를 제공하는 경우, 제3자에게 제공되는 "회원"의 구체적인 회원정보를 명시하고 "회원"의 개별적이고 명시적인 동의를 받은 후 동의의 범위 내에서 해당 서비스의 제공 기간 동안에 한하여 "회원"의 개인정보를 제3자와 공유하는 등 관련 법령을 준수합니다.
4. "회사"는 "개인정보보호법", "정보통신망법" 및 "회사"의 "개인정보 처리방침"에 따라 "회원"의 개인 정보를 처리합니다.

[제10 조] "회원"의 의무
1. 생성된 URL의 모든 책임과 권리는 사용자에게 있으므로, URL 생성시 신중하게 판단해서 이용해 주시기 바랍니다.
2. "회원"은 다음 행위를 하여서는 안 됩니다.
  - 신청 또는 변경 시 허위내용의 등록
  - 타인의 정보도용
  - "회사"가 게시한 정보의 변경
  - "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
  - "회사"나 기타 제3자의 저작권 등 지적재산권에 대한 침해
  - "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
  - 음란, 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
  - "회사"의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
  - 기타 불법적이거나 부당한 행위
3. "회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.

[제11 조] 제공되는 "서비스"
1. "회사"는 회원에게 아래와 같은 서비스를 제공합니다.
 - 긴 주소를 짧게 만들어 주는 서비스
 - 생성된 URL을 서버에서 관리해 주는 서비스
 - 공개하고자 하는 URL을 타인이 검색할 수 있는 서비스
 - 기타 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스
2. "서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
3. "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제7조 ["회원"에 대한 통지]에 정한 방법으로 "회원"에게 통지합니다. 다만, "회사" 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
4. "회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.

[제12 조] "서비스"의 변경
1. "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.
2. "서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.
3. "회사"는 무료로 제공되는 "서비스"의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.

[제13조] 정보의 제공
1. "회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 "회사" 서비스의 개인 설정이나 수신 거절 링크를 통해 수신 거절을 할 수 있습니다.

[제14조] 게시물에 대한 권리의 귀속 및 게시물의 이용
1. "회원"이 "서비스" 내에 게시한 URL 정보의 저작권은 해당 URL을 생성한 회원에게 귀속됩니다.
2. "회원"이 "서비스" 내에 게시하는 게시물과 "회원"의 이름, 아이디, 로고, 프로필 이미지 등은 검색결과 내지 "서비스" 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
2. "회사"는 상기 제14조 2항 이외의 방법으로 "회원"의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 "회원"의 동의를 얻어야 합니다.

[제15조] 게시물 및 URL의 관리
1. "회원"은 "서비스"에 게시한 "URL"에 관련된 책임을 전적으로 부담합니다. "회사"은 "회원"이 생성한 "URL"와 관련하여 어떤 책임도 부담하지 않습니다.
2. "회사"는 "서비스" 상의 "회원"의 "‘URL"를 사전 검토하거나 필터링하지 않습니다. "회사"는 "회원"이 생성한 "URL"의 정확성, 안전성, 완전성 혹은 유용성에 대해 어떠한 보증도 하지 않으며, "회원"의 "URL", 의견, 권고, 충고 등의 정확성이나 신뢰성에 대해 어떤 책임도 지지 않습니다.
3. "회원"의 게시물이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법, 서비스 약관에 따라 조치를 취하여야 합니다.
4. "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
5. 본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한 범위 내에서 "회사"가 정한 "게시중단요청서비스"에 따릅니다.

[제16조] "서비스"에 대한 권리의 귀속
1. "서비스"에 대한 "URL"은 "회사"에 보관됩니다. 단, "회원"의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다. 이 약관에서 명시적으로 정하는 경우를 제외하고 "회원"은 "서비스"에 대해 법적으로 보호되는 부분에 대해 어떤 권리도 취득하지 않는 것에 동의합니다.
2. "회사"는 "서비스"와 관련하여 "회원"에게 "회사" 정한 이용조건에 따라 계정, 아이디, "URL" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
3. "회원"은 "서비스"나 그에 포함된 소프트웨어나 문서를 변경, 2차저작물 작성, 디컴파일, 리버스 엔지니어링, 디스어셈블 혹은 다른 방식으로 소스 코드를 추출하지 않을 것에 동의합니다. 또한 "서비스"에 독점적인 정보에 접근하여 "서비스"의 대체물이나 유사 서비스 혹은 제품을 제작하지 않을 것에 동의합니다.

[제17조] 이용의 제한
1. "회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.
2. "회사"는 전항에도 불구하고, 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 이에 따른 영구이용정지시 발생할 수 있는 손실 등에 대해 "회사"은 별도로 보상하지 않습니다.
3. "회사"는 "회원"이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
4. 무료 서비스를 이용하는 회원은 개인으로 정하며, 회사, 단체등 대상이 아닐 경우 서비스 이용에 제한이 있을 수 있습니다.
5. 본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 "회사"은 제7조["회원"에 대한 통지]에 따라 통지합니다.
6. "회원"은 본 조에 따른 이용제한 등에 대해 7일 이내에 "회사"이 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"이 인정하는 경우 "회사"은 즉시 "서비스"의 이용을 재개합니다.

[제18조] 비정상 이용에 대한 제재
1. 과도한 트래픽이 발생하거나, 다중접속, 단시간 내 접속 아이피가 수시로 변경되는 등 비정상적인 서비스 이용으로 객관적으로 판단되는 경우
2. "회사" 계정을 타인에게 공유하는 경우
3. "회사"를 통해 단축 URL 생성 시 원본 URL로 이동되는 다이렉트 URL가 아닌, 다른 URL로 이동시키는 다중 이동 URL를 사용하는 경우
4. 확인할 수 없는 웹 링크, APP다운로드 링크로 사용하는 경우
5. 동일한 링크로 단축URL을 반복적으로 생성하는 경우
6. 사용하지 않는 단축URL을 생성한 경우(30일 이내 클릭수가 발생하지 않으면 미사용 URL로 간주하여 삭제될 수 있음)
7. 로그인 또는 비밀번호 찾기와 같은 회원정보를 수집하는 링크로 이동시키는 URL을 사용하는 경우
8. "서비스"를 통해 도박, 성인사이트 등의 불법사이트, 또는 저작권이 있는 음원, 동영상, 소프트웨어 등을 불법 공유하는 경우
- 위반 수준이 낮은 경우, 이용정지 없이 경고 메일이 발송될 수 있으며 경우에 따라 생성된 단축URL은 삭제 처리될 수 있습니다.
- 위반 행위가 중복되는 경우 가중 처리될 수 있습니다.
- 서비스 이용제한 조치 내용에 대하여 이의가 있으실 경우에는 "회사" 운영자에게 문의하여 이의 제기가 가능합니다. (연락처 게재 요망)
- 이의 제기는 해당 이용제한 조치가 적용된 계정으로만 접수를 받으며, 이용제한 조치 시작일로부터 7일 이내에만 접수가 가능합니다.
- 저작물 불법 공유 시 한국소프트웨어저작권협회, 사무용소프트웨어연합(BSA), 디지털콘텐츠저작권협회, 한국음악저작권협회 등으로부터 법적 제재를 받으실 수 있으며 요청에 의해 회원정보, 접속IP등이 공개될 수 있으므로 회원 여러분의 올바른 서비스 이용 부탁드립니다.

[제19조] 계약해제, 해지 등
1. "회원"은 아래의 방법으로 이용계약을 해지할 수 있습니다.
2. 이메일을 통해 "회사"에 회원탈퇴 요청을 전달해야하며 "회사"는 회원탈퇴 희망시점 30일 이전에 결과를 통지합니다.
3. "회원"이 회원탈퇴 할 경우, 관련법 및 "개인정보 처리방침"에 따라 "회사" 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.
4. "회원"이 회원탈퇴 한 경우, "회원"이 변환한 URL정보 일체는 삭제됩니다.

[제20조] 준거법 및 재판관할
1. "회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로 합니다. "회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.

이 약관은 <2022년 10월 28일>부터 시행합니다.
`}
      </pre>
    </TermsOfServiceWrapper>
  );
};

export default TermsOfService;
