import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { LOGIN_API_URL } from '../Url';
import * as color from '../css/ColorVariable';

import Profile from '../asset/img/mypageprofile.png';
import Google from '../asset/img/google.png';
import Kakao from '../asset/img/kakao.png';
import Complete from '../asset/img/Complete.png';
import RoundQuestion from '../asset/img/roundquestion.png';
import CustomUrl from '../asset/img/customurl.png';
import CompleteModal from '../components/Modal/CompleteModal';
import ConfirmModal from '../components/Modal/ConfirmModal';
import DeleteModal from '../components/Modal/DeleteModal';
import { useNavigate } from 'react-router-dom';
import TokenExpiredModal from '../components/Modal/TokenExpiredModal';
import useTitle from '../utils/useTitle';

const cookies = new Cookie();

const EmailCertPopup = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.state ? 'flex' : 'none')};
  background-color: rgba(34, 30, 31, 0.5);
  z-index: 9999999;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px;
    position: relative;
    width: 420px;
    background: #ffffff;
    border-radius: 12px;
    box-sizing: border-box;
    & > span {
      font-family: 'SUIT';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #252525;
      margin-bottom: 20px;
    }
    & > p {
      font-family: 'SUIT';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #252525;
    }
    & > button {
      cursor: pointer;
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      width: 100%;
      height: 48px;
      background: ${color.mainColor};
      border-radius: 10px;
      border: 0px;
      color: white;
      font-weight: 700;
      font-size: 18px;
    }
  }
`;

const MyPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 4% 55% 4% 2%;
  & > img {
    width: 100px;
  }
  & > h1 {
    font-family: suitExBold;
    font-weight: 500;
    font-size: 24px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: relative;
  & > div {
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    padding: 15px 20px;
    height: 25px;
    background-color: white;
    font-family: suitRegular;
    border-radius: 10px;
    & > p:first-child {
      color: ${color.mainColor};
    }
    & > p:last-child {
      display: flex;
      align-items: center;
      column-gap: 15px;
      position: relative;
      & > img {
        width: 16px;
      }
      & > .notCerted {
        position: absolute;
        right: 0;
        border: 1px solid ${color.subRed};
        color: ${color.subRed};
        border-radius: 8px;
        padding: 4px 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      & > .certed {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        & > img {
          width: 100%;
          height: 100%;
        }
        & > span {
          position: absolute;
          display: ${(props) => (props.state ? 'flex' : 'none')};
          width: fit-content;
          white-space: nowrap;
          background-color: #252525;
          color: white;
          font-size: 14px;
          padding: 6px 12px;
          border-radius: 8px;
          right: 12;
          top: 12;
        }
      }
    }
  }
`;

const TextBox = styled.div`
  & > h1 {
    font-family: suitExBold;
    font-weight: 500;
    font-size: 24px;
  }
  & > p {
    font-family: suitRegular;
    padding-top: 15px;
  }
`;

const Description = styled.div`
  background-color: ${color.brightgrayColor};
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
  padding: 15px 20px;
  & > img {
    width: 20px;
  }
  & > p {
    font-family: suitRegular;
    font-size: 14px;
    line-height: 160%;
  }
`;

const LinkPreview = styled.p`
  column-gap: 0 !important;
  & > img {
    width: 100px !important;
  }
  & > p > span {
    color: ${color.mainColor};
  }
`;

const MySortiBox = styled.div`
  & > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > button {
      font-family: suitMedium;
      font-weight: 500;
      border-radius: 10px;
      border: none;
      background-color: ${color.mainColor};
      color: white;
      padding: 5px 10px;
      box-sizing: content-box;
      cursor: pointer;
    }
    & > input {
      border: none;
      width: 200px;
      &:focus {
        outline: none;
      }
    }
  }
`;

const ResendAlert = styled.p`
  color: ${color.subRed};
  font-family: suitBold;
`;

const ExitButton = styled.button`
  font-family: suitMedium;
  color: ${color.subRed};
  background-color: white;
  border: 1px solid ${color.subRed};
  padding: 7px 18px;
  border-radius: 10px;
  width: 100px;
  cursor: pointer;
`;

const TextAlign = styled.p`
  text-align: center !important;
`;

function MyPage(props) {
  const navigate = useNavigate();
  useTitle('SORTI | 마이페이지');

  const [mySorti, setMySorti] = React.useState('');
  const [mySortiUpdate, setMySortiUpdate] = React.useState('');
  const [toggleChangeMySorti, setToggleChangeMySorti] = React.useState(false);

  const [toggleOverlapModal, setToggleOverlapModal] = React.useState(false);
  const [toggleConfirmModal, setToggleConfrimModal] = React.useState(false);
  const [toggleErrorModal, setToggleErrorModal] = React.useState(false);
  const [toggleCheckModal, setToggleCheckModal] = React.useState(false);
  const [confirmText, setConfirmText] = React.useState('');

  const [toggleEmailAuthModal, setToggleEmailAuthModal] = React.useState(false);
  const [toggleEmailConfirmModal, setToggleEmailConfirmModal] = React.useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const [isCerted, setIsCerted] = React.useState(false);
  const [isCertedHovered, setIsCertedHovered] = React.useState(false);
  const [isCertedModal, setIsCertedModal] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };

  const [resendAlert, setResendAlert] = React.useState('');

  const handleToggleDeleteModal = () => {
    setToggleDeleteModal(!toggleDeleteModal);
  };

  const handleEmailAuthModal = () => {
    setToggleEmailAuthModal(!toggleEmailAuthModal);
  };

  const handleEmailConfirmModal = () => {
    setToggleEmailConfirmModal(!toggleEmailConfirmModal);
  };

  const handleOverlapModal = () => {
    setToggleOverlapModal(!toggleOverlapModal);
  };

  const handleErrorModal = () => {
    setToggleErrorModal(!toggleErrorModal);
  };

  const handleConfirmModal = () => {
    setToggleConfrimModal(!toggleConfirmModal);
  };

  const handleCheckModal = () => {
    setToggleCheckModal(!toggleCheckModal);
  };

  const handleToggleMySorti = () => {
    setToggleChangeMySorti(!toggleChangeMySorti);
  };

  const handleMySortiUpdate = (e) => {
    setMySortiUpdate(e.target.value);
  };

  const handleCertEmail = () => {
    sendEmail(true);
    setIsCertedModal(true);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/alias',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setMySorti(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
    checkUserRole(true);
  }, []);

  const checkUserRole = (first) => {
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/role',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (!!first) {
          if (response.data.result['code'] === '200') {
            setIsCerted(true);
          }
        } else {
          if (response.data.result['code'] === '200') {
            handleToggleMySorti();
          } else if (response.data.result['code'] === '104') {
            handleEmailAuthModal();
          } else if (response.data.result['code'] === '100') {
            handleTokenExpiredModal();
          }
        }
      })
      .catch(function (error) {});
  };

  const breakout = () => {
    axios({
      method: 'delete',
      url: LOGIN_API_URL + '/sorti/api/user/user',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          cookies.remove('token', {
            sameSite: 'none',
            secure: true,
            path: '/',
          });
          cookies.remove('email', {
            sameSite: 'none',
            secure: true,
            path: '/',
          });
          cookies.remove('name', { sameSite: 'none', secure: true, path: '/' });
          cookies.remove('loginType', {
            sameSite: 'none',
            secure: true,
            path: '/',
          });
          navigate('/');
        } else if (response.data.result['code'] === '104') {
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const sendEmail = (flag) => {
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/email',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        console.log(flag);
        if (!flag) {
          if (response.data.result['code'] === '200') {
            handleEmailAuthModal();
            handleEmailConfirmModal();
          } else if (response.data.result['code'] === '100') {
            handleTokenExpiredModal();
          }
        }
      })
      .catch(function (error) {});
  };

  const resendEmail = () => {
    setResendAlert('');
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/email',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setResendAlert('인증 메일을 재전송했습니다.');
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const updateMySorti = () => {
    axios({
      method: 'put',
      url: LOGIN_API_URL + '/sorti/api/user/alias',
      params: {
        token: cookies.get('token'),
        alias: mySortiUpdate,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setConfirmText('MY SORTI를 생성했습니다.');
          handleCheckModal();
          handleConfirmModal();
        } else if (response.data.result['code'] === '101') {
          setConfirmText('중복된 MY SORTI 입니다.');
          handleCheckModal();
          handleOverlapModal();
        } else if (response.data.result['code'] === '103') {
          setConfirmText('등록 불가능한 MY SORTI 입니다.');
          handleCheckModal();
          handleErrorModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const authEmailText = () => {
    return (
      <p>
        '{cookies.get('email')}'으로
        <br />
        인증 이메일이 발송되었습니다.
      </p>
    );
  };

  const authEmailDescText = () => {
    return (
      <TextAlign>
        이메일이 도착하지 않았다면 스팸메일함을
        <br />
        확인해주세요. 그래도 이메일이 없다면 아래의 재전송
        <br /> 버튼을 눌러 인증을 다시 진행해주세요.
      </TextAlign>
    );
  };

  return (
    <MyPageWrapper>
      <h1>마이페이지</h1>
      <img src={Profile} alt='' />
      <BoxContainer state={isCertedHovered}>
        <div>
          <p>이름</p>
          <p>{cookies.get('name')}</p>
        </div>
        <div>
          <p>계정</p>
          <p>
            <img src={cookies.get('loginType') === 'google' ? Google : Kakao} alt='' />
            {cookies.get('email')}
            {isCerted ? (
              <div onMouseEnter={() => setIsCertedHovered(true)} onMouseLeave={() => setIsCertedHovered(false)} className={'certed'}>
                <img src={Complete} alt={'email certed icon'} />
                <span>이메일 인증이 완료되었어요!</span>
              </div>
            ) : (
              <div className={'notCerted'} onClick={handleCertEmail}>
                이메일 인증하기
              </div>
            )}
          </p>
        </div>
      </BoxContainer>
      <TextBox>
        <h1>MY SORTI</h1>
        <p>MY SORTI를 통해 나만의 URL을 만들 수 있습니다.</p>
      </TextBox>
      <BoxContainer>
        <MySortiBox>
          <p>MY SORTI</p>
          <div>
            {toggleChangeMySorti ? (
              <>
                <input type='text' placeholder={'영문 대소문자와 숫자'} value={mySortiUpdate} onChange={handleMySortiUpdate} />
                <button onClick={handleCheckModal}>저장</button>
              </>
            ) : mySorti === '' ? (
              <>
                <input type='text' value={mySorti} placeholder={'등록된 MY SORTI가 없습니다.'} readOnly={true} />
                <button onClick={() => checkUserRole()}>MY SORTI 만들기 &gt;</button>
              </>
            ) : (
              <>
                <p>{mySorti}</p>
                <button onClick={() => checkUserRole()}>MY SORTI 변경 &gt;</button>
              </>
            )}
          </div>
        </MySortiBox>
        <div>
          <p>링크 미리보기</p>
          <LinkPreview>
            <p>
              https://sorti.me/
              <span>{mySorti === '' ? 'MYSORTI' : mySorti}</span>/
            </p>
            <img src={CustomUrl} alt='CustomUrl' />
          </LinkPreview>
        </div>
      </BoxContainer>
      <Description>
        <img src={RoundQuestion} alt='' />
        <p>
          MY SORTI는 최초 1회 무료로 변경할 수 있습니다.
          <br />
          변경 시에 기존 MY SORTI 링크들은 모두 바뀐 MY SORTI링크로 대체되며,
          <br />
          기존 MY SORTI링크들은 모두 접속이 차단됩니다.
        </p>
      </Description>
      <ExitButton onClick={handleToggleDeleteModal}>탈퇴하기</ExitButton>

      <ConfirmModal
        title={'이메일 인증이 완료되지 않은 계정입니다.'}
        desc={'MY SORTI는 인증된 계정으로만 사용이 가능합니다.'}
        desc2={'이메일 인증은 로그인 시 사용된 이메일로 전송됩니다.'}
        button1={`이메일 인증을 진행할게요`}
        button2={'다음에 할게요'}
        confirmFunc={() => sendEmail(false)}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleEmailAuthModal}
        toggleFunc={handleEmailAuthModal}
        isDelete={true}
      />
      <ConfirmModal
        title={authEmailText()}
        desc={authEmailDescText()}
        button1={`확인`}
        button2={'인증 메일을 못받았어요'}
        confirmFunc={handleEmailConfirmModal}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleEmailConfirmModal}
        toggleFunc={resendEmail}
        isDelete={true}
      >
        <ResendAlert>{resendAlert}</ResendAlert>
      </ConfirmModal>
      <DeleteModal
        title={'정말로 탈퇴하시겠습니까?'}
        desc={'탈퇴하시면 MY SORTI , 생성한 링크 등'}
        desc2={'모든 데이터가 삭제됩니다'}
        button1={'네 탈퇴할래요'}
        button2={'다시 생각해 볼래요'}
        toggle={toggleDeleteModal}
        toggleFunc={handleToggleDeleteModal}
        button1color={color.subRed}
        button2color={color.mainColor}
        confirmFunc={breakout}
      />
      <CompleteModal confirmText={confirmText} toggle={toggleOverlapModal} toggleFunc={handleOverlapModal} />
      <CompleteModal confirmText={confirmText} toggle={toggleConfirmModal} toggleFunc={handleConfirmModal} />
      <CompleteModal confirmText={confirmText} toggle={toggleErrorModal} toggleFunc={handleErrorModal} />
      <ConfirmModal
        title={`‘${mySortiUpdate}’로 결정하시겠어요?`}
        desc={'MY SORTIE는 최초 1회만 무료로 변경할 수 있습니다.'}
        button1={`‘${mySortiUpdate}’로 결정할게요`}
        button2={'다시 생각해볼래요'}
        confirmFunc={updateMySorti}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleCheckModal}
        toggleFunc={handleCheckModal}
        isDelete={true}
        confirmText={'링크를 숨기기 해제 했습니다.'}
      />
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <EmailCertPopup state={isCertedModal} onClick={() => setIsCertedModal(false)}>
        <div>
          <span>{cookies.get('email')}</span>
          <p>해당 이메일로 인증 메일을 전송했습니다!</p>
          <p>메일이 도착하지 않았다면 스팸 메일함을 확인하거나</p>
          <p>인증하기 버튼을 다시 눌러주세요!</p>
          <button onClick={() => setIsCertedModal(false)}>확인</button>
        </div>
      </EmailCertPopup>
    </MyPageWrapper>
  );
}

export default MyPage;
