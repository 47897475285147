import React from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';

import Hide from '../../asset/img/hiddenlink.png';
import Pencil from '../../asset/img/pencil.png';
import Delete from '../../asset/img/delete.png';
import MyLink from '../../asset/img/tomylink.png';
import { LINK_API_URL } from '../../Url';

const cookies = new Cookie();

const PopoverWrapper = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 15px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: white;
  border: 1px solid ${color.mainColor};
  border-radius: 10px;
  z-index: 20;
  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    & > img {
      width: 20px;
    }
    & > p {
      font-family: suitMedium;
    }
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  border-radius: 100px;
  background-color: ${color.brightgrayColor};
`;

// const Overlay = styled.div`
//   position: absolute;
//   display: ${(props) => (props.toggle ? 'block' : 'none')};
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   z-index: 15;
//   background-color: #00000030;
// `;

function LinkDetailPopover({ toggle, toggleFunc, handleTitleUpdate, deleteModal, hideModal, tomyModal }) {
  return (
    <>
      <PopoverWrapper toggle={toggle}>
        {window.location.href.includes('hiddenlink') ? (
          <div onClick={tomyModal}>
            <img src={MyLink} alt='MyLink' />
            <p>나의 링크로</p>
          </div>
        ) : (
          <>
            <div onClick={hideModal}>
              <img src={Hide} alt='Hide' />
              <p>링크 숨기기</p>
            </div>
            <HorizontalLine />
            <div onClick={handleTitleUpdate}>
              <img src={Pencil} alt='Pencil' />
              <p>링크 제목 편집</p>
            </div>
          </>
        )}
        <HorizontalLine />
        <div onClick={deleteModal}>
          <img src={Delete} alt='Delete' />
          <p>링크 삭제</p>
        </div>
      </PopoverWrapper>
    </>
  );
}

export default LinkDetailPopover;
