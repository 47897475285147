import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { LOGIN_API_URL, LINK_API_URL, DATA_API_URL } from '../../Url';

import Button from '../Button/Button';
import ToggleButton from '../Button/ToggleButton';
import MySortiModal from '../Modal/MySortiModal';
import CustomUrl from '../../asset/img/customurlColor.png';
import ArrowUpWhite from '../../asset/img/ArrowUpWhite.png';
import ArrowDownGreen from '../../asset/img/ArrowDownGreen.png';
import Mysorti from '../../asset/img/mysorti.png';
import IconXCircle from '../../asset/img/IconXCircle.png';
import Kakaologin from '../../asset/img/kakaologin.png';
import CopyWhite from '../../asset/img/CopyWhite.png';
import LoadingMotion from '../../asset/img/Loading-Motion.gif';
import AlertModal from '../Modal/AlertModal';
import TokenExpiredModal from '../Modal/TokenExpiredModal';
import ConfirmModal from '../Modal/ConfirmModal';
import ToastBox from './ToastBox';

const cookies = new Cookie();

const LoadingOverlay = styled.div`
  display: ${(props) => (props.state ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: #ffffffb3;
  top: 0;
  left: 0;
  z-index: 999999999;
  & > img {
    width: 216px;
    height: 216px;
  }
`;

const NewLinkComplete = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid ${color.mainColor};
  background-color: #252525;
  box-sizing: border-box;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 24px;
  flex-direction: column;
  border-radius: 10px;
  z-index: 99999;
  & > p {
    font-family: suitSemiBold;
    color: white;
    margin-bottom: 20px;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4px 0;
    border-radius: 10px;
    cursor: pointer;
    & > img {
      width: 24px;
      height: 24px;
    }
    & > span {
      font-family: suitMedium;
      margin-left: 12px;
    }
  }
  & > div:first-of-type {
    background-color: #fee500;
    margin-bottom: 10px;
  }
  & > div:last-of-type {
    background-color: ${color.mainColor};
    color: white;
  }
  & > img:last-of-type {
    position: absolute;
    top: 0;
    right: -32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const CustomInput = styled.input`
  width: 100%;
  border-radius: 10px;
  height: 48px;
  background-image: url(${(props) => props.img});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  transition: 0.3s;
  border: 0;
  font-family: suitRegular;
  font-size: 16px;
  &::placeholder {
    color: #a0b1ab;
  }
  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  column-gap: 20px;
  width: 100%;
`;

const SelectOptionWrapper = styled.div`
  width: fit-content;
  display: flex;
  white-space: nowrap;
  background-color: ${(props) => (props.toggle ? color.mainColor : color.backgroundColor)};
  align-items: center;
  box-sizing: border-box;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  & > span {
    font-size: 16px;
    line-height: 24px;
    font-family: suitMedium;
    color: ${(props) => (props.toggle ? 'white' : '#252525')};
  }
  & > img {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
  &:hover {
    background-color: ${(props) => (props.toggle ? color.hoverSelectedButton : '#ccfde6')};
  }
`;

const OptionWrapper = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')} !important;
`;

const LinkCreateWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 15px;
  border-radius: 10px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 24px;
    & > div:first-child {
      width: 100%;
      & > div:first-child {
        display: flex;
        background-color: white;
        height: 60px;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 10px 20px;
        border: 2px solid ${color.brightgrayColor};
        & > div:first-child {
          display: flex;
          background-color: white;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          & > img {
            width: 32px;
            height: 32px;
          }
          & > input {
            font-size: 20px;
            line-height: 40px;
            padding-left: 12px;
          }
        }
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 12px;
        row-gap: 20px;
        margin-top: 24px;

        & > div:first-child {
          display: flex;
          column-gap: 21px;
          & > div:nth-child(2) {
            width: 100%;
          }
        }
        & > div:nth-child(2) {
        }
      }
    }
  }
  & > div:last-child {
    display: flex;
    width: 100%;
  }
`;

const MySortiWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  //width : calc(100% - 40px);
  height: 18px;
  align-items: center;
  column-gap: 15px;
  border: 1px solid ${(props) => (props.toggle ? color.mainColor : color.brightgrayColor)};
  border-radius: 10px;
  padding: 15px 20px;
  font-family: suitMedium;
  color: ${color.mainColor};
  background-color: white;
  & > p {
    white-space: nowrap;
  }
  &:focus-within {
    border-color: ${color.mainColor};
  }
`;

const LinkPreview = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 15px;
  border: 1px solid ${color.brightgrayColor};
  border-radius: 10px;
  padding: 15px 20px;
  font-family: suitMedium;
  color: ${color.mainColor};
  background-color: white;
  height: 60px;
  box-sizing: border-box;
  & strong {
    color: ${color.mainColor};
  }
  & > p:last-child {
    color: #000000;
    display: flex;
    align-items: center;
    & > img {
      max-width: ${(props) => (props.customUrl === '' ? '100px' : '0px')};
      overflow: hidden;
      visibility: ${(props) => (props.toggle ? 'hidden' : 'visible')};
      height: 24px;
      transition: 0.5s;
    }
  }
`;

const ResendAlert = styled.p`
  color: ${color.subRed};
  font-family: suitBold;
`;

const TextAlign = styled.p`
  text-align: center !important;
`;

const CustomInputWrapper = styled.div`
  &:focus-within {
    border-color: ${color.mainColor} !important;
  }
`;

function LinkCreateContainer(props) {
  const { refresh, setRefresh } = props;
  const inputWidth = '50px';

  const [toggleButton, setToggleButton] = React.useState(false);
  const [toggleMySortiModal, setToggleMySortiModal] = React.useState(false);
  const [mySorti, setMySorti] = React.useState('');
  const [createLink, setCreateLink] = React.useState({
    aliasStatus: 'N',
    customUrl: '',
    originUrl: '',
    urlTitle: '',
    utm_campaign: null,
    utm_medium: null,
    utm_source: null,
  });

  const [loadingToggle, setLoadingToggle] = React.useState(false);

  const [resendAlert, setResendAlert] = React.useState('');

  const [toggleOverlapModal, setToggleOverlapModal] = React.useState(false);
  const [toggleCantRegistModal, setToggleCantRegistModal] = React.useState(false);
  const [toggleEmptyInputModal, setToggleEmptyInputModal] = React.useState(false);
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);
  const [toggleEmailAuthModal, setToggleEmailAuthModal] = React.useState(false);
  const [toggleEmailAuthModal2, setToggleEmailAuthModal2] = React.useState(false);
  const [toggleEmailConfirmModal, setToggleEmailConfirmModal] = React.useState(false);
  const [selectOptionToggle, setSelectOptionToggle] = React.useState(true);

  const [successToggle, setSuccessToggle] = React.useState(false);
  const [toast, setToast] = React.useState(false);

  const [isCerted, setIsCerted] = React.useState(false);
  const [lastCreatedLink, setLastCreatedLink] = React.useState({});

  useEffect(() => {
    setCreateLink({
      aliasStatus: 'N',
      customUrl: '',
      originUrl: '',
      urlTitle: '',
      utm_campaign: null,
      utm_medium: null,
      utm_source: null,
    });
  }, [refresh]);

  const handleEmailAuthModal = () => {
    setToggleEmailAuthModal(!toggleEmailAuthModal);
  };
  const handleEmailAuthModal2 = () => {
    setToggleEmailAuthModal2(!toggleEmailAuthModal2);
  };

  const handleEmailConfirmModal = () => {
    setToggleEmailConfirmModal(!toggleEmailConfirmModal);
  };

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const handleToggleEmptyInputModal = () => {
    setToggleEmptyInputModal(!toggleEmptyInputModal);
  };

  const handleToggleOverlapModal = () => {
    setToggleOverlapModal(!toggleOverlapModal);
  };

  const handleToggleCantRegistModal = () => {
    setToggleCantRegistModal(!toggleCantRegistModal);
  };

  const handleToggleMySortiModal = () => {
    setToggleMySortiModal(!toggleMySortiModal);
  };
  const handleToast = () => {
    setToast(!toast);
  };
  const copyClipBoard = (event) => {
    event.stopPropagation();
    handleToast();
    navigator.clipboard.writeText(`https://sorti.me/${lastCreatedLink.customUrl}`);
  };

  const handleToggleButton = () => {
    if (!isCerted) {
      handleEmailAuthModal2();
    } else if (mySorti === '') {
      handleToggleMySortiModal();
    } else {
      setToggleButton((prev) => !prev);
    }
  };

  const handleKakaoShare = () => {
    if (!window.Kakao) return;

    const kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      kakao.init('e78ff373ae1bbe34241103908d02677d');
    }

    if (!!lastCreatedLink.urlTitle && lastCreatedLink.urlTitle !== '') {
      kakao.Share.sendCustom({
        templateId: 86295,
        templateArgs: {
          url: `https://sorti.me/${lastCreatedLink.customUrl}`,
          description: lastCreatedLink.urlTitle,
        },
      });
    } else {
      axios({
        method: 'get',
        url: DATA_API_URL + '/sorti/api/data/link/title',
        params: {
          token: cookies.get('token'),
          urlId: lastCreatedLink.urlId,
        },
      })
        .then((response) => {
          kakao.Share.sendCustom({
            templateId: 86295,
            templateArgs: {
              url: `https://sorti.me/${lastCreatedLink.customUrl}`,
              description: response.data.data,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const authEmailText = () => {
    return (
      <p>
        '{cookies.get('email')}'으로
        <br />
        인증 이메일이 발송되었습니다.
      </p>
    );
  };

  const authEmailDescText = () => {
    return (
      <TextAlign>
        이메일이 도착하지 않았다면 스팸메일함을
        <br />
        확인해주세요. 그래도 이메일이 없다면 아래의 재전송
        <br /> 버튼을 눌러 인증을 다시 진행해주세요.
      </TextAlign>
    );
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/alias',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setMySorti(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});

    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/role',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);

        if (response.data.result['code'] === '200') {
          setIsCerted(true);
        } else {
          setIsCerted(false);
        }
      })
      .catch(function (error) {});
  }, []);

  const createLinkChange = (e) => {
    let { name, value } = e.target;
    setCreateLink({ ...createLink, [name]: value });
  };

  useEffect(() => {
    console.log(lastCreatedLink);
    if (!lastCreatedLink || !lastCreatedLink.customUrl || lastCreatedLink.customUrl === '') return;
    setSuccessToggle(true);
  }, [lastCreatedLink]);

  const getLastCreatedLink = (urlId) => {
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/links/my',
      params: {
        token: cookies.get('token'),
        tagNameList: [],
        searchData: '',
        startDate: '',
        endDate: '',
        sortType: '최근 날짜 순',
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          const link = response.data.data.linkData.filter((l) => l.urlId === urlId)[0];
          setLastCreatedLink(link);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  };

  // 링크 생성
  const onCreateLink = () => {
    setLoadingToggle(() => true);
    const data = {
      aliasStatus: createLink.aliasStatus,
      originUrl: createLink.originUrl,
      customUrl: createLink.customUrl,
      urlTitle: createLink.urlTitle,
      utm_campaign: null,
      utm_medium: null,
      utm_source: null,
    };

    axios({
      method: 'post',
      url: LINK_API_URL + '/sorti/api/link',
      params: {
        token: cookies.get('token'),
      },
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          // window.location.reload();
          setRefresh(!refresh);
          const urlId = Number(response.data.data);
          getLastCreatedLink(urlId);
        } else if (response.data.result['code'] === '101') {
          handleToggleOverlapModal();
        } else if (response.data.result['code'] === '103') {
          handleToggleCantRegistModal();
        } else if (response.data.result['code'] === '104') {
          handleEmailAuthModal();
        } else if (response.data.result['code'] === '105') {
          handleToggleEmptyInputModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoadingToggle(() => false);
        setToggleButton(false);
      });
  };

  const sendEmail = () => {
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/email',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          handleEmailAuthModal();
          handleEmailConfirmModal();
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  const resendEmail = () => {
    setResendAlert('');
    axios({
      method: 'get',
      url: LOGIN_API_URL + '/sorti/api/user/email',
      params: {
        token: cookies.get('token'),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (response.data.result['code'] === '200') {
          setResendAlert('인증 메일을 재전송했습니다.');
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    setCreateLink((prev) => ({ ...prev, aliasStatus: toggleButton ? 'Y' : 'N' }));
  }, [toggleButton]);

  return (
    <Container>
      <LinkCreateWrapper>
        <div>
          <div>
            <CustomInputWrapper>
              <div>
                <img src={Mysorti} alt='my sorti' />
                <CustomInput
                  value={createLink.originUrl || ''}
                  name='originUrl'
                  onChange={createLinkChange}
                  placeholder='여기에 줄이고 싶은 링크를 입력해주세요.'
                  width={`calc(100%-${inputWidth})`}
                  img={''}
                />
              </div>
              <SelectOptionWrapper toggle={selectOptionToggle} onClick={() => setSelectOptionToggle(!selectOptionToggle)}>
                <span>선택 옵션</span>
                <img alt='select option' src={selectOptionToggle ? ArrowUpWhite : ArrowDownGreen} />
              </SelectOptionWrapper>
            </CustomInputWrapper>
            <OptionWrapper toggle={selectOptionToggle}>
              <div>
                <MySortiWrapper toggle={toggleButton}>
                  <p>MY SORTI</p>
                  <ToggleButton toggle={toggleButton} handleToggle={handleToggleButton} />
                </MySortiWrapper>
                <MySortiWrapper>
                  <p>커스텀 URL</p>
                  <CustomInput
                    value={createLink.customUrl || ''}
                    name='customUrl'
                    onChange={createLinkChange}
                    placeholder='URL 뒷 부분을 원하는 단어로 커스텀할 수 있습니다.'
                    width={`calc(100%-${inputWidth})`}
                    img={''}
                  />
                </MySortiWrapper>
              </div>
              <div>
                <MySortiWrapper>
                  <p>링크 제목</p>
                  <CustomInput
                    value={createLink.urlTitle || ''}
                    name='urlTitle'
                    onChange={createLinkChange}
                    placeholder='링크의 제목을 입력해주세요.'
                    width={`calc(100%-${inputWidth})`}
                    img={''}
                  />
                </MySortiWrapper>
              </div>
            </OptionWrapper>
          </div>
          <Button width={'176px'} text='링크 만들기' onClick={onCreateLink} />
        </div>
        <div>
          <LinkPreview customUrl={createLink.customUrl}>
            <p>링크 미리보기</p>
            <p>
              https://sorti.me/
              <strong>{toggleButton ? `${mySorti}` : ''}</strong>
              {toggleButton ? '/' : ''}
              <img src={CustomUrl} alt='CustomUrl' />
              {createLink.customUrl}
            </p>
          </LinkPreview>
        </div>
      </LinkCreateWrapper>
      {toast ? <ToastBox handleToast={setToast} text={'링크가 복사되었어요.'} /> : ''}

      <AlertModal confirmText={'중복된 커스텀 URL이 존재합니다'} toggle={toggleOverlapModal} toggleFunc={handleToggleOverlapModal} />
      <AlertModal confirmText={'등록 불가능한 커스텀 URL 입니다'} toggle={toggleCantRegistModal} toggleFunc={handleToggleCantRegistModal} />
      <AlertModal confirmText={'URL을 입력해 주세요'} toggle={toggleEmptyInputModal} toggleFunc={handleToggleEmptyInputModal} />
      <MySortiModal toggle={toggleMySortiModal} toggleFunc={handleToggleMySortiModal} />
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
      <ConfirmModal
        title={'이메일 인증이 완료되지 않은 계정입니다.'}
        desc={'이메일 인증을 진행하지 않은 계정은 1개의 링크만 생성할 수 있습니다.'}
        desc2={'이메일 인증은 로그인 시 사용된 이메일로 전송됩니다.'}
        button1={`이메일 인증을 진행할게요`}
        button2={'다음에 할게요'}
        confirmFunc={sendEmail}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleEmailAuthModal}
        toggleFunc={handleEmailAuthModal}
        isDelete={true}
      />
      <ConfirmModal
        title={'이메일 인증이 완료되지 않은 계정입니다.'}
        desc={'이메일 인증을 진행하지 않은 계정은 MY SORTI를 사용할 수 없습니다.'}
        desc2={'이메일 인증은 로그인 시 사용된 이메일로 전송됩니다.'}
        button1={`이메일 인증을 진행할게요`}
        button2={'다음에 할게요'}
        confirmFunc={sendEmail}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleEmailAuthModal2}
        toggleFunc={handleEmailAuthModal2}
        isDelete={true}
      />
      <ConfirmModal
        title={authEmailText()}
        desc={authEmailDescText()}
        button1={`확인`}
        button2={'인증 메일을 못받았어요'}
        confirmFunc={handleEmailConfirmModal}
        button1color={color.mainColor}
        button2color={color.subRed}
        toggle={toggleEmailConfirmModal}
        toggleFunc={resendEmail}
        isDelete={true}
      >
        <ResendAlert>{resendAlert}</ResendAlert>
      </ConfirmModal>
      <NewLinkComplete toggle={successToggle}>
        <p>SORTI로 새로운 링크를 생성했습니다!</p>
        <div onClick={handleKakaoShare}>
          <img src={Kakaologin} alt='kakao login' />
          <span>카카오톡으로 공유하기 &gt;</span>
        </div>
        <div onClick={copyClipBoard}>
          <img src={CopyWhite} alt='copy link' />
          <span>링크 복사하기</span>
        </div>
        <img onClick={() => setSuccessToggle(false)} src={IconXCircle} alt='close' />
      </NewLinkComplete>
      <LoadingOverlay state={loadingToggle}>
        <img src={LoadingMotion} alt='loading motion gif' />
      </LoadingOverlay>
    </Container>
  );
}

export default LinkCreateContainer;
