import React, { useEffect } from "react";
import styled from "styled-components";
import * as color from "../css/ColorVariables";
import { DEPLOY_LOGIN_API_URL } from "../Url";

import Cookie from "universal-cookie";
import SocialLoginButton from "../components/SocialLoginButton";

import Google from "../asset/img/google.png";
import Kakao from "../asset/img/kakaologin.png";
import { isMobileFunc } from "../utils/utils";
import useTitle from "../utils/useTitle";

const cookies = new Cookie();

const isMobile = isMobileFunc();

const Wrapper = styled.div`
  background-color: ${color.mainColor};
  height: 100vh;
  min-width: ${isMobile ? "280px" : "1100px"};
  & > div {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -40%);
    width: ${isMobile ? "95%" : "760px"};
    min-width: ${isMobile ? "270px" : "600px"};
    height: fit-content;
    padding: ${isMobile ? "32px 0px" : "69px 0"};
    border-radius: 10px;
    text-align: center;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    & > p:first-child {
      font-family: suitExBold;
      font-size: ${isMobile ? "24px" : "40px"};
    }
    & > p:nth-child(2) {
      font-family: ${isMobile ? "suitSemiBold" : "suitBold"};
      font-size: ${isMobile ? "16px" : "18px"};
      line-height: ${isMobile ? "24px" : "25px"};
      width: 100%;
      padding: 30px 0;
      white-space: nowrap;
    }
    & > p:last-child {
      font-family: suitMedium;
      margin-top: 30px;
      line-height: 160%;
      font-size: ${isMobile ? "12px" : ""};
      white-space: nowrap;
      & > span {
        color: ${color.darkgrayColor};
        text-decoration: underline;
        cursor: pointer;
      }
    }
    & > p > strong {
      color: ${color.mainColor};
      font-weight: normal;
    }
    & > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      padding: 0 12px;
      box-sizing: border-box;
      & > button {
        width: ${isMobile ? "100%" : "450px"} !important;
        height: 60px !important;
        font-size: 18px;
      }
    }
  }
`;

function Login() {
  const onClickGoogle = () => {
    window.location.href =
      DEPLOY_LOGIN_API_URL + "/oauth2/authorization/google";
  };

  const onClickKakao = () => {
    window.location.href = DEPLOY_LOGIN_API_URL + "/oauth2/authorization/kakao";
  };

  const handleOpen = (title) => {
    window.open(title);
  };

  useTitle("SORTI");

  useEffect(() => {
    cookies.remove("token", { sameSite: "none", secure: true, path: "/" });
    cookies.remove("email", { sameSite: "none", secure: true, path: "/" });
    cookies.remove("name", { sameSite: "none", secure: true, path: "/" });
    cookies.remove("loginType", { sameSite: "none", secure: true, path: "/" });
  }, []);

  return (
    <Wrapper>
      <div>
        <p>
          <strong>SORTI</strong>에 로그인
        </p>
        <p>
          SORTI 서비스 이용을 위해 {isMobile && <br />}아래의 소셜 계정으로
          로그인을 해주세요!
        </p>
        <div>
          <SocialLoginButton
            border={`1px solid ${color.brightgrayColor}`}
            backgroundColor={"white"}
            text={"Google로 시작하기"}
            onClick={onClickGoogle}
            img={Google}
          />
          <SocialLoginButton
            border={"none"}
            backgroundColor={"#FEE500"}
            text={"카카오로 시작하기"}
            onClick={onClickKakao}
            img={Kakao}
          />
        </div>
        <p>
          SORTI에 가입하시면{" "}
          <span onClick={handleOpen.bind(this, "privacyPolicy")}>
            개인정보처리방침
          </span>
          과{" "}
          <span onClick={handleOpen.bind(this, "termsOfService")}>
            이용약관
          </span>
          에<br />
          동의하는 것으로 간주합니다.
        </p>
      </div>
    </Wrapper>
  );
}

export default Login;
