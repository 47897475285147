import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  &  > p {
    font-family: suitMedium;
    font-weight: 500;
  }
  & > button {
    width: 100%;
    font-family: suitBold;
    background-color: ${color.mainColor};
    color : white;
    border-radius: 10px;
    padding : 15px 0;
    cursor : pointer;
    border: none;
  }
`

const AlertModal = ({toggle , toggleFunc , confirmText}) => {

    return(
        <Modal isOpen={toggle} onRequestClose={toggleFunc}
               style={{
                   overlay :{
                       backgroundColor : 'rgba(34, 30, 31, 0.5)',
                       zIndex : 9999999,
                   },
                   content : {
                       height : 'fit-content',
                       width : '250px',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       borderRadius : '10px',
                       border : 'none',
                       minWidth : '300px'
                   }
               }}
        >
            <ModalWrapper>
                <p>{confirmText}</p>
                <button onClick={toggleFunc}>확인</button>
            </ModalWrapper>

        </Modal>
    )
}

export default AlertModal;