import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// #region constants

// #endregion

// #region styled-components
const Wrapper = styled.div`
  border: 1px solid #${(props) => props.color};
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  background-color :  white;
  & > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 16px;
    font-family: suitMedium;
    color: #${(props) => props.color};
    & > img {
      width: 20px;
    }
  }
  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    padding-top: 3px;
    & > p {
      font-family: suitBold;
      font-size: 24px;
      font-weight : 500;
    }
  }
`;
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  text: PropTypes.string,
  content: PropTypes.string,
};

const defaultProps = {
  /**
   * 해당 박스 제목을 나타냅니다.
   */
  text: "Hello",
  /**
   * 해당 박스의 컨텐츠를 나타냅니다.
   */
  content: 0,
  /**
   * 해당 박스의 폰트와 테두리 색을 나타냅니다..
   */
  color: "000000",
};

/**
 *
 */
const LinkInfoBox = ({ text, content, img, color }) => {
  return (
    <Wrapper color={color}>
      <div>
        <img src={img} alt="" />
        <p>{text}</p>
      </div>
      <div>
        <p>{content}</p>
      </div>
    </Wrapper>
  );
};

LinkInfoBox.propTypes = propTypes;
LinkInfoBox.defaultProps = defaultProps;
// #endregion

export default LinkInfoBox;
