import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../css/ColorVariable';
import axios from 'axios';
import Cookie from 'universal-cookie';
import Tag from './TagWithNoImg';
import Magnifier from '../../asset/img/magnifier.png';
import Plus from '../../asset/img/plus.png';
import { DATA_API_URL, LINK_API_URL } from '../../Url';
import TokenExpiredModal from '../Modal/TokenExpiredModal';

const cookies = new Cookie();

const LinTagPopoverWrapper = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  width: 200px;
  flex-direction: column;
  row-gap: 15px;
  position: absolute;
  top: 55px;
  left: 0;
  border: 1px solid ${color.mainColor};
  border-radius: 10px;
  padding: 15px;
  background-color: white;
  z-index: 20;
  & > input {
    background-image: url(${Magnifier});
    background-color: #f1f7f4;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    &::placeholder {
      font-family: suitRegular;
      font-size: 16px;
      color: #a0b1ab;
    }
  }
  & > div {
    display: flex;
    column-gap: 10px;
    align-items: center;
    row-gap: 15px;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    & > img {
      width: 20px;
      cursor: pointer;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  display: ${(props) => (props.toggle ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
`;

function LinkTagPopover({ toggle, handleTagArray, toggleFunc }) {
  const [myTags, setMyTags] = React.useState([]);
  const [searchData, setSearchData] = React.useState('');
  const [toggleTokenExpiredModal, setToggleTokenExpiredModal] = React.useState(false);

  const handleTokenExpiredModal = () => {
    setToggleTokenExpiredModal(!toggleTokenExpiredModal);
  };
  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: DATA_API_URL + '/sorti/api/data/tags',
      params: {
        token: cookies.get('token'),
        searchData: searchData,
        tagNameList: [],
      },
      withCredentials: true,
      paramsSerializer: function (paramObj) {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }

        return params.toString();
      },
    })
      .then((response) => {
        if (response.data.result['code'] === '200') {
          setMyTags(response.data.data);
        } else if (response.data.result['code'] === '100') {
          handleTokenExpiredModal();
        } else {
        }
      })
      .catch(function (error) {});
  }, [searchData]);

  return (
    <>
      <LinTagPopoverWrapper toggle={toggle}>
        <input type='text' placeholder={'태그를 검색해 보세요'} onChange={handleSearchData} />
        <div>{myTags.length < 1 ? '' : myTags.map((item, key) => <Tag key={key} text={item} tagRegister={() => handleTagArray(item)} />)}</div>
      </LinTagPopoverWrapper>
      <Overlay toggle={toggle} onClick={toggleFunc} />
      <TokenExpiredModal toggle={toggleTokenExpiredModal} toggleFunc={handleTokenExpiredModal} />
    </>
  );
}

export default LinkTagPopover;
