import React from 'react'
import styled from "styled-components";
import * as color from '../../css/ColorVariable';

import DownArrow from '../../asset/img/greendownarrow.png';

const SelectBoxWrapper = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    width: 200px;
    padding: 15px;
    border-radius: 10px;
    height : fit-content;
    border : 1px solid ${color.brightgrayColor};
    background-color : white;
    cursor : pointer;
    margin-right : 5px;
    & > p {
        font-family : suitRegular;
        color : ${color.darkgrayColor};
    }
    & > img {
        width : 14px;
    }
`

function SelectBox({onClick}) {
  return (
    <SelectBoxWrapper onClick={onClick}>
        <p>태그</p>
        <img src={DownArrow} alt="" />
    </SelectBoxWrapper>
  )
}

export default SelectBox