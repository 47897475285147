import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Cookie from "universal-cookie";
import { LOGIN_API_URL } from "../Url";

import BannerContainer from "../components/BannerContainer";
import ExtensionContainer from "../components/ExtensionContainer";
import Footer from "../components/Footer";
import LinkReportContainer from "../components/LinkReportContainer";
import MySortiContainer from "../components/MySortiContainer";
import ShortUrlDescription from "../components/ShortUrlDescription";
import StartSortiContainer from "../components/StartSortiContainer";
import { isMobileFunc } from "../utils/utils";
import DownArrow from "../asset/img/downArrow.png";
import useTitle from "../utils/useTitle";

const cookies = new Cookie();

const isMobile = isMobileFunc();

const ArrowAnimation = keyframes`
    0%{
        transform: translate(0 , 0);
    }
    50%{
        transform: translate(0 , 10px);
    }
    100%{
        transform: translate(0 , 0);
    }
`;

const DownArrowWrapper = styled.div`
  bottom: 0;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  & > img {
    width: 30px;
    cursor: pointer;
    animation: ${ArrowAnimation} 1.5s infinite linear;
  }
`;

const Wrapper = styled.div`
  min-width: ${isMobile ? "280px" : "1000px"};
  overflow: hidden;
  height: ${window.innerHeight};
  box-sizing: border-box;
  & > div {
    transition: all 1s;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
  }
`;

function Main(props) {
  const outerDivRef = useRef();

  useTitle("SORTI");

  const [_margin, _setMargin] = useState(0);
  const margin = useRef(_margin);
  const setMargin = (data) => {
    _setMargin(data);
    margin.current = data;
  };

  const [_isDelay, _setIsDelay] = useState(false);
  const isDelay = useRef(_isDelay);
  const setIsDelay = (data) => {
    _setIsDelay(data);
    isDelay.current = data;
  };

  useEffect(() => {
    if (isDelay.current) {
      setTimeout(() => {
        setIsDelay(false);
      }, [1300]);
    }
  }, [isDelay.current]);

  useEffect(() => {
    axios({
      method: "get",
      url: LOGIN_API_URL + "/sorti/api/user/authentication",
      params: {
        token: cookies.get("token"),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.result["code"] === "200") {
          cookies.set("name", response.data.data.name, {
            sameSite: "none",
            secure: true,
            path: "/",
          });
          cookies.set("token", response.data.data.token, {
            sameSite: "none",
            secure: true,
            path: "/",
          });
          cookies.set("loginType", response.data.data.loginType, {
            sameSite: "none",
            secure: true,
            path: "/",
          });
          cookies.set("email", response.data.data.email, {
            sameSite: "none",
            secure: true,
            path: "/",
          });
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const wheelHandler = (e) => {
      e.preventDefault();

      const footer = document.getElementById("landingpage-footer");

      const { deltaY } = e;

      if (isDelay.current) return;

      if (deltaY > 0) {
        if (margin.current === window.innerHeight * -5 - footer.clientHeight)
          return;

        if (margin.current === window.innerHeight * -5) {
          outerDivRef.current.style.marginTop =
            margin.current - footer.clientHeight;
          setMargin(margin.current - footer.clientHeight);
        } else {
          outerDivRef.current.style.marginTop =
            margin.current - window.innerHeight;
          setMargin(margin.current - window.innerHeight);
        }
        setIsDelay(true);
      } else if (deltaY < 0) {
        if (margin.current === 0) return;

        if (margin.current === window.innerHeight * -5 - footer.clientHeight) {
          outerDivRef.current.style.marginTop =
            margin.current + footer.clientHeight;
          setMargin(margin.current + footer.clientHeight);
        } else {
          outerDivRef.current.style.marginTop =
            margin.current + window.innerHeight;
          setMargin(margin.current + window.innerHeight);
        }
        setIsDelay(true);
      }
    };

    const outerDivRefCurrent = outerDivRef.current;

    outerDivRefCurrent.addEventListener("wheel", wheelHandler);
    return () => {
      outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  return (
    <Wrapper>
      <div ref={outerDivRef}>
        <BannerContainer />
        <ShortUrlDescription />
        <MySortiContainer />
        <LinkReportContainer />
        <ExtensionContainer />
        <StartSortiContainer />
        <Footer id="landingpage-footer" />
      </div>
    </Wrapper>
  );
}

export default Main;
