import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Main from './pages/Main';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import MenuBar from './components/MenuBar';
import MenuBarMobile from './components/MenuBarMobile';

import Test from './pages/Test';
import NotFoundPage from './pages/NotFoundPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import KakaoShare from './pages/KakaoShare';
import { isMobileFunc } from './utils/utils';
import useTitle from './utils/useTitle';

const isMobile = isMobileFunc();

const tagManagerArgs = {
  gtmId: 'GTM-5FWZNCJ',
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [menuBarState, setMenuBarState] = React.useState(true);

  const location = useLocation();

  useEffect(() => {
    console.log(isMobile);
    if (isMobile) {
      window.location.href = 'https://m.sorti.one';
    }
  }, [isMobile]);

  return (
    <>
      {window.location.pathname.includes('/dashboard') ? '' : window.location.pathname.includes('/test') ? '' : isMobile ? <MenuBarMobile /> : <MenuBar top={menuBarState} />}
      <Routes key={location.pathname} location={location}>
        <Route path='/' exact element={<Main setMenu={setMenuBarState} />} />
        <Route path='/index' exact element={<Main setMenu={setMenuBarState} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/dashboard/*' element={<Dashboard />} />
        <Route path='/test' element={<Test />} />
        <Route path='/termsOfService' element={<TermsOfService />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/kakaoShare' element={<KakaoShare />} />
        <Route path={'/Oops404'} element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
