import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookie from 'universal-cookie';
import * as color from '../../css/ColorVariable';

const cookies = new Cookie();

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  text-align: center;
  & > p {
    font-family: suitMedium;
    font-weight: 500;
  }
  & > button {
    width: 100%;
    font-family: suitBold;
    background-color: ${color.mainColor};
    color: white;
    border-radius: 10px;
    padding: 15px 0;
    cursor: pointer;
    border: none;
  }
`;

const TokenExpiredModal = ({ toggle, toggleFunc }) => {
  const navigate = useNavigate();

  const goToLogin = () => {
    cookies.remove('token', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('email', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('name', { sameSite: 'none', secure: true, path: '/' });
    cookies.remove('loginType', { sameSite: 'none', secure: true, path: '/' });
    navigate('/login');
  };

  return (
    <Modal
      isOpen={toggle}
      onRequestClose={goToLogin}
      style={{
        overlay: {
          backgroundColor: 'rgba(34, 30, 31, 0.5)',
          zIndex: 9999999,
        },
        content: {
          height: 'fit-content',
          width: '250px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          border: 'none',
          minWidth: '300px',
        },
      }}
    >
      <ModalWrapper>
        <p>
          세션이 만료되었습니다.
          <br /> 로그인 페이지로 이동합니다.
        </p>
        <button onClick={goToLogin}>확인</button>
      </ModalWrapper>
    </Modal>
  );
};

export default TokenExpiredModal;
