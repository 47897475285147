import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as color from '../css/ColorVariables';

import DownArrow from '../asset/img/downArrow.png';
import CustomUrlColor from '../asset/img/customurlColor.png';
import SampleUrl from '../asset/img/sampleUrl.png';
import Background from '../asset/img/greenBackground.png';
import BackgroundMobile from '../asset/img/BackMobile.png';
import { isMobileFunc } from '../utils/utils';

const isMobile = isMobileFunc();

const urlFontSize = 24;

const ArrowAnimation = keyframes`
    0%{
        transform: translate(0 , 0);
    }
    50%{
        transform: translate(0 , 10px);
    }
    100%{
        transform: translate(0 , 0);
    }
`;

const ShortUrlDescriptionWrapper = styled.div`
  position: relative;
  z-index: 10;
  height: 100vh;
  align-items: center;
  display: flex;
  overflow: hidden;
  justify-content: center;
  & > div {
    width: 100%;
    position: relative;
    display: flex;
    & > div:first-child {
      display: flex;
      justify-content: center;
      position: absolute;
      width: ${isMobile ? '100%' : '1300px'};
      max-width: ${isMobile ? '400px' : ''};
      z-index: -1;
      font-family: suitExBold;
      font-size: ${isMobile ? '26px' : '32px'};
      left: 50%;
      right: 50%;
      top: 0;
      transform: ${isMobile ? 'translate(-50%, -220%)' : 'translate(-50%, -65%)'};
      & > img {
        width: ${isMobile ? '90%' : '50%'};
        background: linear-gradient(180deg, #ddd 0%, rgba(236, 239, 238, 0) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      position: absolute;
      width: 80%;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      row-gap: 50px;
      align-items: center;
      & > p {
        font-family: suitExBold;
        font-size: ${isMobile ? '24px' : '40px'};
        text-align: center;
        line-height: ${isMobile ? '40px' : '50px'};
        white-space: nowrap;
        & > strong {
          color: ${color.mainColor};
          font-weight: normal;
        }
      }

      & > img {
        width: 50px;
        animation: ${ArrowAnimation} 1.5s infinite linear;
      }
      & > div {
        display: flex;
        align-items: center;
        font-family: suitRegular;
        font-size: ${isMobile ? '16px' : urlFontSize};
        border: 1px solid ${color.brightgrayColor};
        border-radius: 15px;
        padding: ${isMobile ? '12px 15px' : '22px 23px 20px 23px'};
        background-color: white;
        & > img {
          height: ${isMobile ? urlFontSize : urlFontSize}px;
        }
      }
    }
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  z-index: -1 !important;
  width: ${isMobile ? '80%' : '100%'};
  margin-top: ${isMobile ? '500px' : '300px'};
`;

function ShortUrlDescription() {
  return (
    <ShortUrlDescriptionWrapper>
      <div>
        <div>
          <img src={SampleUrl} alt='sample url' />
        </div>
        <div>
          <p>
            길고 복잡한 URL, <br /> <strong>짧고 간단하게</strong> 줄여보세요!
          </p>
          {!isMobile && <img src={DownArrow} alt={''} />}
          <div>
            <p>https://sorti.me/</p>
            <img src={CustomUrlColor} alt={''} />
          </div>
        </div>
      </div>
      <BackgroundImg src={isMobile ? BackgroundMobile : Background} alt='background' />
    </ShortUrlDescriptionWrapper>
  );
}

export default ShortUrlDescription;
